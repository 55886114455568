import React, { useContext, useEffect } from 'react';
import { FunctionField, ReferenceField, SelectField, TextField, useTranslate } from 'react-admin';
import moment from 'moment-timezone';
import { Box, IconButton, Tooltip, useTheme } from '@material-ui/core';
import { Cancel, Edit, FilterNoneOutlined } from '@material-ui/icons';
import resourceSlug from '../../constant/resource-slug';
import CustomLinkField from '../../base/components/ra/fields/customLink.field';
import { transformIdValue } from '../../services/util/stringUtil';
import CheckInFilters from './components/CheckInList/CheckInFilters';
import CheckInAction from './components/CheckInList/CheckInAction';
import { NavigationContext } from '../../App';
import { DateField } from '../../base/components/ra/fields';
import { DATE_DISPLAY_FORMAT } from '../../constant';
import { CAMPAIGN_STATUS, WALLET_TYPES } from '../../constant/campaign';
import { hasPermission } from '../../services/util/auth';
import CampaignStatus from '../mkt-free-spin/components/CampaignStatus';
import { useCancelCampaign } from '../../base/hooks/useCancelCampaign';
import { CustomizedNoPageList } from '../../base/components/customized/CustomizedNoPageList';

const CheckInList = () => {
  const t = useTranslate();
  const theme = useTheme();

  const hasCreatePermission = hasPermission(resourceSlug.CHECK_IN, 'create');
  const hasUpdatePermission = hasPermission(resourceSlug.CHECK_IN, 'update');
  const { cancelCampaign } = useCancelCampaign();

  const { setNavigationData } = useContext(NavigationContext);

  useEffect(() => {
    setNavigationData({
      pageTitleInfo: {
        label: t('resources.check-in.name'),
      },
      breadcrumbs: [
        {
          label: t('ra.page.home'),
          link: '/',
        },
        {
          label: t('resources.group.boost.name'),
        },
        {
          label: t('resources.check-in.name'),
        },
      ],
    });

    return () => {
      setNavigationData({});
    };
  }, [t]);

  return (
    <CustomizedNoPageList
      resource={resourceSlug.CHECK_IN}
      filters={<CheckInFilters />}
      actions={<CheckInAction />}
      basePath="/check-in"
      hasCreate={hasCreatePermission}
    >
      <CustomLinkField
        source="id"
        label="common.fields.id"
        href={(record) => `/#/${resourceSlug.CHECK_IN}/${record.id}/show`}
        title={(record) => transformIdValue(record.id)}
      />
      <TextField source="campaignName" label="resources.check-in.fields.name" sortable={false} />
      <TextField source="campaignCode" label="resources.campaign.fields.code" sortable={false} />
      <DateField
        source="startTime"
        label="resources.check-in.fields.start-date"
        sortable={false}
        dateFormat={DATE_DISPLAY_FORMAT}
      />
      <DateField
        source="endTime"
        label="resources.check-in.fields.end-date"
        sortable={false}
        dateFormat={DATE_DISPLAY_FORMAT}
      />
      <ReferenceField
        reference="currency"
        source="currencyId"
        label="resources.reward.fields.currency"
        link="show"
        sortable={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <FunctionField
        source="timezone"
        label="resources.check-in.fields.timezone"
        render={(record) =>
          `${record.timezone} (GMT${record.timezone ? moment().tz(record.timezone).format('Z') : ''})`
        }
        sortable={false}
      />
      <SelectField
        source="walletConversion"
        label="resources.check-in.fields.transfer-destination"
        choices={Object.values(WALLET_TYPES).map((type) => ({
          id: type,
          name: t(`common.wallet.${type}`),
        }))}
        sortable={false}
      />
      <CampaignStatus label="common.fields.status" />
      <FunctionField
        label="ra.field.action"
        sortable={false}
        render={(record) => (
          <Box display="flex" gap={4} justifyContent="center">
            {hasUpdatePermission && record.status === CAMPAIGN_STATUS.ACTIVE && (
              <Tooltip title={t('ra.action.edit')}>
                <IconButton href={`/#/${resourceSlug.CHECK_IN}/${record.id}`} onClick={(e) => e.stopPropagation()}>
                  <Edit />
                </IconButton>
              </Tooltip>
            )}
            {hasCreatePermission && (
              <Tooltip title={t('ra.action.duplicate')}>
                <IconButton
                  href={`/#/${resourceSlug.CHECK_IN}/create?cloneId=${record.id}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  <FilterNoneOutlined />
                </IconButton>
              </Tooltip>
            )}
            {hasUpdatePermission && record.status === CAMPAIGN_STATUS.ACTIVE && (
              <Tooltip title={t('ra.action.cancel')}>
                <Box
                  component={IconButton}
                  sx={{
                    color: `${theme.palette.error.main} !important`,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    cancelCampaign(record.id);
                  }}
                >
                  <Cancel />
                </Box>
              </Tooltip>
            )}
          </Box>
        )}
      />
    </CustomizedNoPageList>
  );
};

export default CheckInList;
