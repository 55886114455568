/* eslint-disable import/no-cycle */
import { SimpleForm, Toolbar, useTranslate } from 'react-admin';
import React, { useEffect } from 'react';
import { Box, Button } from '@material-ui/core';
import { useForm } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import BasicInfoStep from './BasicInfoStep';
import { validateCampaign } from '../utils';
import { showConfirmDialog } from '../../../../services/redux/app/app.actions';
import resourceSlug from '../../../../constant/resource-slug';
import useSetting from '../../../../base/hooks/useSetting';

const CampaignCreateUpdateForm = ({ clonedRecord, ...props }) => {
  const t = useTranslate();
  const settings = useSetting();
  const { saving, save, record: initialRecord } = props;

  const record = clonedRecord || initialRecord;

  return (
    <SimpleForm toolbar={null} validate={(values) => validateCampaign(values, t, settings)} save={save}>
      <CampaignCreateUpdateFields record={record} />
      <CustomizedToolbar campaignId={record?.id} saving={saving} />
    </SimpleForm>
  );
};

const CampaignCreateUpdateFields = ({ record }) => {
  const form = useForm();

  useEffect(() => {
    if (isEmpty(record)) {
      return;
    }

    form.reset(record);
  }, [record]);

  return (
    <Box
      px={{
        xs: '16px',
        md: '28px',
      }}
    >
      <BasicInfoStep isEditForm={!!record?.id} initialValue={record} />
    </Box>
  );
};

const CustomizedToolbar = ({ campaignId, saving }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const t = useTranslate();
  const form = useForm();

  return (
    <Box
      component={Toolbar}
      display="flex"
      justifyContent="space-between"
      mx="-16px"
      mb="-16px"
      sx={{
        px: {
          xs: '24px !important',
          md: '44px !important',
        },
      }}
    >
      <Button
        onClick={() => {
          dispatch(
            showConfirmDialog({
              isOpen: true,
              title: 'resources.campaign.cancel-title',
              content: 'resources.campaign.cancel-desc',
              onConfirm: () =>
                history.replace(`/${resourceSlug.MKT_FREE_SPINS}/${campaignId ? `${campaignId}/show` : ''}`),
            }),
          );
        }}
        variant="outlined"
        disabled={saving}
      >
        {t('ra.action.cancel')}
      </Button>

      <Button
        disabled={saving}
        color="primary"
        variant="contained"
        disableElevation
        onClick={() => {
          form.submit();
        }}
      >
        {t(campaignId ? 'ra.action.update' : 'ra.action.save')}
      </Button>
    </Box>
  );
};

export default CampaignCreateUpdateForm;
