/* eslint-disable react/require-default-props */
/* eslint-disable no-param-reassign */
import { Box, Drawer, makeStyles } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { camelCase, isEmpty, startCase, upperCase } from 'lodash';
import Type from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, getResources, Loading, SimpleForm, useEditController, useLocale, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import { spreadComponents } from '../../../services/util';
import { useSchema } from '../../hooks';
import { Edit } from '../ra/views';
import Actions from '../ra/views/action-toolbar';
import RefManyBulkActionTypes from './refManyBulkActionTypes.constant';
import { guessProperty } from './wealth-guesser';
import { RefManyEditGuesserList } from './wealth-ref-many-edit.guesser';
import WealthInputArrangementProvider from './wealth-input-arrangement-provider';
import WealthInputWrapper from './wealth-input-wrapper';

const pascalCase = str => startCase(camelCase(str)).replace(/ /g, '');
const parseTokenName = resourceName => {
  // parse resource name to token name used in API schema
  // ex: game-type => GameType
  // ex: toc-rate => TOCRate
  let tokenName = pascalCase(resourceName);
  if (resourceName.startsWith('toc')) {
    tokenName = `${resourceName.split('-')[0].toUpperCase()}${pascalCase(resourceName.split('-')[1])}`;
  }
  if (resourceName.startsWith('vip')) {
    tokenName = upperCase(tokenName);
  }
  return tokenName;
};

const WealthEditGuesser = props => {
  const classes = useStyles();
  const resources = useSelector(getResources);
  const {
    excludeFields, customFields, redirect, extendsActionMenu, fieldOrder, ...rest
  } = props;
  const {
    hasEdit, basePath, id, resource, location,
  } = rest;

  const {
    api, ref,
  } = useSchema();
  const translate = useTranslate();
  const { record } = useEditController(props);
  const locale = useLocale();

  const [referenceManyEdit, setReferenceManyEdit] = useState({
    open: false,
    bulkAction: RefManyBulkActionTypes.ADD,
  });
  const [guessedInfo, setGuessedInfo] = useState({});
  const [editingResource, setEditingResource] = useState({
    id,
    name: resource,
  });
  const [referenceResource, setReferenceResource] = useState({});

  if (!hasEdit) {
    throw new Error('This resource does not support edit');
  }

  const transform = (writableFields = []) => data => {
    const fieldsInPayload = [...writableFields, ...customFields];

    if (resource === 'user') {
      fieldsInPayload.push('role');
    }

    if (fieldsInPayload?.length) {
      return fieldsInPayload.reduce((result, field) => {
        result[field] = data?.[field] ?? null;
        return result;
      }, {});
    }

    return data;
  };

  // Ref-Many-Edit-Guesser Handler
  useEffect(() => {
    if (api && !isEmpty(referenceResource)) {
      let editingResourceSchema;
      try {
        editingResourceSchema = ref.get(`#/components/schemas/${parseTokenName(resource)}`);

        const referenceResourceSchema = ref.get(`#/components/schemas/${parseTokenName(referenceResource?.name)}`);
        // find name as property of editing resource, ex: "game" has name as properpties (in category) is "games"
        const editingResourceNameAsProperty = Object.keys(referenceResourceSchema?.properties || {})?.filter(
          key => referenceResourceSchema?.properties?.[key]?.items?.$ref?.split('/')?.pop()?.toLowerCase() === resource,
        )?.[0];

        const referenceResourceType = editingResourceSchema?.properties?.[referenceResource?.nameAsProperty]?.type;
        const editingResourceType = referenceResourceSchema?.properties?.[editingResourceNameAsProperty]?.type;
        const isManyToMany = referenceResourceType === 'array' && editingResourceType === 'array';

        setReferenceResource({
          ...referenceResource,
          isManyToMany,
        });
        setEditingResource({
          ...editingResource,
          nameAsProperty: editingResourceNameAsProperty,
        });
      } catch {
        setReferenceResource({
          ...referenceResource,
          isManyToMany: false,
        });
      }
    }
  }, [isEmpty(referenceResource)]);

  useEffect(() => {
    if (api) {
      const { paths } = api;
      const target = `${basePath}/{id}`;
      let requestRef = paths?.[target]?.patch?.requestBody?.content?.['application/json']?.schema;

      requestRef = requestRef.$ref || requestRef.allOf?.filter(i => i?.$ref)?.[0]?.$ref;

      const requestComponent = ref.get(requestRef);
      const {
        properties, required,
      } = requestComponent;

      const writableFields = Object.keys(properties)
        ?.filter(key => !excludeFields?.includes(key) && !customFields?.includes(key))
        ?.filter(key => {
          const simpleFieldRO = properties?.[key]?.readOnly;
          const complexFieldRO = properties?.[key]?.allOf?.some(p => p.readOnly);
          return !simpleFieldRO && !complexFieldRO;
        });

      let tabComponents;

      const components = writableFields
        ?.filter(prop => writableFields.includes(prop))
        ?.map(prop => (
          <WealthInputWrapper source={prop}>
            {guessProperty({
              source: prop,
              properties,
              apiRef: ref,
              view: 'edit',
              resource,
              resources,
              required,
              record,
              locale,
            })}
          </WealthInputWrapper>
        ));

      if (referenceResource?.isManyToMany) {
        // if relation is many-many, render autocomplete if module have a few data, render list in tab if module have many data
        // components = writableFields
        //   ?.filter(
        //     prop => properties?.[prop]?.type !== 'list' && writableFields.includes(prop),
        //   )
        //   ?.map(prop => guessProperty({
        //     source: prop,
        //     properties,
        //     apiRef: ref,
        //     view: 'edit',
        //     resource,
        //   }));

        tabComponents = writableFields
          ?.filter(
            prop => properties?.[prop]?.type === 'array'
              && properties?.[prop]?.format === 'list'
              && writableFields.includes(prop),
          )
          ?.map(prop => guessProperty({
            source: prop,
            properties,
            apiRef: ref,
            view: 'edit',
            resource,
            resources,
            required,
            record,
            locale,
          }));
      } else {
        // else relation is one-to-many show addable list, removeable list in tabs
        // components = writableFields
        //   ?.filter(
        //     prop => properties?.[prop]?.type !== 'array' && writableFields.includes(prop),
        //   )
        //   ?.map(prop => guessProperty({
        //     source: prop,
        //     properties,
        //     apiRef: ref,
        //     view: 'edit',
        //     resource,
        //   }));

        tabComponents = writableFields
          ?.filter(prop => properties?.[prop]?.type === 'array' && writableFields.includes(prop))
          ?.map(prop => guessProperty({
            source: prop,
            properties,
            apiRef: ref,
            view: 'edit',
            resource,
            resources,
            required,
            record,
            locale,
          }));
      }

      setGuessedInfo({
        components,
        tabComponents,
        writableFields,
      });
    }
  }, [api, referenceResource?.isManyToMany, excludeFields?.length, customFields?.length, record]);

  useEffect(() => {
    const activeTabName = location?.pathname?.split('/')?.pop();
    guessedInfo?.tabComponents?.forEach(tab => {
      if (tab?.key === activeTabName) {
        setReferenceResource({
          ...referenceResource,
          name: tab.props?.reference,
          nameAsProperty: tab.props?.source || tab.key,
        });
      }
    });
  }, [location?.pathname, guessedInfo?.tabComponents]);

  const handleToggleDrawer = arg => {
    if (!arg) {
      setReferenceManyEdit(prevState => ({
        ...prevState,
        open: !prevState?.open,
      }));
      return;
    }

    const {
      tab, bulkAction,
    } = arg;

    setReferenceResource({
      ...referenceResource,
      name: tab.reference, // ex: category
      nameAsProperty: tab.name, // ex: categories
    });

    setReferenceManyEdit(prevState => ({
      ...prevState,
      open: !prevState?.open,
      bulkAction,
    }));
  };
  const realResource = resource.includes('/') ? resource.split('/')[1] : resource;

  const inputComponentList = [...spreadComponents(guessedInfo?.components), ...spreadComponents(rest.children)];

  return !isEmpty(guessedInfo?.writableFields) ? (
    <>
      <Edit
        {...rest}
        undoable={false}
        title={translate('ra.page.edit', {
          name: translate(`resources.${realResource}.name`),
          id: record?.id,
        })}
        transform={rest.transform || transform(guessedInfo?.writableFields)}
        actions={(
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {extendsActionMenu || <div />}
            <Actions
              {...rest}
              data={record}
              resource={resource}
            />
          </div>
        )}
      >
        <SimpleForm
          redirect={`/${resource}/${record?.id}/${redirect}`}
          className={classes.form}
        >
          <WealthInputArrangementProvider
            basePath={basePath}
            fieldOrder={fieldOrder}
          >
            {inputComponentList}
          </WealthInputArrangementProvider>
        </SimpleForm>
      </Edit>
      {
        /* Addable list (one-to-many) */
        !isEmpty(referenceResource) && !referenceResource?.isManyToMany && (
          <Drawer
            open={referenceManyEdit.open}
            anchor="right"
          >
            <Box width="70vw">
              <Button onClick={() => handleToggleDrawer()}>
                <ClearIcon />
              </Button>
              <RefManyEditGuesserList
                refManyEditProps={{
                  editingResource: editingResource?.name,
                  editingResourceId: editingResource?.id,
                  editingResourceNameAsProperty: editingResource?.nameAsProperty,
                  referenceResource: referenceResource?.name,
                  referenceResourceNameAsProperty: referenceResource?.nameAsProperty,
                  isManyToMany: referenceResource?.isManyToMany,
                  refManyBulkAction: RefManyBulkActionTypes.ADD,
                  onToggleDrawer: handleToggleDrawer,
                }}
              />
            </Box>
          </Drawer>
        )
      }
    </>
  ) : (
    <Loading />
  );
};

WealthEditGuesser.propTypes = {
  hasEdit: Type.bool,
  basePath: Type.string,
  redirect: Type.string, // "edit"|"show"|"list"|<custom route>
  children: Type.element,
  id: Type.string,
  resource: Type.string,
  extendsActionMenu: Type.element,
  excludeFields: Type.arrayOf(Type.string),
  customFields: Type.arrayOf(Type.string),
  fieldOrder: Type.arrayOf(Type.string),
};

WealthEditGuesser.defaultProps = {
  hasEdit: false,
  basePath: null,
  redirect: 'show',
  excludeFields: [],
  customFields: [],
  fieldOrder: null,
};

export default WealthEditGuesser;

const useStyles = makeStyles({
  form: {
    '&>[class*="RaCardContentInner"]:first-child': {
      padding: '28px 24px 0px',
    },
  },
});
