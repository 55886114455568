/* eslint-disable */
import { Box } from '@material-ui/core';
import React, { useMemo } from 'react';
import { ReferenceField, SelectField, TextField, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import { useReportContext } from '../../../base/components/reports/ReportLayout';
import { DEFAULT_CONSOLIDATED_CODE_FOR_INVOICING_REPORT, REPORT_TYPES } from '../../../constant';
import resourceSlug from '../../../constant/resource-slug';
import { LIST_REPORTS } from '../report-invoicing.list';
import Empty from '../../../base/components/empty';
import CustomReportTable from '../../../base/components/reports/CustomReportTable';
import { CAMPAIGN_ISSUERS } from '../../../constant/campaign';

const InvoicingReportResults = () => {
  const { data, loading: loadingAllData } = useReportContext();

  const hasNoData = useMemo(() => {
    const totalTableNoData = Object.values(data).reduce((totalNoDataTable, table) => {
      if (!table?.total && Array.isArray(table?.data)) {
        return totalNoDataTable + 1;
      }
      return totalNoDataTable;
    }, 0);
    return totalTableNoData === LIST_REPORTS.length && !loadingAllData;
  }, [data, loadingAllData]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        gap: 24,
      }}
    >
      {hasNoData ? (
        <Empty />
      ) : (
        <>
          <CommonReportTable />
          <MarketingTable />
          <DailyProgramTable />
        </>
      )}
    </Box>
  );
};

const CommonReportTable = () => {
  const translate = useTranslate();
  const { appliedFilters } = useReportContext();
  const currenciesList = useSelector(({ admin }) => admin.resources.currency.data);
  const targetCurrencyCode =
    currenciesList?.[appliedFilters?.consolidated]?.code || DEFAULT_CONSOLIDATED_CODE_FOR_INVOICING_REPORT;

  return (
    <CustomReportTable
      title={translate('resources.report.standard')}
      resource={resourceSlug.REPORT_INVOICING}
      reportList={LIST_REPORTS}
      reportType={REPORT_TYPES.STANDARD}
    >
      <ReferenceField link="show" source="group" reference="group" label="resources.group.name">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField link="show" source="brand" reference="brand" label="resources.brand.name">
        <TextField source="name" />
      </ReferenceField>
      <TextField label="resources.report.fields.currencyType" source="currencyType" sortable={false} />
      <ReferenceField link="show" label="resources.currency.name" source="currency" reference="currency">
        <TextField source="code" />
      </ReferenceField>
      <TextField label="resources.report.fields.turnOver" source="turnover" sortable />
      <TextField label="resources.report.fields.wonAmount" source="wonAmount" sortable />
      <TextField label="resources.report.fields.GGR" source="GGR" sortable />
      <TextField
        label={`${translate('resources.report.fields.GGRConsolidated')} (${targetCurrencyCode})`}
        source="GGRConsolidated"
        sortable
      />
    </CustomReportTable>
  );
};

const MarketingTable = () => {
  const translate = useTranslate();
  const { appliedFilters } = useReportContext();
  const currenciesList = useSelector(({ admin }) => admin.resources.currency.data);
  const targetCurrencyCode =
    currenciesList?.[appliedFilters?.consolidated]?.code || DEFAULT_CONSOLIDATED_CODE_FOR_INVOICING_REPORT;

  return (
    <CustomReportTable
      title={translate('resources.report.marketing')}
      resource={resourceSlug.REPORT_INVOICING}
      reportList={LIST_REPORTS}
      reportType={REPORT_TYPES.MARKETING}
    >
      <ReferenceField link="show" source="group" reference="group" label="resources.group.name">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField link="show" source="brand" reference="brand" label="resources.brand.name">
        <TextField source="name" />
      </ReferenceField>
      <TextField label="resources.report.fields.campaignCode" source="campaignCode" sortable={false} />
      <TextField label="resources.report.fields.campaignName" source="campaignName" sortable={false} />
      <SelectField
        source="issuer"
        label="resources.campaign.fields.issuer"
        choices={Object.values(CAMPAIGN_ISSUERS).map((issuer) => ({
          id: issuer,
          name: translate(`resources.campaign.issuer.${issuer}`),
        }))}
        sortable={false}
      />
      <TextField label="resources.report.fields.currencyType" source="currencyType" sortable={false} />
      <ReferenceField link="show" label="resources.currency.name" source="currency" reference="currency">
        <TextField source="code" />
      </ReferenceField>
      <TextField label="resources.report.fields.stakes" source="stakes" sortable />
      <TextField label="resources.report.fields.wonAmount" source="wonAmount" sortable />
      <TextField label="resources.report.fields.GGR" source="GGR" sortable />
      <TextField
        label={`${translate('resources.report.fields.GGRConsolidated')} (${targetCurrencyCode})`}
        source="GGRConsolidated"
        sortable
      />
    </CustomReportTable>
  );
};

const DailyProgramTable = () => {
  const translate = useTranslate();
  const { appliedFilters } = useReportContext();
  const currenciesList = useSelector(({ admin }) => admin.resources.currency.data);
  const targetCurrencyCode =
    currenciesList?.[appliedFilters?.consolidated]?.code || DEFAULT_CONSOLIDATED_CODE_FOR_INVOICING_REPORT;

  return (
    <CustomReportTable
      title={translate('resources.report.dailyPrograms')}
      resource={resourceSlug.REPORT_INVOICING}
      reportList={LIST_REPORTS}
      reportType={REPORT_TYPES.DAILY_PROGRAMS}
    >
      <ReferenceField link="show" source="group" reference="group" label="resources.group.name">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField link="show" source="brand" reference="brand" label="resources.brand.name">
        <TextField source="name" />
      </ReferenceField>
      <TextField label="resources.report.fields.currencyType" source="currencyType" sortable={false} />
      <ReferenceField link="show" label="resources.currency.name" source="currency" reference="currency">
        <TextField source="code" />
      </ReferenceField>
      <TextField label="resources.report.fields.bonusCashConversion" source="amount" sortable />
      <TextField
        label={`${translate('resources.report.fields.consolidated')} (${targetCurrencyCode})`}
        source="amountConsolidated"
        sortable
      />
    </CustomReportTable>
  );
};

export default InvoicingReportResults;
