import { Box } from '@material-ui/core';
import React from 'react';
import { Toolbar } from 'react-admin';
import { SaveButton } from '../buttons';

const DrawerToolBar = (props) => (
  <Box
    component={Toolbar}
    sx={{
      bgcolor: 'transparent !important',
      padding: '0 !important',
      justifyContent: 'flex-end',
      minHeight: 'fit-content !important',
    }}
    {...props}
  >
    <SaveButton icon={<></>} disabled={props.pristine || props?.disabled} />
  </Box>
);

export default DrawerToolBar;
