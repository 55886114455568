/* eslint-disable import/no-cycle */
import { makeStyles } from '@material-ui/core';
import { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { useLocale, useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';
import ctzc from 'country-tz-currency';
import moment from 'moment';
import { NavigationContext } from '../../../App';
import { UUID_REGEX } from '../../../constant';
import resourceSlug from '../../../constant/resource-slug';
import { CAMPAIGN_STATUS, CHECK_IN_TYPES } from '../../../constant/campaign';
import { sanitizeObject } from '../../../services/util';

export const CheckInFormContext = createContext({});

export const useCheckInForm = () => {
  const values = useContext(CheckInFormContext);

  if (values === null) {
    throw new Error('Make sure to use `CheckInFormContext` before using the steps.');
  }

  return values;
};

export const useTableListStyles = makeStyles((theme) => ({
  root: {
    "& [class*='Paper']": {
      boxShadow: 'none',
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 8,
    },
  },
  wrapper: {
    margin: '16px 28px',
  },
}));

export const useCheckInNavigation = () => {
  const t = useTranslate();
  const { setNavigationData } = useContext(NavigationContext);
  const { pathname } = useLocation();
  const locale = useLocale();

  useEffect(() => {
    setNavigationData({
      pageTitleInfo: {
        label: t('resources.check-in.details-title'),
        showSubLabel: false,
      },
      breadcrumbs: [
        {
          label: t('ra.page.home'),
          link: '/',
        },
        {
          label: t('resources.group.boost.name'),
        },
        {
          label: t('resources.check-in.name'),
          link: `/${resourceSlug.CHECK_IN}`,
        },
        {
          label: `#${pathname
            .split('/')
            .find((path) => path.match(UUID_REGEX))
            .slice(0, 4)}`,
          isActive: true,
        },
      ],
    });
    return () => {
      setNavigationData({});
    };
  }, [t, pathname, locale]);
};

export const getTimezoneByCurrencyCode = (currencyCode) => {
  if (!currencyCode) return undefined;

  return Object.values(ctzc.getAllCountries()).find((country) => country.currencyCode === currencyCode)?.timeZone?.[0];
};

export const getTimezoneByTzId = (timezoneId) => ctzc.getTzById(timezoneId);

export const useCheckInCampaignDuration = () => {
  const { data } = useCheckInForm();

  const { startTime, endTime } = data.current;

  return useMemo(
    () =>
      startTime && endTime
        ? Math.abs(Math.ceil(moment(startTime).startOf('day').diff(moment(endTime).endOf('day'), 'days', true))) + 1
        : 0,
    [startTime, endTime],
  );
};

export const useCheckInValidate = () => {
  const t = useTranslate();

  const campaignNameValidator = useCallback(
    (value) => {
      const trimmedValue = value?.trim();
      if (!trimmedValue?.length) {
        return t('ra.validation.required');
      }

      if (trimmedValue.length >= 60) {
        return t('ra.validation.maxLength', {
          max: 60,
        });
      }

      return undefined;
    },
    [t],
  );

  const toCheckInValidator = useCallback(
    (value, values) => {
      if (!value) {
        return t('ra.validation.required');
      }

      if (values.fromCheckInTime && moment(value, 'hh:mm:ss').isBefore(moment(values.fromCheckInTime, 'hh:mm:ss'))) {
        return t('resources.check-in.validation.to-check-in-time');
      }

      return undefined;
    },
    [t],
  );

  const endDateValidator = useCallback(
    (value, values) => {
      if (!value) {
        return t('ra.validation.required');
      }

      if (values.startTime && moment(value).isBefore(moment(values.startTime))) {
        return t('resources.check-in.validation.end-date');
      }

      return undefined;
    },
    [t],
  );

  const rewardConfigValidate = useCallback(
    (value, values) => {
      if (
        values.type === CHECK_IN_TYPES.CUMULATIVE &&
        !Object.values(value || {}).find((day) => !!day.rewards?.length)
      ) {
        return t('resources.check-in.validation.cumulative-reward-config');
      }
      if (
        values.type === CHECK_IN_TYPES.CONSECUTIVE &&
        values.requiredCheckInDays &&
        !value?.[values.requiredCheckInDays]?.rewards?.length
      ) {
        return t('resources.check-in.validation.consecutive-reward-config');
      }
      return undefined;
    },
    [t],
  );

  return useMemo(
    () => ({
      campaignNameValidator,
      toCheckInValidator,
      endDateValidator,
      rewardConfigValidate,
    }),
    [campaignNameValidator, toCheckInValidator, endDateValidator, rewardConfigValidate],
  );
};

export const formatCheckInData = (data, isCloned = false) => {
  const campaignName = data?.campaignName || '';
  const selectAllGroup = !!data?.group?.isSelectAll;
  const selectAllBrand = !!data?.brand?.isSelectAll;
  const type = data?.campaignSettings?.missionType;
  const requiredTimeFrame = !!data?.campaignSettings?.fromCheckInTime && !!data?.campaignSettings?.toCheckInTime;

  return {
    campaignName: isCloned ? `${campaignName} (CLONED)` : campaignName,
    currencyId: data?.currencyId || '',
    timezone: data?.timezone || '',
    startTime: data?.startTime
      ? isCloned && moment(data.startTime).isSameOrBefore(moment())
        ? undefined
        : moment(data.startTime)
      : undefined,
    endTime: data?.endTime
      ? isCloned && moment(data.endTime).isSameOrBefore(moment())
        ? undefined
        : moment(data.endTime).endOf('day')
      : undefined,
    group: {
      isSelectAll: selectAllGroup,
      ids: (selectAllGroup ? data?.group?.excludes : data?.group?.includes) || [],
    },
    brand: {
      isSelectAll: selectAllBrand,
      ids: (selectAllBrand ? data?.brand?.excludes : data?.brand?.includes) || [],
    },
    termAndCondition: data?.termAndCondition || [],
    title: data?.title || [],
    bannerConfigs: data?.bannerConfigs || [],
    type: type || CHECK_IN_TYPES.CUMULATIVE,
    ...(type === CHECK_IN_TYPES.CONSECUTIVE && {
      requiredCheckInDays: data?.campaignSettings?.requiredCheckInDays,
      streakRepeatable: data?.campaignSettings?.streakRepeatable,
      rewardRepeatable: data?.campaignSettings?.streakRepeatable || !!data?.campaignSettings?.rewardRepeatable,
    }),
    timeFrameRequired: requiredTimeFrame,
    ...(requiredTimeFrame && {
      fromCheckInTime: data?.campaignSettings?.fromCheckInTime,
      toCheckInTime: data?.campaignSettings?.toCheckInTime,
    }),
    destinationTransferWallet: data?.walletConversion,
    rewardConfig:
      data?.campaignSettings?.rewardConfigs?.reduce?.((acc, currentConfig) => {
        acc[currentConfig.totalCheckInDays] = {
          rewards: currentConfig.rewards || [],
        };
        return acc;
      }, {}) || {},
  };
};

export const parseCheckInData = (data) => {
  const selectAllGroup = !!data?.group?.isSelectAll;
  const selectAllBrand = !!data?.brand?.isSelectAll;
  const campaignDuration =
    Math.abs(Math.ceil(moment(data?.startTime).startOf('day').diff(moment(data?.endTime).endOf('day'), 'days', true))) +
    1;

  return sanitizeObject({
    name: data?.campaignName,
    currencyId: data?.currencyId,
    group: {
      isSelectAll: selectAllGroup,
      ...(selectAllGroup
        ? {
            excludes: data?.group?.ids || [],
          }
        : {
            includes: data?.group?.ids || [],
          }),
    },
    brand: {
      isSelectAll: selectAllBrand,
      ...(selectAllBrand
        ? {
            excludes: data?.brand?.ids,
          }
        : {
            includes: data?.brand?.ids,
          }),
    },
    termAndCondition: data?.termAndCondition || undefined,
    title: data?.title || undefined,
    bannerConfigs: data?.bannerConfigs || undefined,
    startTime: moment(data?.startTime).startOf('day').toISOString(),
    endTime: moment(data?.endTime).startOf('day').add(1, 'days').toISOString(),
    config: sanitizeObject({
      requiredCheckInDays: data?.requiredCheckInDays,
      streakRepeatable: data?.streakRepeatable,
      rewardRepeatable: data?.rewardRepeatable,
      fromCheckInTime: data?.fromCheckInTime || undefined,
      toCheckInTime: data?.toCheckInTime || undefined,
      timezone: data?.timezone,
      checkInType: data?.type,
    }),
    rewardConfigs: Object.entries(data?.rewardConfig || {})
      .filter(([totalCheckInDays]) => totalCheckInDays <= campaignDuration)
      .map(([totalCheckInDays, config]) => ({
        totalCheckInDays: +totalCheckInDays,
        rewardIds: config?.rewards?.map((reward) => reward.rewardId),
      })),
    destinationTransferWallet: data?.destinationTransferWallet,
    status: CAMPAIGN_STATUS.ACTIVE,
  });
};
