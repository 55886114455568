import { Edit, useEditController, useTranslate } from 'react-admin';
import React from 'react';
import { Box } from '@material-ui/core';
import resourceSlug from '../../constant/resource-slug';
import { useUpdateCustom } from '../../base/hooks';
import { formatMissionData, parseMissionData, useMissionNavigation } from './components/utils';
import Actions from '../../base/components/ra/views/action-toolbar';
import MissionCreateUpdate from './components/MissionCreateEdit';

const MissionUpdate = (props) => {
  const t = useTranslate();
  const { record } = useEditController(props);

  useMissionNavigation();

  const { update, updateLoading: isUpdating } = useUpdateCustom({
    resource: resourceSlug.MISSION,
    successMessage: 'resources.check-in.updated-successfully',
    resourceTranslated: t(`resources.${resourceSlug.MISSION}.name`),
  });

  const handleSave = (data) => {
    update(resourceSlug.MISSION, record.id, parseMissionData(data));
  };

  return (
    <Box
      component={Edit}
      {...props}
      width="100%"
      maxWidth={1200}
      mx="auto"
      actions={<Actions data={record} resource={resourceSlug.MISSION} hasList hasShow />}
    >
      <MissionCreateUpdate
        onSave={handleSave}
        isSaving={isUpdating}
        uneditableFields={record?.uneditableFields}
        initialValues={formatMissionData(record)}
      />
    </Box>
  );
};

export default MissionUpdate;
