/* eslint-disable import/no-cycle */
import React, { memo } from 'react';
import { required } from 'react-admin';
import { useForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import { AutocompleteInput } from '../../../../../base/components/ra/inputs';
import { getTimezoneByCurrencyCode, useCheckInForm } from '../../utils';
import resourceSlug from '../../../../../constant/resource-slug';
import ReferenceInput from '../../../../../base/components/ra/inputs/reference.input';

const CurrencyInput = () => {
  const form = useForm();
  const { uneditableFields } = useCheckInForm();
  const currencies = useSelector(({ admin }) => admin.resources.currency.data || {});

  return (
    <ReferenceInput
      source="currencyId"
      isRequired
      resettable
      filter={{
        enabled: true,
      }}
      label="resources.reward.fields.currency"
      reference={resourceSlug.CURRENCY}
      validate={required()}
      filterToQuery={(value) => ({
        'name||$cont': value,
      })}
      onChange={(currencyId) =>
        form.batch(() => {
          form.change('brand.ids', []);
          form.change('group.ids', []);
          form.change('timezone', getTimezoneByCurrencyCode(currencies[currencyId]?.code));
        })
      }
      disabled={uneditableFields?.includes?.('currencyId')}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  );
};

export default memo(CurrencyInput);
