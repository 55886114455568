/* eslint-disable react/prop-types, eqeqeq */
import React from 'react';
import { useTranslate } from 'react-admin';
import WealthEditGuesser from '../../base/components/guesser/wealth-edit.guesser';
import MarketingCampaignCheckbox from './components/MarketingCampaignCheckbox';
import { BooleanInput } from '../../base/components/ra/inputs';
import GameIconUploader from './components/GameIconUploader';

function GameEdit(props) {
  const translate = useTranslate();

  const sanitizeObject = (values) =>
    Object.keys(values).reduce((accumulator, key) => {
      if (key === 'gameIcon') {
        accumulator[key] = values[key]?.fileKey || '';
      } else if (values[key] != null && values[key] != undefined) {
        accumulator[key] = values[key];
      }
      return accumulator;
    }, {});

  return (
    <WealthEditGuesser
      {...props}
      excludeFields={['enabled', 'isAvailable', 'isMcEnabled', 'isHotGame', 'isNewGame', 'gameIcon']}
      transform={sanitizeObject}
      fieldOrder={[
        'gameIcon',
        'gameId',
        'name',
        'gameType',
        'playUrl',
        'gameLogicUrl',
        'desc',
        'preview',
        'isNewGame',
        'isHotGame',
        'isMcEnabled',
      ]}
    >
      <GameIconUploader source="gameIcon" />
      <BooleanInput label={translate('resources.game.fields.isNewGame')} source="isNewGame" />
      <BooleanInput label={translate('resources.game.fields.isHotGame')} source="isHotGame" />
      <MarketingCampaignCheckbox source="isMcEnabled" />
    </WealthEditGuesser>
  );
}

export default GameEdit;
