import React from 'react';
import { useTranslate } from 'react-admin';
import Box from '@material-ui/core/Box';
import { Empty } from 'antd';

function EmptyTable(props) {
  const translate = useTranslate();
  return (
    <Box
      p={8}
      pb={0}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      sx={{
        '& .ant-empty .ant-empty-description': {
          color: '#9e9e9e',
        },
      }}
      {...props}
    >
      <Empty description={translate('ra.text.noRecords')} />
    </Box>
  );
}

export default EmptyTable;
