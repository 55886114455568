import React from 'react';
import { useTranslate } from 'react-admin';
import UrlInput from '../../../base/components/customized/common-input/UrlInput';
import InputWithTooltipWrapper from '../../../base/components/common/InputWithTooltipWrapper';

export const GET_PLAYER_TOKEN_URL_FIELD_KEY = 'getPlayerTokenUrl';

const GetPlayerTokenUrlInput = (props) => {
  const translate = useTranslate();

  return (
    <InputWithTooltipWrapper
      source={GET_PLAYER_TOKEN_URL_FIELD_KEY}
      title={translate('resources.brand.fields.getPlayerTokenUrl')}
      resettable
      helperText
      {...props}
      tooltip={translate('resources.brand.fields.getPlayerTokenUrlTooltip')}
    >
      <UrlInput />
    </InputWithTooltipWrapper>
  );
};

export default GetPlayerTokenUrlInput;
