/* eslint-disable */
import { FunctionField, useTranslate } from 'react-admin';
import React, { useMemo, useState } from 'react';
import WealthListGuesser from '../../base/components/guesser/wealth-list.guesser';
import resourceSlug from '../../constant/resource-slug';
import { CURRENCY_TYPES, FILTER_PREFIX_ON_VALUE, SERVER_DATETIME_FORMAT } from '../../constant';
import { keyBy } from 'lodash';
import { DefaultNoPagePagination, useNoPageListData } from '../../base/components/customized/CustomizedNoPageList';
import { CAMPAIGN_PLAYER_STATUS, CAMPAIGN_TYPES } from '../../constant/campaign';
import { REWARD_TYPES } from '../../constant/reward';
import moment from 'moment';
import CustomLinkField from '../../base/components/ra/fields/customLink.field';
import { useCampaignDetailsUrl } from '../../base/hooks/useCampaignDetailsUrl';
import PlayerRewardStatus from '../check-in/components/CheckInDetails/CheckInPlayerRewards/PlayerRewardStatus';
import UserRewardDetailsDrawer from './components/UserRewardDetailsDrawer';

function ReportUserRewardsList(props) {
  const t = useTranslate();
  const [details, setDetails] = useState();

  const userRewards = useNoPageListData(resourceSlug.REPORT_USER_REWARDS);
  const { getCampaignDetailsUrl } = useCampaignDetailsUrl();

  const filterDefaultValues = useMemo(() => {
    const today = moment().add(1, 'days').startOf('day').format(SERVER_DATETIME_FORMAT);
    const yesterday = moment().subtract(1, 'days').startOf('day').format(SERVER_DATETIME_FORMAT);

    return {
      issuedDate: `${FILTER_PREFIX_ON_VALUE.BETWEEN}${yesterday},${today}`,
    };
  }, []);

  return (
    <>
      <WealthListGuesser
        rowClick={(_, __, record) => setDetails(record)}
        reportInterface={{
          filter: [
            {
              label: 'resources.campaign.fields.issued-date',
              source: 'issuedDate',
              inputType: 'date-range',
            },
            {
              label: 'resources.campaign.fields.used-date',
              source: 'usedDate',
              inputType: 'date-range',
              props: {
                allowClear: true,
              },
            },
            { label: 'common.fields.nativeId', operator: '$eq', source: 'nativeId' },
            {
              label: 'resources.group.name',
              source: 'group',
              reference: resourceSlug.GROUP,
              inputType: 'reference',
              optionText: ['name'],
              choiceLimit: 25,
            },
            {
              label: 'resources.brand.name',
              source: 'brand',
              reference: resourceSlug.BRAND,
              inputType: 'reference',
              optionText: ['name'],
              choiceLimit: 25,
            },
            {
              label: 'common.fields.sourceType',
              source: 'sourceType',
              inputType: 'select',
              choices: Object.values(CAMPAIGN_TYPES).map((type) => ({
                id: type,
                name: t(`common.sourceType.${type}`),
              })),
            },
            { label: 'common.fields.campaignCode', operator: '$eq', source: 'campaignCode' },
            {
              label: 'resources.reward.fields.reward-type',
              source: 'rewardType',
              inputType: 'select',
              choices: Object.values(REWARD_TYPES).map((type) => ({
                id: type,
                name: t(`resources.reward.reward-type.${type}`),
              })),
            },
            {
              label: 'common.fields.status',
              source: 'status',
              inputType: 'select',
              choices: Object.values(CAMPAIGN_PLAYER_STATUS).map((item) => ({
                id: item,
                name: t(`resources.campaign.player-status.${item}`),
              })),
            },
            {
              label: 'resources.report.fields.currencyType',
              source: 'currencyType',
              inputType: 'select',
              choices: Object.values(CURRENCY_TYPES).map((type) => ({ id: type, name: type })),
            },
            {
              label: 'resources.currency.name',
              source: 'currency',
              reference: resourceSlug.CURRENCY,
              inputType: 'reference',
              optionText: ['name'],
              filter: {
                enabled: true,
              },
            },
          ],
          response: {
            playerId: {
              custom: (
                <CustomLinkField
                  source="nativeId"
                  label={t('common.fields.nativeId')}
                  href={(record) => `/#/${resourceSlug.PLAYER}/${record.playerId}/show`}
                  title={(record) => record.nativeId}
                />
              ),
              index: 0,
            },
            group: {
              custom: <FunctionField label={t('resources.group.name')} render={(record) => record.group} />,
              index: 1,
            },
            brand: {
              custom: <FunctionField label={t('resources.brand.name')} render={(record) => record.brand} />,
              index: 2,
              sortable: false,
            },
            sourceType: {
              custom: (
                <FunctionField
                  label={t('common.fields.sourceType')}
                  render={(record) => t(`common.sourceType.${record.sourceType}`)}
                />
              ),
              index: 3,
            },
            campaignId: {
              custom: (
                <CustomLinkField
                  source="campaignCode"
                  label={t('common.fields.campaignCode')}
                  href={(record) => getCampaignDetailsUrl(record.sourceType, record.campaignId)}
                  title={(record) => record.campaignCode}
                />
              ),
              index: 4,
            },
            rewardName: {
              custom: (
                <FunctionField
                  label={t('resources.reward.fields.reward-name')}
                  render={(record) => record.rewardName || '-'}
                />
              ),
              index: 5,
            },
            rewardType: {
              custom: (
                <FunctionField
                  label={t('resources.reward.fields.reward-type')}
                  render={(record) => t(`resources.reward.reward-type.${record.rewardType}`)}
                />
              ),
              index: 6,
            },
            currencyName: { label: t('resources.currency.name'), index: 7, sortable: false },
            currencyType: { label: t('resources.report.fields.currencyType'), index: 8, sortable: false },
            issuedDate: { label: t('resources.campaign.fields.issued-date'), format: 'date', index: 9 },
            usedDate: { label: t('resources.campaign.fields.used-date'), format: 'date', index: 10, sortable: false },
            status: {
              custom: (
                <FunctionField
                  label={t('common.fields.status')}
                  render={(record) => <PlayerRewardStatus status={record.status} />}
                />
              ),
              index: 11,
            },
            issuedQuantity: { hidden: true },
            usedQuantity: { hidden: true },
            nativeId: { hidden: true },
            campaignCode: { hidden: true },
            amount: { hidden: true },
            wonAmount: { hidden: true },
          },
        }}
        controllerProps={{
          data: keyBy(userRewards, 'id'),
          ids: userRewards?.map(({ id }) => id),
        }}
        pagination={<DefaultNoPagePagination resource={resourceSlug.REPORT_USER_REWARDS} />}
        filterDefaultValues={filterDefaultValues}
        {...props}
      />
      <UserRewardDetailsDrawer data={details} onClose={() => setDetails(undefined)} open={!!details} />
    </>
  );
}

export default ReportUserRewardsList;
