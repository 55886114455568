/* eslint-disable */
import { useTranslate } from 'react-admin';
import React, { useMemo } from 'react';
import { FILTER_PREFIX_ON_VALUE, SERVER_DATETIME_FORMAT } from '../../constant';
import WealthListGuesser from '../../base/components/guesser/wealth-list.guesser';
import resourceSlug from '../../constant/resource-slug';
import { CURRENCY_TYPES } from '../../constant';
import { DEFAULT_CONSOLIDATED_CODE_FOR_INVOICING_REPORT } from '../../constant';
import moment from 'moment';
import { keyBy } from 'lodash';
import { DefaultNoPagePagination, useNoPageListData } from '../../base/components/customized/CustomizedNoPageList';

function ReportPlayerCampaignUsageList(props) {
  const t = useTranslate();

  const playerCampaignUsage = useNoPageListData(resourceSlug.REPORT_PLAYER_MKT_CAMPAIGN);

  const filterDefaultValues = useMemo(() => {
    const today = moment().add(1, 'days').startOf('day').format(SERVER_DATETIME_FORMAT);
    const yesterday = moment().subtract(1, 'days').startOf('day').format(SERVER_DATETIME_FORMAT);

    return {
      created: `${FILTER_PREFIX_ON_VALUE.BETWEEN}${yesterday},${today}`,
    };
  }, []);

  return (
    <WealthListGuesser
      reportInterface={{
        filter: [
          {
            label: 'fromTo',
            source: 'created',
            reference: '',
            inputType: 'date-range',
          },
          {
            label: 'nativeId',
            source: 'nativeId',
            operator: '$eq',
          },
          { label: 'campaignCode', operator: '$eq', source: 'campaignCode' },
          {
            label: 'group',
            source: 'group',
            reference: resourceSlug.GROUP,
            inputType: 'reference',
            optionText: ['name'],
            choiceLimit: 25,
          },
          {
            label: 'brand',
            source: 'brand',
            reference: resourceSlug.BRAND,
            inputType: 'reference',
            optionText: ['name'],
            choiceLimit: 25,
          },
          {
            label: 'game',
            source: 'game',
            reference: resourceSlug.GAME,
            inputType: 'reference',
            optionText: ['name'],
            choiceLimit: 25,
            translatable: true,
          },
          {
            label: 'currencyType',
            source: 'currencyType',
            inputType: 'select',
            resettable: true,
            choices: Object.values(CURRENCY_TYPES).map((type) => ({ id: type, name: type })),
          },
          {
            label: 'currency',
            source: 'currency',
            reference: resourceSlug.CURRENCY,
            inputType: 'reference',
            optionText: ['code'],
            filter: {
              enabled: true,
            },
          },
        ],
        response: {
          nativeId: { label: t('resources.player.fields.nativeId'), index: 0, sortable: false },
          group: { label: t('resources.group.name'), type: 'string', format: 'text', index: 1, sortable: false },
          brand: { label: t('resources.brand.name'), type: 'string', format: 'text', index: 2, sortable: false },
          campaignCode: { label: t('resources.report.fields.campaignCode'), index: 3, sortable: false },
          game: { label: t('resources.game.name'), translatable: true, index: 4, sortable: false },
          currencyType: { label: t('resources.report.fields.currencyType'), index: 5, sortable: false },
          currencyName: { label: t('resources.currency.name'), index: 6, sortable: false },
          usedQuantity: { label: t('resources.report.fields.usedQuantity'), index: 7, sortable: false },
          winAmountBase: {
            label: t('resources.report.fields.wonAmount'),
            index: 8,
            type: 'decimal',
            format: 'default_currency',
            options: {
              minimumFractionDigits: 2,
              maximumFractionDigits: 4,
            },
          },
          winAmount: {
            label: `${t('resources.report.fields.wonAmount')} (${DEFAULT_CONSOLIDATED_CODE_FOR_INVOICING_REPORT})`,
            index: 9,
            type: 'decimal',
            format: 'default_currency',
            options: {
              minimumFractionDigits: 2,
              maximumFractionDigits: 4,
            },
          },
          targetCurrencyCode: {
            hidden: true,
          },
        },
      }}
      controllerProps={{
        data: keyBy(playerCampaignUsage, 'id'),
        ids: playerCampaignUsage?.map(({ id }) => id),
      }}
      pagination={<DefaultNoPagePagination resource={resourceSlug.REPORT_PLAYER_MKT_CAMPAIGN} />}
      filterDefaultValues={filterDefaultValues}
      {...props}
    />
  );
}

export default ReportPlayerCampaignUsageList;
