import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslate } from 'react-admin';

const CampaignPlayerListAction = () => {
  const t = useTranslate();
  return <Typography variant="h4">{t('resources.check-in.player-rewards')}</Typography>;
};

export default CampaignPlayerListAction;
