import React, { useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { Refresh } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { IconButton, Tooltip } from '@material-ui/core';
import { useGetNoPageListData } from '../../../base/components/customized/CustomizedNoPageList';
import { showConfirmDialog } from '../../../services/redux/app/app.actions';

const RetryButton = ({ playerId, transactionId, resource, disabled }) => {
  const t = useTranslate();
  const { retryTransaction } = useRetryTransaction(resource);

  return (
    <Tooltip title={t('ra.action.retry')}>
      <div>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            retryTransaction(playerId, transactionId);
          }}
          disabled={disabled}
        >
          <Refresh />
        </IconButton>
      </div>
    </Tooltip>
  );
};

RetryButton.propTypes = {
  playerId: PropTypes.string.isRequired,
  transactionId: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
};

export default RetryButton;

const useRetryTransaction = (resource) => {
  const [isTrying, setIsTrying] = useState(false);
  const notify = useNotify();
  const t = useTranslate();
  const dispatch = useDispatch();
  const { refetchListData } = useGetNoPageListData(resource);

  const confirm = (playerId, transactionId) => {
    dispatch(
      showConfirmDialog({
        isOpen: true,
        title: 'resources.bonus-wallet-transaction.retry-transaction-title',
        content: 'resources.bonus-wallet-transaction.retry-transaction-desc',
        onConfirm: () => retryTransaction(playerId, transactionId),
      }),
    );
  };

  const retryTransaction = async (playerId, transactionId) => {
    setIsTrying(true);

    try {
      await axios.post('/api/player/retry-payout-bonus', {
        playerId,
        walletTransactionId: transactionId,
      });
      await refetchListData();
      notify(t('resources.bonus-wallet-transaction.retried-successfully'), 'success');
    } catch (error) {
      console.log(error);
    } finally {
      setIsTrying(false);
    }
  };

  return {
    retryTransaction: confirm,
    isTrying,
  };
};
