import React from 'react';
import { Box, Chip, useTheme } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { WALLET_TRANSACTION_STATUS } from '../../../constant/transaction';

const WalletTransactionStatus = ({ status }) => {
  const t = useTranslate();
  const theme = useTheme();

  return (
    <Box
      component={Chip}
      sx={{
        "&[class*='Chip']": {
          borderRadius: 6,
          padding: '3px 12px',
          backgroundColor: {
            [WALLET_TRANSACTION_STATUS.COMPLETED]: theme.palette.success.main,
            [WALLET_TRANSACTION_STATUS.FAILED]: theme.palette.error.main,
            [WALLET_TRANSACTION_STATUS.PROCESSING]: theme.palette.primary.main,
          }[status],
          color: 'white',
          '& span': {
            padding: '0 !important', // To override default styles
          },
        },
      }}
      label={t(`resources.wallet-transaction.status.${status}`)}
    />
  );
};

export default WalletTransactionStatus;
