/* eslint-disable import/no-cycle, no-use-before-define  */
import React, { useState } from 'react';
import {
  RecordContext,
  ReferenceField,
  SelectField,
  TextField,
  useTranslate,
  FunctionField,
  useLocale,
} from 'react-admin';
import { Box, Card, makeStyles, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import resourceSlug from '../../../../../constant/resource-slug';
import { DateField } from '../../../../../base/components/ra/fields';
import { useMissionNavigation, useTableListStyles } from '../../utils';
import CommonWrapper from '../../../../../base/components/reverse-integration/common-wrapper';
import MissionPlayerRewardFilters from './MissionPlayerRewardFilters';
import { REWARD_TYPES } from '../../../../../constant/reward';
import MissionDetailsTabs, { MISSION_TABS } from '../MissionDetailsTabs';
import DrawerSimpleShowLayout from '../../../../../base/components/ra/drawers/DrawerSimpleShowLayout';
import { formatNumber } from '../../../../../services/util/formatNumber';
import MuiDrawer from '../../../../../base/components/mui/MuiDrawer';
import { CustomizedNoPageList } from '../../../../../base/components/customized/CustomizedNoPageList';
import PlayerRewardStatus from '../../../../check-in/components/CheckInDetails/CheckInPlayerRewards/PlayerRewardStatus';

const MissionPlayerRewardsList = () => {
  const classes = useTableListStyles();
  const { id } = useParams();
  const [details, setDetails] = useState();

  useMissionNavigation();

  return (
    <Card>
      <MissionDetailsTabs currentTab={MISSION_TABS.PLAYER_REWARDS}>
        <CommonWrapper className={classes.wrapper}>
          <CustomizedNoPageList
            resource={`${resourceSlug.MISSION}/${id}/player-rewards`}
            basePath={`/${resourceSlug.MISSION}/${id}/player-rewards`}
            filters={<MissionPlayerRewardFilters />}
            hasCreate={false}
            hasEdit={false}
            hasShow={false}
            className={classes.root}
            rowClick={(_, __, record) => setDetails(record)}
            sort={{
              field: 'issuedDate',
              order: 'DESC',
            }}
          >
            <TextField source="nativeId" label="common.fields.nativeId" />
            <ReferenceField
              reference="group"
              source="groupId"
              label="common.fields.group"
              sortable={false}
              emptyText="-"
              link="show"
            >
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField
              reference="brand"
              source="brandId"
              label="common.fields.brand"
              sortable={false}
              emptyText="-"
              link="show"
            >
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField
              reference="reward"
              source="rewardId"
              label="resources.reward.fields.reward"
              sortable={false}
              emptyText="-"
              link={(record) => `/${resourceSlug.REWARD}/${record.rewardId}/details`}
            >
              <TextField source="rewardName" />
            </ReferenceField>
            <DateField source="issuedDate" label="resources.check-in.fields.issued-date" sortable={false} />
            <DateField source="expiryDate" label="resources.check-in.fields.expiry-date" sortable={false} />
            <DateField source="usedDate" label="resources.check-in.fields.used-date" sortable={false} emptyText="-" />
          </CustomizedNoPageList>
        </CommonWrapper>
      </MissionDetailsTabs>
      <PlayerRewardDetailDrawer data={details} onClose={() => setDetails(undefined)} open={!!details} />
    </Card>
  );
};

const PlayerRewardDetailDrawer = ({ data, onClose, open }) => {
  const t = useTranslate();
  const classes = useStyles();
  const locale = useLocale();

  return (
    <MuiDrawer
      open={open}
      anchor="right"
      onClose={onClose}
      title={t('resources.check-in.player-reward-details')}
      classes={{
        paper: classes.paper,
      }}
    >
      <RecordContext.Provider value={data}>
        <Box display="flex" flexDirection="column" height="100%">
          <Box
            component={DrawerSimpleShowLayout}
            sx={{
              flexGrow: 1,
              overflow: 'scroll',
            }}
          >
            <Box
              component={Typography}
              variant="h4"
              sx={{
                marginBottom: '10px !important',
              }}
            >
              {t('resources.check-in.basic-info')}
            </Box>
            <TextField source="nativeId" label="common.fields.nativeId" />
            <ReferenceField reference="group" source="groupId" label="common.fields.group" emptyText="-" link={false}>
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField reference="brand" source="brandId" label="common.fields.brand" emptyText="-" link={false}>
              <TextField source="name" />
            </ReferenceField>
            <FunctionField
              label="resources.check-in.fields.issued-date"
              render={(record) => <DateField record={record} source="issuedDate" emptyText="-" />}
            />
            <FunctionField
              label="resources.check-in.fields.expiry-date"
              render={(record) => <DateField record={record} source="expiryDate" emptyText="-" />}
            />

            <FunctionField
              label="resources.check-in.fields.used-date"
              render={(record) => <DateField record={record} source="usedDate" emptyText="-" />}
            />
            <FunctionField
              label="common.fields.status"
              render={(record) => <PlayerRewardStatus status={record.status} />}
            />

            <Box
              component={Typography}
              variant="h4"
              sx={{
                margin: '10px 0 !important',
              }}
            >
              {t('resources.check-in.reward-info')}
            </Box>
            <ReferenceField
              reference="reward"
              source="rewardId"
              label="resources.reward.fields.reward-name"
              sortable={false}
              emptyText="-"
              link={false}
            >
              <TextField source="rewardName" />
            </ReferenceField>
            <ReferenceField
              reference="reward"
              source="rewardId"
              label="resources.reward.fields.reward-type"
              sortable={false}
              emptyText="-"
              link={false}
            >
              <SelectField
                label="resources.reward.fields.reward-type"
                source="rewardType"
                choices={Object.values(REWARD_TYPES).map((type) => ({
                  id: type,
                  name: t(`resources.reward.reward-type.${type}`),
                }))}
              />
            </ReferenceField>

            {[REWARD_TYPES.FREE_SPIN, REWARD_TYPES.GOLD_CARD, REWARD_TYPES.RUBY_CARD].includes(data?.rewardType) && (
              <ReferenceField
                reference="game"
                source="freeSpinReward.gameId"
                label="resources.reward.fields.game"
                emptyText="-"
                link={false}
                addLabel
              >
                <TextField source={`name.${locale}`} />
              </ReferenceField>
            )}
            {[REWARD_TYPES.FREE_SPIN, REWARD_TYPES.GOLD_CARD, REWARD_TYPES.RUBY_CARD].includes(data?.rewardType) && (
              <FunctionField
                label="resources.reward.fields.total-spins"
                source="freeSpinReward.issuedQuantity"
                addLabel
                render={(record) => formatNumber('en', record.freeSpinReward.issuedQuantity) ?? '-'}
              />
            )}
            {[REWARD_TYPES.FREE_SPIN, REWARD_TYPES.GOLD_CARD, REWARD_TYPES.RUBY_CARD].includes(data?.rewardType) && (
              <FunctionField
                label="resources.reward.fields.used-spins"
                source="freeSpinReward.usedQuantity"
                addLabel
                render={(record) =>
                  formatNumber('en', record.freeSpinReward.issuedQuantity - record.freeSpinReward.remainingQuantity) ??
                  '-'
                }
              />
            )}
          </Box>
        </Box>
      </RecordContext.Provider>
    </MuiDrawer>
  );
};

const useStyles = makeStyles({
  paper: {
    width: '29rem',
  },
});

export default MissionPlayerRewardsList;
