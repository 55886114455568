/* eslint-disable react/no-array-index-key */
import { Box, Button, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useListContext, useTranslate } from 'react-admin';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useStyles } from '../guesser/wealth-filter.guesser.utils';
import { noop } from '../../../services/util';
import { useFilterFormContext } from '../../context/form/filterForm.context';
import { ExportButton } from '../ra/buttons';

const CustomizedFilterWrapper = ({ children, searchButton, className, showExporter }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { filterValues, setFilters, filterDefaultValues, resource } = useListContext();

  const { setValues } = useFilterFormContext();

  useEffect(
    () =>
      function cleanUp() {
        setValues(filterDefaultValues || {});
        setFilters(filterDefaultValues || {});
      },
    [],
  );

  return (
    <Box className={clsx(classes.filterContainer, className)}>
      <Form
        initialValues={{
          ...filterValues,
        }}
        onSubmit={noop}
        render={({ values: formValues }) => (
          <form className={classes.formStyle}>
            <Grid
              container
              spacing={1}
              display="flex"
              sx={{
                gap: '1rem',
                padding: '0 2px',
              }}
            >
              {children &&
                React.Children.map(children, (child, idx) => (
                  <Grid item key={idx} xs={12} sm="auto">
                    {React.cloneElement(child, {
                      helperText: child?.props?.helperText ?? false,
                      resettable: child?.props?.resettable ?? true,
                    })}
                  </Grid>
                ))}
            </Grid>
            <Grid>
              <Box textAlign="right" marginTop={2}>
                {React.cloneElement(
                  React.isValidElement(searchButton) ? (
                    searchButton
                  ) : (
                    <Button className={classes.btn}>{translate('ra.action.search')}</Button>
                  ),
                  {
                    onClick: () => setFilters(formValues),
                    disabled: JSON.stringify(formValues) === JSON.stringify(filterValues),
                  },
                )}
                {showExporter && <ExportButton className={classes.btn} resource={resource} />}
              </Box>
            </Grid>
          </form>
        )}
      />
    </Box>
  );
};

export default CustomizedFilterWrapper;

CustomizedFilterWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  searchButton: PropTypes.node,
};
