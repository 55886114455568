import React from 'react';
import { useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import FormField from './FormField';
import { BET_TYPES } from '../../../../constant';
import { SelectInput } from '../../ra/inputs';

const BetTypeSelect = ({ source, ...otherProps }) => {
  const translate = useTranslate();

  return (
    <FormField name={source}>
      {() => (
        <SelectInput
          label={translate('resources.report.fields.betType')}
          source={source}
          choices={Object.values(BET_TYPES).map((item) => ({
            id: item,
            name: item,
          }))}
          resettable
          {...otherProps}
        />
      )}
    </FormField>
  );
};

BetTypeSelect.propTypes = {
  source: PropTypes.string,
};

BetTypeSelect.defaultProps = {
  source: 'betType',
};

export default BetTypeSelect;
