import React, { useEffect } from 'react';
import { AutocompleteInput, useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import { useField } from 'react-final-form';
import { TooltipForDisabledInput } from '../../../../../base/components/custom-tooltip';
import { ReferenceInput } from '../../../../../base/components/ra/inputs';
import { useAuthUser, usePrevious } from '../../../../../base/hooks';

const BrandInput = ({ source, tooltipProps, groupId, ...otherProps }) => {
  const user = useAuthUser();
  const {
    input: { value: brand },
  } = useField(source);
  const {
    input: { onChange: onChangeCurrency },
  } = useField('currencyId');
  const prevBrand = usePrevious(brand);

  const translate = useTranslate();

  const group = groupId || user?.group?.id;

  const hasNoGroup = !group;

  useEffect(() => {
    if (brand === prevBrand || !prevBrand) return;
    onChangeCurrency(undefined);
  }, [brand]);

  return (
    <TooltipForDisabledInput
      title={translate('ra.message.pleaseSelectFirst', {
        smart_name: translate('resources.group.name'),
      })}
      placement="top-start"
      showMessage={hasNoGroup}
      {...tooltipProps}
    >
      <ReferenceInput
        label={translate('resources.brand.name')}
        source={source}
        reference="brand"
        filter={{
          'group.id': group,
          enabled: true,
          enableMkt: true,
        }}
        filterToQuery={(value) => ({
          'name||$cont': value,
        })}
        sort={{
          field: 'name',
          order: 'ASC',
        }}
        disabled={hasNoGroup}
        enableGetChoices={() => !hasNoGroup}
        {...otherProps}
      >
        <AutocompleteInput optionText="name" resettable />
      </ReferenceInput>
    </TooltipForDisabledInput>
  );
};

BrandInput.propTypes = {
  source: PropTypes.string,
  tooltipProps: PropTypes.object,
  groupId: PropTypes.string,
};

BrandInput.defaultProps = {
  source: 'brandId',
  tooltipProps: {},
  groupId: undefined,
};

export default BrandInput;
