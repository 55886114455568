import React, { useCallback, useMemo } from 'react';
import keyBy from 'lodash/keyBy';
import { useDispatch, useSelector } from 'react-redux';
import { useDataProvider, useListContext } from 'react-admin';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { sanitizeObject } from '../../../services/util';
import InfinitePagination from '../ra/list/InfinitePagination';
import CustomizedList from './CustomizedList';
import { cacheListByPage, updateCurrentListPage } from '../../../services/redux/list/list.action';
import CustomizedListActionWrapper from './CustomizedListActionWrapper';
import FilterButton from './common-list-action-buttons/FilterButton';

export const CustomizedNoPageList = ({ children, resource, actions, actionsProps, ...props }) => {
  const data = useNoPageListData(resource);

  return (
    <CustomizedList
      disabledAutoRefetch
      hasList
      actions={actionsProps?.overrideDefault ? actions : <DefaultAction {...actionsProps}>{actions}</DefaultAction>}
      exporter={false}
      controllerProps={{
        data: keyBy(data, 'id'),
        ids: data?.map(({ id }) => id),
      }}
      pagination={<DefaultNoPagePagination resource={resource} />}
      syncWithLocation
      rowClick={(id) => `/${resource}/${id}/show`}
      resource={resource}
      hasCreate={false}
      hasShow={false}
      hasEdit={false}
      {...props}
    >
      {children}
    </CustomizedList>
  );
};

CustomizedNoPageList.propTypes = {
  filters: PropTypes.node,
  actions: PropTypes.node,
  syncWithLocation: PropTypes.bool,
  pagination: PropTypes.node,
  resource: PropTypes.string.isRequired,
};

export const useNoPageListData = (resource) => {
  const { list, currentPage } = useSelector((state) => state.list[resource] || {});

  return useMemo(() => list?.[currentPage]?.data || [], [list, currentPage]);
};

export const useGetNoPageListData = (resource) => {
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();
  const { filterValues, currentSort, perPage, setLoading } = useListContext();

  const { list, currentPage } = useSelector((state) => state.list[resource] || {});

  const fetchListData = useCallback(
    async (newPage) => {
      try {
        setLoading(true);
        const nextPagination = list?.[newPage - 1]?.nextPagination;
        const data = await dataProvider.getList(resource, {
          pagination: {
            perPage,
            cachedPage: newPage,
          },
          sort: currentSort,
          filter: {
            ...filterValues,
            q: sanitizeObject({
              nextPagination: JSON.stringify(nextPagination),
            }),
          },
        });

        dispatch(
          cacheListByPage({
            data,
            page: newPage,
            resource,
          }),
        );
      } catch (error) {
        // Do nothing
      } finally {
        setLoading(false);
      }
    },
    [list, currentPage, resource],
  );

  return {
    fetchListData,
    refetchListData: () => fetchListData(currentPage),
  };
};

export const DefaultNoPagePagination = ({ resource }) => {
  const dispatch = useDispatch();
  const { list, currentPage } = useSelector((state) => state.list[resource] || {});

  const { fetchListData } = useGetNoPageListData(resource);

  const disableNext = useMemo(() => !list?.[currentPage]?.nextPagination, [currentPage, list]);

  const handlePageChange = useCallback(
    (newPage) => {
      if (!list[newPage]) {
        fetchListData(newPage);
      }
      dispatch(
        updateCurrentListPage({
          page: newPage,
          resource,
        }),
      );
    },
    [list, currentPage, resource],
  );

  return (
    <InfinitePagination
      disableNext={disableNext}
      disablePrev={currentPage === 1 || !currentPage}
      onNextPage={() => {
        handlePageChange(currentPage + 1);
      }}
      onPrevPage={() => {
        handlePageChange(currentPage - 1);
      }}
      currentPage={currentPage}
    />
  );
};

DefaultNoPagePagination.propTypes = {
  resource: PropTypes.string.isRequired,
};

const DefaultAction = ({ children, ...props }) => (
  <Box component={CustomizedListActionWrapper} {...props}>
    <FilterButton />
    {children}
  </Box>
);
