import React from 'react';
import { FunctionField } from 'react-admin';
import { Box, Link, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

const CustomLinkField = ({ source, href, title, component, ...otherProps }) => {
  const theme = useTheme();
  return (
    <FunctionField
      source={source}
      render={(record) => (
        <Box
          component={(typeof component === 'function' ? component(record) : component) || Link}
          color="primary.lighter"
          href={typeof href === 'function' || href instanceof Function ? href(record) : href}
          sx={{
            '&[class*="Link"]:hover': {
              color: theme.palette.primary.main,
              textDecoration: 'none',
            },
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {typeof title === 'function' || title instanceof Function ? title(record) : title}
        </Box>
      )}
      {...otherProps}
    />
  );
};

CustomLinkField.propTypes = {
  source: PropTypes.string.isRequired,
  href: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  component: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func]),
};

CustomLinkField.defaultProps = {
  component: Link,
};

export default CustomLinkField;
