import { Box } from '@material-ui/core';
import { Image as ImageIcon } from '@material-ui/icons';
import { Avatar, Image } from 'antd';
import React from 'react';
import { getImageUrl } from '../../../services/util/image';

const GameIcon = ({ src }) => (
  <Box width={60} minWidth={60}>
    {src ? (
      <Box
        sx={{
          objectFit: 'contain',
          width: 60,
          height: 60,
          borderRadius: 8,
        }}
        component={Image}
        src={getImageUrl(src)}
      />
    ) : (
      <Avatar shape="square" size={60} icon={<ImageIcon />} />
    )}
  </Box>
);

export default GameIcon;
