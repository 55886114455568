/* eslint-disable import/no-cycle */
import { Box, Button } from '@material-ui/core';
import React, { memo } from 'react';
import { useTranslate, Toolbar } from 'react-admin';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useFormState, useForm } from 'react-final-form';
import { showConfirmDialog } from '../../../../services/redux/app/app.actions';
import resourceSlug from '../../../../constant/resource-slug';
import { useMissionForm } from '../utils';

const PreviousButton = memo(() => {
  const t = useTranslate();
  const { backStep, activeStep, isSaving } = useMissionForm();
  return (
    <Button onClick={backStep} disabled={activeStep === 0 || isSaving} variant="contained" disableElevation>
      {t('ra.action.previous')}
    </Button>
  );
});

const NextButton = memo(() => {
  const t = useTranslate();
  const { activeStep, nextStep } = useMissionForm();
  const form = useForm();
  const { dirty } = useFormState();

  return (
    <Button
      onClick={async () => {
        await form.submit();

        if (form.getState().valid) {
          nextStep();
        }
      }}
      variant="contained"
      disableElevation
      disabled={activeStep === 1 || !dirty}
    >
      {t('ra.action.next')}
    </Button>
  );
});

const CancelButton = memo(() => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { isSaving } = useMissionForm();

  return (
    <Button
      onClick={() => {
        dispatch(
          showConfirmDialog({
            isOpen: true,
            title: 'resources.check-in.cancel-title',
            content: 'resources.check-in.cancel-desc',
            onConfirm: () => history.replace(`/${resourceSlug.MISSION}/${id ? `${id}/show` : ''}`),
          }),
        );
      }}
      variant="outlined"
      disabled={isSaving}
    >
      {t('ra.action.cancel')}
    </Button>
  );
});

const SaveButton = memo(() => {
  const t = useTranslate();
  const { isSaving, onSave, data } = useMissionForm();
  const { id } = useParams();
  const form = useForm();

  return (
    <Button
      disabled={isSaving}
      color="primary"
      variant="contained"
      disableElevation
      icon={<></>}
      onClick={async () => {
        await form.submit();

        if (form.getState().valid) {
          onSave(data.current);
        }
      }}
    >
      {t(id ? 'ra.action.update' : 'ra.action.save')}
    </Button>
  );
});

const MissionFormToolbar = memo(() => {
  const { id } = useParams();
  const { activeStep } = useMissionForm();

  return (
    <Box
      component={Toolbar}
      display="flex"
      justifyContent="space-between"
      mx={-10.5}
      mb={-4}
      sx={{
        px: {
          xs: '24px !important',
          md: '44px !important',
        },
      }}
    >
      <Box display="flex" gap={8}>
        {id ? (
          <>
            <PreviousButton />
            <NextButton />
          </>
        ) : (
          <CancelButton />
        )}
      </Box>
      <Box display="flex" gap={8}>
        {id ? (
          <>
            <CancelButton />
            <SaveButton />
          </>
        ) : (
          <>
            <PreviousButton />
            {activeStep === 0 ? <NextButton /> : <SaveButton />}
          </>
        )}
      </Box>
    </Box>
  );
});

export default MissionFormToolbar;
