/* eslint-disable import/no-dynamic-require, global-require, import/no-cycle */
import * as React from 'react';
import { Route, useParams } from 'react-router-dom';
import { Resource } from 'react-admin';
import { CUSTOM_ROUTES } from '../../constant/customRoutes';
import { RoleBuiltIn } from '../../services/util';
import ResetPassword from '../../theme/@jumbo/components/Common/authComponents/ResetPassword';
import Profile from './profile';
import { QuickSetupNewGroupRoute, QuickSetupNewBrandRoute } from './quick-setup-components';
import { CUSTOM_RESOURCES } from '../../constant/customResources';
import PlayerFreeSpinList from '../mkt-free-spin/components/PlayerFreeSpinList';
import resourceSlug from '../../constant/resource-slug';
import ChangeLogList from '../mkt-free-spin/components/ChangeLogList';
import ChangeLogDetailDrawer from '../mkt-free-spin/components/ChangeLogList/ChangeLogDetailDrawer';
import CheckInPlayerRewardsList from '../check-in/components/CheckInDetails/CheckInPlayerRewards';
import CheckInChangeLog from '../check-in/components/CheckInDetails/CheckInChangeLog';
import RewardList from '../reward/reward.list';
import MissionChangeLog from '../mission/components/MissionDetails/MissionChangeLog';
import MissionPlayerRewardsList from '../mission/components/MissionDetails/MissionPlayerRewardsList';

export const customRoutes = [
  <Route exact path={CUSTOM_ROUTES.PROFILE} component={Profile} />,
  <Route
    exact
    path={CUSTOM_ROUTES.QUICK_SETUP_NEW_GROUP}
    component={QuickSetupNewGroupRoute}
    resource={CUSTOM_RESOURCES.QUICK_SETUP.name}
    accessibleRoleIds={[RoleBuiltIn.SYSTEM_ADMIN]}
  />,
  <Route
    exact
    path={CUSTOM_ROUTES.QUICK_SETUP_NEW_BRAND}
    component={QuickSetupNewBrandRoute}
    resource={CUSTOM_RESOURCES.QUICK_SETUP.name}
    accessibleRoleIds={[RoleBuiltIn.SYSTEM_ADMIN]}
  />,
  <Route
    exact
    path={CUSTOM_ROUTES.CAMPAIGN_PLAYER_FREE_SPINS}
    component={(props) => {
      const { id } = useParams();
      const resource = `${resourceSlug.MKT_FREE_SPINS}/${id}/players`;
      return (
        <>
          <Resource intent="registration" list={PlayerFreeSpinList} name={resource} />
          <PlayerFreeSpinList resource={resource} basePath={`/${resource}`} {...props} />
        </>
      );
    }}
  />,
  <Route
    path={CUSTOM_ROUTES.CAMPAIGN_CHANGES_LOG}
    component={(props) => {
      const { id } = useParams();
      const resource = `${resourceSlug.MKT_FREE_SPINS}/${id}/${resourceSlug.ACTION_LOG}`;

      return (
        <>
          <Resource intent="registration" list={ChangeLogList} show={ChangeLogDetailDrawer} name={resource} />
          <Resource intent="registration" show={ChangeLogDetailDrawer} name="action" />
          <ChangeLogList resource={resource} basePath={`/${resource}`} {...props} />
        </>
      );
    }}
  />,
  <Route
    exact
    path={CUSTOM_ROUTES.CHECK_IN_PLAYER_REWARDS}
    component={(props) => <CheckInPlayerRewardsList {...props} />}
  />,
  <Route exact path={CUSTOM_ROUTES.CHECK_IN_CHANGE_LOG} component={(props) => <CheckInChangeLog {...props} />} />,
  <Route exact path={`/${resourceSlug.REWARD}/:id/details`}>
    {(props) => <RewardList basePath="/" resource={resourceSlug.REWARD} {...props} />}
  </Route>,
  <Route
    exact
    path={CUSTOM_ROUTES.MISSION_PLAYER_REWARDS}
    component={(props) => <MissionPlayerRewardsList {...props} />}
  />,
  <Route exact path={CUSTOM_ROUTES.MISSION_CHANGE_LOG} component={(props) => <MissionChangeLog {...props} />} />,
];

export const publicCustomRoutes = [<Route exact path="/reset-password/:token" component={ResetPassword} noLayout />];
