import { usePermissions } from 'react-admin';
import { useAuthUser } from '../../base/hooks';
import { isUserSA } from '../../constant';

/* checkingPermission = 'read' | 'update' | 'create' | 'list' */
export const hasPermission = (checkingResource, checkingPermission) => {
  const user = useAuthUser();
  const { permissions } = usePermissions();

  if (user?.superman || isUserSA(user)) return true;

  return !!permissions?.find((item) => item.resource.name === checkingResource)?.[checkingPermission];
};

export const isSystemAdmin = () => {
  const user = useAuthUser();
  return isUserSA(user);
};
