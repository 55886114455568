/* eslint-disable react/prop-types */
import React from 'react';
import { FunctionField, ReferenceField, SelectField, TextField, useTranslate } from 'react-admin';
import { Box, IconButton, Tooltip, useTheme } from '@material-ui/core';
import { FilterNoneOutlined, Edit, Cancel } from '@material-ui/icons';
import { DateField } from '../../base/components/ra/fields';
import resourceSlug from '../../constant/resource-slug';
import CustomLinkField from '../../base/components/ra/fields/customLink.field';
import { transformIdValue } from '../../services/util/stringUtil';
import CampaignListAction from './components/CampaignActions';
import CampaignFilterWrapper from './components/CampaignFilters';
import { CAMPAIGN_ISSUERS, CAMPAIGN_STATUS } from '../../constant/campaign';
import CampaignStatus from './components/CampaignStatus';
import { hasPermission } from '../../services/util/auth';
import { useCancelCampaign } from './components/utils';
import { CustomizedNoPageList } from '../../base/components/customized/CustomizedNoPageList';

const CampaignList = () => {
  const t = useTranslate();
  const theme = useTheme();

  const hasCreatePermission = hasPermission(resourceSlug.MKT_FREE_SPINS, 'create');
  const hasUpdatePermission = hasPermission(resourceSlug.MKT_FREE_SPINS, 'update');
  const { cancelCampaign } = useCancelCampaign();

  return (
    <CustomizedNoPageList
      resource={resourceSlug.MKT_FREE_SPINS}
      actions={<CampaignListAction />}
      filters={<CampaignFilterWrapper />}
      disabledAutoRefetch
      basePath="/mkt-free-spin"
      hasCreate={hasCreatePermission}
    >
      <CustomLinkField
        source="id"
        label="common.fields.id"
        href={(record) => `/#/${resourceSlug.MKT_FREE_SPINS}/${record.id}/show`}
        title={(record) => transformIdValue(record.id)}
      />
      <TextField source="name" label="resources.campaign.fields.name" sortable={false} />
      <TextField source="code" label="resources.campaign.fields.code" sortable={false} />
      <DateField source="startTime" label="resources.campaign.fields.start-date" sortable={false} />
      <DateField source="endTime" label="resources.campaign.fields.end-date" sortable={false} />
      <ReferenceField
        source="groupId"
        label="resources.group.name"
        reference={resourceSlug.GROUP}
        link="show"
        sortable={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="brandId"
        label="resources.brand.name"
        reference={resourceSlug.BRAND}
        link="show"
        sortable={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <SelectField
        source="issuer"
        label="resources.campaign.fields.issuer"
        choices={Object.values(CAMPAIGN_ISSUERS).map((issuer) => ({
          id: issuer,
          name: t(`resources.campaign.issuer.${issuer}`),
        }))}
        sortable={false}
      />
      <CampaignStatus label="resources.campaign.fields.status" sortable={false} />
      <FunctionField
        label="ra.field.action"
        sortable={false}
        render={(record) => (
          <Box display="flex" gap={4} justifyContent="center">
            {hasUpdatePermission && record.status === CAMPAIGN_STATUS.ACTIVE && (
              <Tooltip title={t('ra.action.edit')}>
                <IconButton
                  href={`/#/${resourceSlug.MKT_FREE_SPINS}/${record.id}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            )}
            {hasCreatePermission && (
              <Tooltip title={t('ra.action.duplicate')}>
                <IconButton
                  href={`/#/${resourceSlug.MKT_FREE_SPINS}/create?cloneId=${record.id}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  <FilterNoneOutlined />
                </IconButton>
              </Tooltip>
            )}
            {hasUpdatePermission && record.status === CAMPAIGN_STATUS.ACTIVE && (
              <Tooltip title={t('ra.action.cancel')}>
                <Box
                  component={IconButton}
                  sx={{
                    color: `${theme.palette.error.main} !important`,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    cancelCampaign(record.id);
                  }}
                >
                  <Cancel />
                </Box>
              </Tooltip>
            )}
          </Box>
        )}
      />
    </CustomizedNoPageList>
  );
};

export default CampaignList;
