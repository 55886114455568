import React, { useCallback } from 'react';
import { ReferenceArrayInput, useTranslate } from 'react-admin';
import { useField } from 'react-final-form';
import { Box } from '@material-ui/core';
import { AutocompleteArrayInput } from '../../../../../base/components/ra/inputs';
import Switcher from '../../../../../base/components/common/Switcher';
import { useMissionForm } from '../../utils';

const BrandInput = () => {
  const t = useTranslate();
  const {
    input: { value: currencyId },
  } = useField('currencyId');
  const {
    input: { value: groupIds },
  } = useField('group.ids');
  const {
    input: { value: selectAllGroups },
  } = useField('group.isSelectAll');
  const {
    input: { value: isSelectAll },
  } = useField('brand.isSelectAll');
  const { uneditableFields } = useMissionForm();

  const disabled = !currencyId || (!selectAllGroups && !groupIds?.length) || !!uneditableFields?.includes?.('brand');

  const brandValidator = useCallback(
    (brandIds, values) => {
      if (!brandIds?.length && !values.brand?.isSelectAll && !values.group?.isSelectAll && !values.group?.ids?.length) {
        return t('ra.validation.required');
      }
      return undefined;
    },
    [t],
  );

  return (
    <Box>
      <Switcher
        source="brand.isSelectAll"
        label="resources.check-in.fields.select-all-brands"
        defaultValue={false}
        disabled={disabled}
      />
      <ReferenceArrayInput
        key={`brand.ids-${isSelectAll}`}
        variant="outlined"
        label={isSelectAll ? 'resources.check-in.fields.excluded-brands' : 'resources.brand.name'}
        source="brand.ids"
        validate={brandValidator}
        reference="brand"
        filterToQuery={(value) => ({
          'name||$cont': value,
        })}
        filter={{
          enabled: true,
          'group.id||$notin': selectAllGroups ? groupIds : undefined,
          'group.id||$in': selectAllGroups ? undefined : groupIds,
          'currencies.id': currencyId,
        }}
        sort={{
          field: 'name',
          order: 'ASC',
        }}
        disabled={disabled}
        enableGetChoices={() => !disabled}
      >
        <AutocompleteArrayInput optionText="name" resettable enabledSelectAll={false} />
      </ReferenceArrayInput>
    </Box>
  );
};

export default BrandInput;
