import { Box, MenuItem } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { TextField, useRecordContext, useTranslate } from 'react-admin';
import MuiSelect from '../../../../base/components/mui/MuiSelect';
import { AmountField, DateField } from '../../../../base/components/ra/fields';
import { ShowSection } from '../../../../base/components/guesser/wealth-show.guesser';
import Labeled from '../../../../base/components/ra/labeled';

const MainWallet = () => {
  const t = useTranslate();
  const record = useRecordContext();

  const [currencyId, setCurrencyId] = useState('');

  const currencies = useMemo(
    () =>
      record?.wallets
        ?.filter((wallet) => !!wallet?.cash)
        ?.map((wallet) => ({
          id: wallet.currency?.id,
          name: wallet.currency?.name,
        })),
    [record],
  );

  const wallet = useMemo(
    () => record?.wallets?.find((wallet) => wallet.currency?.id === currencyId)?.cash,
    [currencyId, record],
  );

  useEffect(() => {
    const firstBonusWallet = record?.wallets?.find((wallet) => !!wallet?.cash);
    if (firstBonusWallet?.currency?.id) {
      setCurrencyId(firstBonusWallet?.currency?.id);
    }
  }, [record?.wallets]);

  return (
    <Box
      mt={3}
      display="flex"
      flexDirection="column"
      sx={{
        gap: 12,
      }}
    >
      <Box component={MuiSelect} width={200} value={currencyId} onChange={(e) => setCurrencyId(e.target.value)}>
        {currencies?.map((currency) => (
          <MenuItem key={currency.id} value={currency.id}>
            {currency.name}
          </MenuItem>
        ))}
      </Box>
      <Box
        component={ShowSection}
        label={false}
        record={wallet}
        sx={{
          padding: '0 !important',
        }}
      >
        <TextField source="id" label="common.fields.id" />
        <Labeled label={t('common.fields.balance')}>
          <AmountField source="balance" />
        </Labeled>
        <Labeled label={t('common.fields.created')}>
          <DateField source="created" />
        </Labeled>
        <Labeled label={t('common.fields.updated')}>
          <DateField source="updated" />
        </Labeled>
      </Box>
    </Box>
  );
};

MainWallet.propTypes = {};

export default MainWallet;
