export const WALLET_TRANSACTION_STATUS = {
  PROCESSING: 'PROCESSING',
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED',
};

export const WALLET_TRANSFER_TYPE = {
  TRANSFER_IN: 'TRANSFER_IN',
  TRANSFER_OUT: 'TRANSFER_OUT',
  RESET: 'RESET',
};
