import MissionCampaignList from './mission.list';
import MissionCampaignShow from './mission.show';
import MissionCampaignCreate from './mission.create';
import MissionCampaignUpdate from './mission.edit';

export default {
  create: MissionCampaignCreate,
  edit: MissionCampaignUpdate,
  list: MissionCampaignList,
  show: MissionCampaignShow,
};
