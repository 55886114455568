import React, { useCallback } from 'react';
import { FormDataConsumer, useTranslate } from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import { useFormState, useField } from 'react-final-form';
import { IntegerInput, TextInput } from '../../../../../base/components/ra/inputs';
import GroupInput from './GroupInput';
import BrandInput from './BrandInput';
import InputWithTooltipWrapper from '../../../../../base/components/common/InputWithTooltipWrapper';
import Switcher from '../../../../../base/components/common/Switcher';
import ExpirationTimeInput from './ExpirationTimeInput';
import StockInput from './StockInput';
import IssuerInput from './IssuerInput';
import CurrencyInput from './CurrencyInput';
import FreeSpinExpireUnitInput from './FreeSpinExpireUnitInput';
import { CAMPAIGN_ISSUERS } from '../../../../../constant/campaign';
import { isSystemAdmin } from '../../../../../services/util/auth';

const BasicInfoStep = ({ isEditForm, initialValue }) => {
  const t = useTranslate();
  const isSA = isSystemAdmin();
  const { initialValues } = useFormState();
  const {
    input: { value: issuer },
  } = useField('issuer');
  const isNotIssuer = isEditForm && initialValues.issuer === CAMPAIGN_ISSUERS.NG && !isSA;

  const isUneditableField = useCallback(
    (field) => !!(initialValue?.uneditableFields || []).find((uneditableField) => uneditableField === field),
    [initialValue?.uneditableFields],
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      mt={3}
      sx={{
        gap: 8,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          gap: 12,
        }}
      >
        <Typography variant="h3">{t('resources.campaign.basic-info')}</Typography>
        <Box>
          <Box
            width="100%"
            display="grid"
            sx={{
              gap: 24,
            }}
            gridTemplateColumns="repeat(2, 1fr)"
          >
            <TextInput
              label="resources.campaign.fields.name"
              source="campaignName"
              disabled={isUneditableField('name') || isNotIssuer}
              required
              resettable
            />
            <InputWithTooltipWrapper
              source="campaignCode"
              title={t('resources.campaign.fields.code')}
              tooltip={t('resources.campaign.fields.code-tooltip')}
              required
              resettable
              disabled={isEditForm || isNotIssuer || isUneditableField('campaignCode')}
            >
              <TextInput />
            </InputWithTooltipWrapper>
          </Box>
          <Box
            width="100%"
            display="grid"
            sx={{
              gap: 24,
            }}
            gridTemplateColumns="repeat(2, 1fr)"
          >
            <InputWithTooltipWrapper
              source="expDate"
              title={t('resources.campaign.fields.exp-date')}
              tooltip={t('resources.campaign.fields.exp-date-tooltip')}
              required
              isEditForm={isEditForm}
              disabled={isNotIssuer || [isUneditableField('startTime'), isUneditableField('endTime')]}
            >
              <ExpirationTimeInput />
            </InputWithTooltipWrapper>

            <IssuerInput disabled={isUneditableField('issuer') || isEditForm} />
          </Box>
          <Box
            width="100%"
            display="grid"
            sx={{
              gap: 24,
            }}
            gridTemplateColumns="repeat(2, 1fr)"
          >
            <GroupInput source="groupId" disabled={isEditForm || isUneditableField('groupId')} isRequired resettable />

            <FormDataConsumer>
              {({ formData, ...rest }) => (
                <BrandInput
                  groupId={formData.groupId}
                  source="brandId"
                  disabled={isEditForm || isUneditableField('brandId')}
                  isRequired
                  resettable
                  {...rest}
                />
              )}
            </FormDataConsumer>
          </Box>
          <TextInput
            label="resources.campaign.fields.desc"
            source="description"
            fullWidth
            multiline
            resettable
            disabled={isUneditableField('description') || isNotIssuer}
          />
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        sx={{
          gap: 12,
        }}
      >
        <Typography variant="h3">{t('resources.campaign.free-spins-settings')}</Typography>
        <Box>
          <Box
            display="flex"
            sx={{
              gap: 24,
            }}
          >
            <StockInput
              source="stock"
              label="resources.campaign.fields.stock"
              disabled={isNotIssuer || isUneditableField('stock')}
              isEditForm={isEditForm}
            />
            <Box
              width="50%"
              display="flex"
              sx={{
                gap: 8,
              }}
            >
              <InputWithTooltipWrapper
                source="freeSpinExpireIn"
                title={t('resources.campaign.fields.valid-period')}
                tooltip={t('resources.campaign.fields.issued-date-tooltip')}
                required
                disabled={isNotIssuer || isUneditableField('freeSpinExpireIn')}
              >
                <IntegerInput />
              </InputWithTooltipWrapper>
              <Box width={96}>
                <FreeSpinExpireUnitInput disabled={isNotIssuer || isUneditableField('freeSpinExpireUnit')} />
              </Box>
            </Box>
            <CurrencyInput source="currencyId" disabled={isEditForm} />
          </Box>
        </Box>
      </Box>
      {isSA && issuer === CAMPAIGN_ISSUERS.NG && (
        <Box>
          <Typography variant="h4" gutterBottom>
            {t('resources.campaign.fields.operator-update-enable')}
          </Typography>
          <Switcher
            source="operatorUpdateEnable"
            checkedLabel={t('resources.campaign.enabled')}
            uncheckedLabel={t('resources.campaign.disabled')}
          />
        </Box>
      )}
    </Box>
  );
};

export default BasicInfoStep;
