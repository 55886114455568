import React, { useMemo, memo } from 'react';
import { useInput, useLocale, useTranslate } from 'react-admin';
import { Box, Checkbox, Chip, FormHelperText, makeStyles } from '@material-ui/core';
import { MultiSelect } from 'react-multi-select-component';
import { ArrowDropDownSharp, Clear } from '@material-ui/icons';
import clsx from 'clsx';
import Fieldset from '../../../../../../../base/components/common/Fieldset';

const GameListInput = ({ source = 'gameIds', games }) => {
  const t = useTranslate();
  const classes = useStyles();
  const locale = useLocale();

  const {
    meta: { error, submitError, touched, dirtySinceLastSubmit },
    input: { onChange, value },
  } = useInput({
    source,
    validate: (value) => {
      if (!value?.length) {
        return t('ra.validation.minLengthArray', {
          smart_count: 1,
        });
      }
      return undefined;
    },
  });

  const allGames = useMemo(
    () =>
      Object.values(games)?.map((game) => ({
        value: game.id,
        label: game.name[locale],
      })),
    [locale, games],
  );

  const showError = ((submitError && !dirtySinceLastSubmit) || error) && touched;
  const title = t('resources.reward.fields.game-list');

  return (
    <Fieldset title={value?.length ? `${title} *` : ''}>
      <MultiSelect
        hasSelectAll
        options={allGames}
        value={
          Array.isArray(value)
            ? value.map((game) =>
                typeof game === 'string'
                  ? {
                      label: games?.[game]?.name?.[locale],
                      value: game,
                    }
                  : game,
              )
            : []
        }
        onChange={(values) => {
          onChange(values);
        }}
        overrideStrings={{
          noOptions: t('common.text.noOptions'),
          search: t('ra.text.search'),
          selectAll: t('ra.text.selectAll'),
          selectAllFiltered: t('ra.text.selectAllFiltered'),
        }}
        isCreatable={false}
        isLoading={!games}
        className={clsx(classes.select, showError && classes.error)}
        valueRenderer={(selected) =>
          selected?.length
            ? allGames.length === selected.length
              ? t('common.text.allGames')
              : selected.map((selectedGame) => (
                  <Chip
                    size="small"
                    key={selectedGame?.value}
                    onDelete={() => onChange(selected.filter((game) => game?.value !== selectedGame?.value))}
                    label={selectedGame?.label}
                    className={classes.chip}
                  />
                ))
            : `${title} *`
        }
        ClearIcon={<ClearIcon />}
        debounceDuration={0}
        ArrowRenderer={({ expanded }) => (
          <Box
            component={ArrowDropDownSharp}
            sx={{
              color: 'rgba(0, 0, 0, 0.6)',
              transform: expanded ? 'rotate(180deg)' : 'rotate(0)',
            }}
          />
        )}
        ClearSelectedIcon={<ClearIcon />}
        ItemRenderer={({ checked, option, onClick }) => (
          <>
            <Checkbox size="small" checked={checked} onChange={onClick} tabIndex={-1} />
            {option?.label}
          </>
        )}
      />
      <Box
        component={FormHelperText}
        sx={{
          visibility: showError ? 'visible' : 'hidden',
          margin: '3px 14px 0 !important',
        }}
        error={showError}
      >
        {error || submitError || '&ZeroWidthSpace;'}
      </Box>
    </Fieldset>
  );
};

const ClearIcon = () => <Box component={Clear} width={16} color="gray" />;

const useStyles = makeStyles((theme) => ({
  select: {
    "& *[class='dropdown-heading']": {
      height: 'fit-content',
      paddingTop: 8,
      paddingBottom: 8,
      overflow: 'hidden',
      "& *[class='dropdown-heading-value']": {
        maxHeight: 104,
        overflowY: 'auto',
      },
    },
    "& *[class='dropdown-heading-value']>span": {
      display: 'flex',
      gap: 4,
      flexWrap: 'wrap',
      fontSize: '1rem',
      "&[class*='gray']": {
        color: 'rgba(0, 0, 0, 0.6)',
      },
    },
    "& *[class='dropdown-content']": {
      zIndex: 200,
    },
    "& *[class='dropdown-container']:focus-within": {
      boxShadow: 'none',
      borderColor: theme.palette.primary.dark,
    },
    "& *[class='select-item']": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  error: {
    '&, & *': {
      color: theme.palette.error.main,
      '&[class="dropdown-heading-value"]>span[class*="gray"]': {
        color: theme.palette.error.main,
      },
    },
    "& *[class='dropdown-container']": {
      borderColor: theme.palette.error.main,
    },
  },
  chip: {
    borderRadius: 4,
  },
  checked: {
    backgroundColor: theme.palette.grey[200],
  },
}));

export default memo(GameListInput);
