export const CAMPAIGN_TYPES = {
  DAILY_CHECK_IN: 'DAILY_CHECK_IN',
  MARKETING_FREE_SPIN: 'MARKETING_FREE_SPIN',
  DAILY_MISSION: 'DAILY_MISSION',
};

export const CAMPAIGN_STATUS = {
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
  CANCELED: 'CANCELED',
};

export const CAMPAIGN_ISSUERS = {
  OPERATOR: 'OPERATOR',
  NG: 'NG',
};

export const CAMPAIGN_UNIT_EXPIRE_IN = {
  HOURS: 'HOURS',
  DAYS: 'DAYS',
};

export const CAMPAIGN_PLAYER_STATUS = {
  EXPIRED: 'EXPIRED',
  USED: 'USED',
  ISSUED: 'ISSUED',
  PENDING: 'PENDING',
  CANCELED: 'CANCELED',
};

export const ACTION_TYPE = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  ADD_PLAYER: 'ADD PLAYER',
  REMOVE_PLAYER: 'REMOVE PLAYER',
};

export const ACTION_MODULE = {
  MAKERTING_CAMPAIGN: 'Marketing Campaigns',
  PLAYER_FREE_SPINS: 'Player Free Spins',
};

export const PLAYER_BATCH_STATUS = {
  CANCELED: 'CANCELED',
  PROCESSED: 'PROCESSED',
  SCHEDULED: 'SCHEDULED',
};

export const PLAYER_BATCH_SOURCE_TYPE = {
  OPEN_API: 'OPEN_API',
  BACK_OFFICE: 'BACK_OFFICE',
};

export const MAXIMUM_ADDED_PLAYERS = 10000;

export const MAX_NATIVE_ID_LENGTH = 255;

export const MAX_VALID_PERIOD_IN_MONTHS = 3;

export const DEFAULT_MAX_TOTAL_FREE_SPINS_WHEN_INPUT = 4294967295;

export const WALLET_TYPES = {
  BONUS_WALLET: 'BONUS',
  NORMAL_WALLET: 'NORMAL',
};

export const PLAYER_REWARD_STATUS = {
  ISSUED: 'ISSUED',
  EXPIRED: 'EXPIRED',
  USED: 'USED',
  COMPLETED: 'COMPLETED',
};

export const CHECK_IN_TYPES = {
  CUMULATIVE: 'CUMULATIVE',
  CONSECUTIVE: 'CONSECUTIVE',
};

export const MISSION_LEVEL_TYPES = {
  PROGRESSIVE: 'PROGRESSIVE',
  ALL_AT_ONCE: 'ALL_AT_ONCE',
};

export const MISSION_TYPES = {
  SPIN: 'SPIN',
  TURNOVER: 'TURNOVER',
};
