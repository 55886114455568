/* eslint-disable import/no-cycle */
import React from 'react';
import { required } from 'react-admin';
import ctzc from 'country-tz-currency';
import { useField } from 'react-final-form';
import { useSelector } from 'react-redux';
import { AutocompleteInput } from '../../../../../base/components/ra/inputs';
import { getTimezoneByCurrencyCode, useCheckInForm } from '../../utils';

const TimezoneChoices = Object.values(ctzc.getAllTimezones()).map((timezone) => ({
  id: timezone.id,
  name: `${timezone.id} (GMT${timezone.GMT})`,
}));

const TimezoneInput = () => {
  const {
    input: { value: currencyId },
  } = useField('currencyId');
  const { code: currencyCode } = useSelector(({ admin }) => admin.resources.currency.data[currencyId] || {});
  const { uneditableFields } = useCheckInForm();

  return (
    <AutocompleteInput
      source="timezone"
      label="resources.check-in.fields.timezone"
      choices={TimezoneChoices}
      isRequired
      fullWidth
      resettable
      validate={required()}
      disabled={uneditableFields?.includes?.('timezone')}
      defaultValue={getTimezoneByCurrencyCode(currencyCode)}
      suggestionLimit={50}
      matchSuggestion={(filter, choice) => choice?.name?.toLowerCase().includes(filter?.toLowerCase())}
    />
  );
};

export default TimezoneInput;
