import React, { useMemo } from 'react';
import { AutocompleteInput, ReferenceInput, SelectInput, useLocale, useTranslate } from 'react-admin';
import moment from 'moment';
import { useForm } from 'react-final-form';
import { BET_TYPES, FILTER_PREFIX_ON_VALUE, SERVER_DATETIME_FORMAT } from '../../../constant';
import CustomizedFilterWrapper from '../../../base/components/customized/CustomizedFilterWrapper';
import CreatedDateRangeInput from './created-date-range.filter';
import resourceSlug from '../../../constant/resource-slug';
import GroupSelector from '../../../base/components/customized/common-input/GroupSelector';
import BrandSelector from '../../../base/components/customized/common-input/BrandSelector';
import { useAuthUser, useEffectAfterMount } from '../../../base/hooks';
import { TextInput } from '../../../base/components/ra/inputs';

const BRAND_KEY = 'brand';
const GROUP_KEY = 'group';
const GAME_KEY = 'game';

const LargeWinFilterWrapper = () => {
  const translate = useTranslate();

  const createdDefaultValues = useMemo(() => {
    const today = moment().add(1, 'days').startOf('day').format(SERVER_DATETIME_FORMAT);
    const yesterday = moment().subtract(1, 'days').startOf('day').format(SERVER_DATETIME_FORMAT);

    return `${FILTER_PREFIX_ON_VALUE.BETWEEN}${yesterday},${today}`;
  }, []);

  const betTypeChoices = useMemo(
    () => [
      {
        id: null,
        name: translate('ra.text.all'),
      },
      ...Object.values(BET_TYPES).map((item) => ({
        id: item,
        name: item,
      })),
    ],
    [],
  );

  return (
    <CustomizedFilterWrapper>
      <CreatedDateRangeInput
        source="created"
        label={translate('resources.bet.fields.created')}
        defaultValue={createdDefaultValues}
        resource={resourceSlug.LARGE_WIN}
      />
      <GameFilter />
      <PlayerIDFilter />
      <GroupFilter />
      <BrandFilter />
      <SelectInput
        label="resources.report.fields.betType"
        source="betType"
        choices={betTypeChoices}
        variant="outlined"
        options={{
          SelectProps: {
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            },
          },
        }}
      />
    </CustomizedFilterWrapper>
  );
};

const GroupFilter = () => {
  const user = useAuthUser();
  const userGroupId = user.group?.id;

  return <GroupSelector source={GROUP_KEY} defaultValue={userGroupId} disabled={Boolean(userGroupId)} />;
};

const BrandFilter = () => {
  const user = useAuthUser();
  const form = useForm();
  const formValues = form.getState().values;
  const currentSelectedGroupId = formValues[GROUP_KEY];
  const groupId = currentSelectedGroupId;
  const userBrandId = user?.brands?.[0]?.id;

  useEffectAfterMount(() => {
    if (!formValues[BRAND_KEY] || !currentSelectedGroupId) return;
    form.change(BRAND_KEY, undefined);
  }, [currentSelectedGroupId]);

  return (
    <BrandSelector
      source={BRAND_KEY}
      defaultValue={userBrandId}
      disabled={Boolean(userBrandId)}
      filter={{
        'group.id': groupId,
      }}
    />
  );
};

const PlayerIDFilter = () => <TextInput label="resources.report.fields.playerId" source="playerId||$eq" resettable />;

const GameFilter = () => {
  const locale = useLocale();
  const user = useAuthUser();
  const form = useForm();
  const formValues = form.getState().values;
  const currentSelectedBrandId = formValues[BRAND_KEY];

  const brandId = user?.brands?.[0]?.id || currentSelectedBrandId;

  useEffectAfterMount(() => {
    if (!formValues[GAME_KEY] || !currentSelectedBrandId) return;
    form.change(GAME_KEY, undefined);
  }, [currentSelectedBrandId]);

  return (
    <ReferenceInput
      helperText={false}
      label="resources.game.name"
      source={GAME_KEY}
      variant="outlined"
      reference={resourceSlug.GAME}
      filter={{
        brandId,
      }}
      filterToQuery={(value) => ({
        [`name->>'$.${locale}'||$cont`]: value,
      })}
      sort={{
        field: 'created',
        order: 'DESC',
      }}
    >
      <AutocompleteInput optionText={`name.${locale}`} resettable />
    </ReferenceInput>
  );
};

export default LargeWinFilterWrapper;
