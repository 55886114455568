/* eslint-disable import/no-cycle */
import React from 'react';
import { maxValue, minValue, RadioButtonGroupInput, required, useTranslate } from 'react-admin';
import { Box } from '@material-ui/core';
import { useField } from 'react-final-form';
import { CHECK_IN_TYPES } from '../../../../../../constant/campaign';
import TimeFrameInput from './TimeFrameInput';
import { useCheckInCampaignDuration, useCheckInForm } from '../../../utils';
import { IntegerInput } from '../../../../../../base/components/ra/inputs';
import InputWithTooltipWrapper from '../../../../../../base/components/common/InputWithTooltipWrapper';
import Switcher from '../../../../../../base/components/common/Switcher';

const Conditions = () => {
  const t = useTranslate();
  const {
    input: { value: type },
  } = useField('type');
  const campaignDuration = useCheckInCampaignDuration();
  const { uneditableFields, id } = useCheckInForm();

  return (
    <>
      <Box
        component={RadioButtonGroupInput}
        source="type"
        choices={Object.values(CHECK_IN_TYPES).map((type) => ({
          id: type,
          name: t(`resources.check-in.type.${type}`),
        }))}
        defaultValue={CHECK_IN_TYPES.CUMULATIVE}
        validate={required()}
        label={false}
        helperText={false}
        mt={0}
        mb={0}
        disabled={!!uneditableFields?.includes?.('missionType') || !!id}
      />
      {type === CHECK_IN_TYPES.CONSECUTIVE && (
        <>
          <IntegerInput
            source="requiredCheckInDays"
            resettable
            label="resources.check-in.fields.max-consecutive-days"
            validate={[
              required(),
              minValue(
                1,
                t('ra.validation.greater', {
                  min: 0,
                }),
              ),
              maxValue(
                campaignDuration,
                t('resources.check-in.validation.max-consecutive-days', {
                  day: campaignDuration,
                }),
              ),
            ]}
            disabled={!!uneditableFields?.includes?.('requiredCheckInDays')}
          />
          <InputWithTooltipWrapper
            source="streakRepeatable"
            title={t('resources.check-in.fields.repeatable-streak')}
            tooltip={t('resources.check-in.fields.repeatable-streak-tooltip')}
            disabled={!!uneditableFields?.includes?.('streakRepeatable') || !!id}
          >
            <Box
              component={Switcher}
              defaultValue={false}
              width="fit-content"
              sx={{
                '& label': {
                  flexDirection: 'row-reverse',
                  gap: 18,
                },
              }}
            />
          </InputWithTooltipWrapper>
        </>
      )}
      <TimeFrameInput />
    </>
  );
};

export default Conditions;
