/* eslint-disable no-use-before-define, no-shadow, react/require-default-props */
import React, { memo, useCallback, useMemo } from 'react';
import { Box, makeStyles, Tooltip } from '@material-ui/core';
import { useInput, useLocale, useTranslate, SelectInput } from 'react-admin';
import * as moment from 'moment';
import { range as getRange } from 'lodash';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import localeEn from 'antd/es/date-picker/locale/en_US';
import localeZhCn from 'antd/es/date-picker/locale/zh_CN';
import localeTh from 'antd/es/date-picker/locale/th_TH';
import AntdDatePicker from '../../antd/AntdDatePicker';

import {
  SERVER_DATETIME_FORMAT,
  DATETIME_DISPLAY_FORMAT_WITH_SECONDS,
  FILTER_PREFIX_ON_VALUE,
  DATE_DISPLAY_FORMAT,
} from '../../../../constant';
import { clearDateRangePrefix, getBackofficeEnv } from '../../../../services/util';

import RangePickerReport from './RangePickerReport';

const { RangePicker } = AntdDatePicker;

const dateRangePrefix = FILTER_PREFIX_ON_VALUE.BETWEEN;

const getAntDesignLocale = (locale) => {
  switch (locale) {
    case 'cn':
      return localeZhCn;
    case 'th':
      return localeTh;
    case 'en':
    default:
      return localeEn;
  }
};

const DateRangeInput = (props) => {
  const {
    entity,
    source,
    resource,
    label,
    // eslint-disable-next-line
    isFilter,
    dateRange: initDateRange,
    prefix,
    disabled = false,
    clearable,
    reportServiceVersion,
    disabledDate,
    hasRangeOption = true,
    defaultRangeOption = 'date',
    serverFormat = SERVER_DATETIME_FORMAT,
    ...rest
  } = props;
  const { REPORT_BET_QUERY_SUPPORT_DAYS } = getBackofficeEnv();
  const isReportPage = entity === 'report' || ['wager'].includes(resource);

  const {
    input: { onChange: onRangeTypeChange, value: range },
  } = useInput({
    source: `${source}Option||$ignores`,
    defaultValue: defaultRangeOption,
  });

  const parseValue = useCallback(
    (value) => {
      if (Array.isArray(value) && value[0] && value[1]) {
        let dateRangesStr = [
          moment(value[0]).startOf('second').format(serverFormat),
          moment(value[1]).startOf('second').format(serverFormat),
        ];

        if (range === 'date') {
          dateRangesStr = [
            moment(value[0]).startOf('day').format(serverFormat),
            moment(value[1]).add(1, 'days').startOf('day').format(serverFormat),
          ];
        }

        return prefix
          ? `${dateRangePrefix}${dateRangesStr[0]},${dateRangesStr[1]}`
          : `${dateRangesStr[0]},${dateRangesStr[1]}`;
      }

      return undefined;
    },
    [prefix, range],
  );

  const { input } = useInput({
    defaultValue: parseValue(initDateRange),
    format: (value) => {
      if (typeof value === 'string') {
        const [startDateString, endDateString] = clearDateRangePrefix(value)?.split(',') || ['', ''];

        if (range === 'date') {
          return [
            startDateString ? moment(startDateString) : undefined,
            endDateString ? moment(endDateString).subtract(1, 'days') : undefined,
          ];
        }
        return [
          startDateString ? moment(startDateString) : undefined,
          endDateString ? moment(endDateString) : undefined,
        ];
      }

      return [value?.[0] ? moment(value[0]) : undefined, value?.[1] ? moment(value[1]) : undefined];
    },
    parse: parseValue,
    ...props,
  });

  const translate = useTranslate();
  const format = range === 'date' ? DATE_DISPLAY_FORMAT : DATETIME_DISPLAY_FORMAT_WITH_SECONDS;
  const locale = useLocale();
  const antDesignLocale = getAntDesignLocale(locale);

  const ranges =
    range === 'date'
      ? {
          [translate('ra.text.today')]: [moment().startOf('day'), moment().startOf('day')],
          [translate('ra.text.yesterday')]: [
            moment().subtract(1, 'days').startOf('day'),
            moment().subtract(1, 'days').startOf('day'),
          ],
          [translate('ra.text.lastNDays', {
            smart_number: 7,
          })]: [moment().subtract(7, 'days').startOf('day'), moment().subtract(1, 'days').startOf('day')],
          [translate('ra.text.lastMonth')]: [
            moment().subtract(1, 'month').startOf('month').startOf('day'),
            moment().startOf('month').subtract(1, 'days'),
          ],
          [translate('ra.text.monthTillDate')]: [moment().startOf('month').startOf('day'), moment().startOf('day')],
        }
      : {
          [translate('ra.text.today')]: [moment().startOf('day'), moment().add(1, 'days').startOf('day')],
          [translate('ra.text.yesterday')]: [moment().subtract(1, 'days').startOf('day'), moment().startOf('day')],
          [translate('ra.text.lastNDays', {
            smart_number: 7,
          })]: [moment().subtract(7, 'days').startOf('day'), moment().startOf('day')],
          [translate('ra.text.lastMonth')]: [
            moment().subtract(1, 'month').startOf('month').startOf('day'),
            moment().startOf('month'),
          ],
          [translate('ra.text.monthTillDate')]: [
            moment().startOf('month').startOf('day'),
            moment().add(1, 'minutes').startOf('minute'),
          ],
        };

  const disabledTimesInReports = (selected) => {
    const isReportPageWithoutWager = entity === 'report';
    const selectedDate = moment(selected);

    if (isReportPageWithoutWager && moment().diff(selectedDate, 'days') >= REPORT_BET_QUERY_SUPPORT_DAYS) {
      return {
        disabledHours: () => [],
        disabledMinutes: () => getRange(0, 60),
        disabledSeconds: () => getRange(0, 60),
      };
    }
    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
      disabledSeconds: () => [],
    };
  };

  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '40px',
        border: '1px solid #0000003B',
        borderRadius: '4px',
        background: '#FFFFFF',
        marginTop: '8px',
        position: 'relative',
      }}
    >
      {/* Background of Label */}
      <Box
        component="label"
        style={{
          position: 'absolute',
          top: -4,
          left: 0,
          padding: '0 6px',
          maxWidth: '1000px',
          background: '#FFFFFF',
          color: '#FFFFFF',
          fontSize: '1rem',
          fontFamily: 'Normal',
          fontWeight: 400,
          lineHeight: 1,
          transform: 'scale(0.75)',
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
        }}
      >
        {label}
        <InfoIcon
          style={{
            width: '18px !important',
          }}
        />
      </Box>

      {/* Value of Label */}
      <Box
        component="label"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          padding: '0 6px',
          color: 'rgba(0, 0, 0, 0.6)',
          fontSize: '1rem',
          fontFamily: 'Normal',
          fontWeight: 400,
          lineHeight: 1,
          transform: 'translateY(-50%) scale(0.75)',
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
          zIndex: 2,
        }}
      >
        {label}
        <Tooltip
          placement="top-end"
          title={
            <Box
              sx={{
                whiteSpace: 'pre-line',
              }}
            >
              {translate('common.tooltip.report-time-range-filters')}
            </Box>
          }
        >
          <Box
            component={InfoIcon}
            sx={{
              width: '18px !important',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          />
        </Tooltip>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
        }}
      >
        {hasRangeOption && (
          <DateRangeOption
            source={`${source}Option||$ignores`}
            onChange={(range) => {
              onRangeTypeChange(range);
            }}
          />
        )}
        {isReportPage ? (
          <RangePickerReport
            value={input.value}
            resource={resource}
            allowClear={false}
            disabled={disabled}
            bordered={false}
            format={format}
            locale={antDesignLocale}
            onChange={input.onChange}
            onOk={input.onChange}
            translate={translate}
            reportServiceVersion={reportServiceVersion}
            disabledDate={disabledDate}
            disabledTime={disabledTimesInReports}
            source={source}
            showTime={range === 'date' ? false : null}
            ranges={ranges}
            {...rest}
          />
        ) : (
          <RangePicker
            style={{
              maxWidth: '350px',
            }}
            allowClear={clearable}
            ranges={ranges}
            value={input.value}
            disabled={disabled}
            bordered={false}
            format={format}
            locale={antDesignLocale}
            onChange={input.onChange}
            onOk={input.onChange}
            disabledDate={disabledDate}
            showTime={
              range === 'date'
                ? false
                : {
                    defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('00:00', 'mm:ss')],
                  }
            }
            {...rest}
          />
        )}
      </Box>
    </Box>
  );
};

DateRangeInput.propTypes = {
  source: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isFilter: PropTypes.bool,
  dateRange: PropTypes.array,
  prefix: PropTypes.bool,
  disabled: PropTypes.bool,
  clearable: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.bool), PropTypes.bool]),
  entity: PropTypes.oneOf(['report', undefined]),
  reportServiceVersion: PropTypes.number,
  disabledDate: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  hasRangeOption: PropTypes.bool,
  defaultRangeOption: PropTypes.oneOf(['date-time', 'date']),
  serverFormat: PropTypes.string,
};

DateRangeInput.defaultProps = {
  isFilter: false,
  dateRange: null,
  prefix: true,
  disabled: false,
  clearable: true,
  entity: undefined,
  reportServiceVersion: 0,
  disabledDate: undefined,
  serverFormat: SERVER_DATETIME_FORMAT,
  defaultRangeOption: 'date',
};

const DateRangeOption = ({ source, onChange }) => {
  const translate = useTranslate();
  const classes = useStyles();

  const options = useMemo(
    () => [
      {
        id: 'date',
        name: translate('common.text.date'),
      },
      {
        id: 'date-time',
        name: translate('common.text.dateTime'),
      },
    ],
    [translate],
  );

  return (
    <SelectInput
      source={source}
      label={false}
      classes={{
        input: classes.input,
      }}
      SelectProps={{
        classes: {
          root: classes.selectRoot,
        },
      }}
      FormHelperTextProps={{
        className: classes.helperText,
      }}
      onChange={(e) => onChange?.(e.target.value)}
      choices={options}
    />
  );
};

const useStyles = makeStyles({
  input: {
    minWidth: 120,
    width: 120,
    margin: 0,
    '&>*:first-child': {
      backgroundColor: 'transparent',
    },
    '&>*:first-child:before,&>*:first-child:after': {
      border: 'none',
    },
  },
  selectRoot: {
    padding: 10,
    paddingRight: 16,
    background: 'rgba(0,0,0,0.05)',
    fontSize: 13,
    fontVariant: 'tabular-nums',
    color: 'rgba(0, 0, 0, 0.85)',
  },
  helperText: {
    height: 0,
    margin: 0,
  },
});

DateRangeOption.propTypes = {
  source: PropTypes.string.isRequired,
};

export default memo(DateRangeInput);
