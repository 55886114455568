import React, { memo } from 'react';
import { Box } from '@material-ui/core';
import { useInput, useLocale, useTranslate } from 'react-admin';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import { alpha, useTheme } from '@material-ui/core/styles';

import localeEn from 'antd/es/date-picker/locale/en_US';
import localeZhCn from 'antd/es/date-picker/locale/zh_CN';
import localeTh from 'antd/es/date-picker/locale/th_TH';
import AntdDatePicker from '../../antd/AntdDatePicker';

const { RangePicker } = AntdDatePicker;

const getAntDesignLocale = (locale) => {
  switch (locale) {
    case 'cn':
      return localeZhCn;
    case 'th':
      return localeTh;
    case 'en':
    default:
      return localeEn;
  }
};

const rootStyles = {
  marginTop: '8px',
  marginBottom: '4px',
};

const inputContainerStyles = ({ error, muiTheme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '40px',
  border: `1px solid ${error ? muiTheme.palette.error.main : '#0000003B'}`,
  borderRadius: '4px',
  background: '#FFFFFF',
  position: 'relative',
});

const labelStyles = {
  position: 'absolute',
  top: -8,
  left: -12,
  padding: '0 6px',
  maxWidth: '1000px',
  background: '#FFFFFF',
  color: alpha('#000000', 0.6),
  fontSize: '16px',
  fontFamily: 'Normal',
  fontWeight: 400,
  lineHeight: 1,
  transform: 'scale(0.75)',
  display: 'flex',
  alignItems: 'center',
  zIndex: 2,
};

const DateRangePicker = (props) => {
  const { label, errorMessage, rangeProps, ...otherProps } = props;
  const translate = useTranslate();

  const locale = useLocale();
  const antDesignLocale = getAntDesignLocale(locale);

  const {
    isRequired,
    input,
    meta: { error, submitError, touched },
  } = useInput(otherProps);
  const muiTheme = useTheme();
  const errorText = touched ? error || submitError || errorMessage : '';

  return (
    <Box style={rootStyles} className="date-picker-input">
      <Box
        style={inputContainerStyles({
          error: errorText,
          muiTheme,
        })}
      >
        <Box component="label" style={labelStyles}>
          <Box color={errorText && muiTheme.palette.error.main}>{label}</Box>
          {isRequired ? ' *' : ''}
        </Box>
        <RangePicker
          showTime
          allowClear={false}
          bordered={false}
          suffixIcon={null}
          value={[
            input.value?.[0] ? moment(input.value?.[0]) : undefined,
            input.value?.[1] ? moment(input.value?.[1]) : undefined,
          ]}
          onChange={input.onChange}
          locale={antDesignLocale}
          {...rangeProps}
        />
      </Box>
      <Box
        style={{
          margin: '4px 14px 0px',
          color: muiTheme.palette.error.main,
          minHeight: '20px',
          fontSize: '0.75rem',
        }}
      >
        {errorText ? translate(errorText) : ''}
      </Box>
    </Box>
  );
};

DateRangePicker.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  source: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
};

DateRangePicker.defaultProps = {
  errorMessage: undefined,
};

export default memo(DateRangePicker);
