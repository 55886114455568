import React, { memo } from 'react';
import { minValue, required, SimpleForm, useTranslate, RadioButtonGroupInput } from 'react-admin';
import moment from 'moment';
import { makeStyles, Box } from '@material-ui/core';
import MuiDialog from '../../../../../../../base/components/mui/MuiDialog';
import DrawerToolBar from '../../../../../../../base/components/ra/drawers/DrawerToolBar';
import { useMissionCalender } from '../MissionCalenderContext';
import DatePickerInput from '../../../../../../../base/components/ra/inputs/datePicker.input';
import { useMissionForm } from '../../../../utils';

const MissionCloneDialog = memo(({ open, onClose }) => {
  const t = useTranslate();
  const classes = useStyles();

  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      title={t('resources.mission.copy-to-other-days')}
      classes={{
        paper: classes.paper,
      }}
    >
      {open && <MissionCloneForm />}
    </MuiDialog>
  );
});

export default MissionCloneDialog;

const MissionCloneForm = memo(() => {
  const classes = useStyles();
  const { missionList, missionDate, onChange, clearAction } = useMissionCalender();

  const handleCopy = ({ clonedDate, resolveConflictMethod }) => {
    const newMissionList = {
      ...missionList,
    };

    clonedDate.forEach((date) => {
      const isoDate = moment(date).startOf('day').toISOString();
      const currentData = newMissionList[isoDate];
      const clonedData = JSON.parse(JSON.stringify(newMissionList[missionDate]));

      if (currentData && resolveConflictMethod === RESOLVE_CONFLICT_METHODS.SKIP) {
        newMissionList[isoDate].missionLevelType = currentData.missionLevelType ?? clonedData.missionLevelType;
        newMissionList[isoDate].missionTasks = currentData.missionTasks?.length
          ? currentData.missionTasks
          : clonedData.missionTasks;
      } else {
        newMissionList[isoDate] = clonedData;
      }
    });

    onChange(newMissionList);
    clearAction();
  };

  return (
    <SimpleForm
      toolbar={<DrawerToolBar />}
      save={handleCopy}
      initialValues={{
        fromDate: moment(missionDate),
        resolveConflictMethod: RESOLVE_CONFLICT_METHODS.SKIP,
        clonedDate: undefined,
      }}
      className={classes.card}
    >
      <DatePickerInput
        disabled
        picker="date"
        showToday={false}
        showNow={false}
        source="fromDate"
        label="resources.mission.fields.from-date"
        helperText={false}
      />
      <ClonedDatePicker />
      <ResolveConflictMethods />
    </SimpleForm>
  );
});

const ClonedDatePicker = memo((props) => {
  const t = useTranslate();
  const classes = useStyles();
  const { data } = useMissionForm();
  const { missionDate } = useMissionCalender();
  const { startTime, endTime } = data.current;

  const disabledDate = (current) =>
    current &&
    (moment(current).isBefore(moment(startTime), 'date') ||
      moment(current).isAfter(moment(endTime), 'date') ||
      moment(current).isSameOrBefore(moment(), 'date') ||
      moment(current).isSame(moment(missionDate), 'date'));

  return (
    <DatePickerInput
      source="clonedDate"
      picker="date"
      showToday={false}
      showNow={false}
      showTime={false}
      multiple
      maxTagCount="responsive"
      label="resources.mission.fields.destination-dates"
      isRequired
      validate={[
        required(t('ra.validation.required')),
        minValue(
          1,
          t('ra.validation.greater', {
            min: 0,
          }),
        ),
      ]}
      disabledDate={disabledDate}
      popupClassName={classes.datePickerPopup}
      {...props}
    />
  );
});

const ResolveConflictMethods = memo((props) => {
  const t = useTranslate();
  const classes = useStyles();

  return (
    <Box className={classes.radioGroup}>
      <RadioButtonGroupInput
        source="resolveConflictMethod"
        label="resources.mission.fields.resolve-conflict-method"
        choices={Object.values(RESOLVE_CONFLICT_METHODS).map((method) => ({
          id: method,
          name: t(`resources.mission.resolve-conflict-method.${method}`),
        }))}
        helperText={false}
        {...props}
      />
    </Box>
  );
});

const RESOLVE_CONFLICT_METHODS = {
  SKIP: 'SKIP',
  OVERRIDE: 'OVERRIDE',
};

const useStyles = makeStyles((theme) => ({
  datePickerPopup: {
    zIndex: 1900,
  },
  card: {
    "& [class*='Card']": {
      padding: 0,
    },
  },
  radioGroup: {
    '&>*': {
      marginTop: 0,
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
  },
  paper: {
    minWidth: 500,
    width: 500,
  },
}));
