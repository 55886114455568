import React, { memo, useEffect } from 'react';
import { required, SimpleForm, useTranslate } from 'react-admin';
import { useForm, useField } from 'react-final-form';
import { Box } from '@material-ui/core';
import pick from 'lodash/pick';
import moment from 'moment';
import MissionFormToolbar from '../MissionFormToolbar';
import { TextInput } from '../../../../../base/components/ra/inputs';
import { useMissionForm, useMissionValidate } from '../../utils';
import TimezoneInput from './TimezoneInput';
import GroupInput from './GroupInput';
import BrandInput from './BrandInput';
import CurrencyInput from './CurrencyInput';
import DatePickerInput from '../../../../../base/components/ra/inputs/datePicker.input';
import TitleAndBannerInputs from './TitleAndBannerInputs';

const BasicInfoStep = memo(() => {
  const { updateData } = useMissionForm();

  return (
    <SimpleForm
      toolbar={null}
      save={(values) => {
        const basicInfo = pick(values, [
          'campaignName',
          'bannerConfigs',
          'currencyId',
          'timezone',
          'startTime',
          'endTime',
          'group.isSelectAll',
          'group.ids',
          'brand.isSelectAll',
          'brand.ids',
          'title',
        ]);

        updateData(basicInfo);
      }}
    >
      <BasicInfoForm />
      <MissionFormToolbar />
    </SimpleForm>
  );
});

const BasicInfoForm = memo(() => {
  const t = useTranslate();
  const { initialValues, uneditableFields } = useMissionForm();
  const { initialize } = useForm();
  const { campaignNameValidator, endDateValidator } = useMissionValidate();
  const {
    input: { value: startTime },
  } = useField('startTime');
  const {
    input: { value: endTime },
  } = useField('endTime');

  useEffect(() => {
    if (initialValues) {
      initialize(initialValues);
    }
  }, [initialValues]);

  return (
    <>
      <TextInput
        fullWidth
        source="campaignName"
        label="resources.check-in.fields.name"
        isRequired
        validate={campaignNameValidator}
        disabled={uneditableFields?.includes?.('campaignName')}
      />
      <CurrencyInput />
      <Box
        display="grid"
        gridTemplateColumns="2fr 1fr 1fr"
        alignItems="flex-end"
        sx={{
          gap: 24,
        }}
      >
        <TimezoneInput />
        <DatePickerInput
          validate={required()}
          label={t('resources.check-in.fields.start-date')}
          timeFormat=""
          isRequired
          allowClear
          source="startTime"
          showTime={false}
          disabled={uneditableFields?.includes?.('startTime')}
          disabledDate={(current) =>
            !!current &&
            (moment(current).isSameOrBefore(moment()) ||
              (!!endTime && moment(current).isSameOrAfter(moment(endTime), 'date')))
          }
        />
        <DatePickerInput
          validate={endDateValidator}
          label={t('resources.check-in.fields.end-date')}
          timeFormat=""
          isRequired
          source="endTime"
          showTime={false}
          allowClear
          disabled={uneditableFields?.includes?.('endTime')}
          disabledDate={(current) =>
            !!current &&
            (moment(current).isSameOrBefore(moment()) ||
              (!!startTime && moment(current).isSameOrBefore(moment(startTime), 'date')))
          }
        />
      </Box>
      <GroupInput />
      <BrandInput />
      <TitleAndBannerInputs />
    </>
  );
});

export default BasicInfoStep;
