/* eslint-disable react/prop-types */
import React from 'react';
import { FunctionField, TextField, useLocale } from 'react-admin';
import { DateField, DecimalField } from '../../base/components/ra/fields';
import LargeWinFilterWrapper from './components/large-win.filter';
import resourceSlug from '../../constant/resource-slug';
import CustomLinkField from '../../base/components/ra/fields/customLink.field';
import { transformIdValue } from '../../services/util/stringUtil';
import { CustomizedNoPageList } from '../../base/components/customized/CustomizedNoPageList';

function LargeWinList(props) {
  const locale = useLocale();

  return (
    <CustomizedNoPageList
      filters={<LargeWinFilterWrapper />}
      sort={{
        field: 'created',
        order: 'DESC',
      }}
      disabledAutoRefetch
      rowClick={false}
      {...props}
    >
      <CustomLinkField
        source="id"
        label="common.fields.id"
        href={(record) => `/#/${resourceSlug.BET}/${record.id}/show`}
        title={(record) => transformIdValue(record.id)}
        sortable={false}
      />
      <DateField source="created" label="common.fields.created" />
      <CustomLinkField
        source="player"
        label="resources.player.name"
        href={(record) => `/#/${resourceSlug.PLAYER}/${record.player.id}/show`}
        title={(record) => record.player.name}
        sortable={false}
      />
      <FunctionField
        source="game"
        render={(record) => (record.game ? JSON.parse(record.game)?.[locale] : '')}
        sortable={false}
      />

      <CustomLinkField
        source="group"
        label="resources.group.name"
        href={(record) => `/#/${resourceSlug.GROUP}/${record.group.id}/show`}
        title={(record) => record.group.name}
        sortable={false}
      />
      <CustomLinkField
        source="brand"
        label="resources.brand.name"
        href={(record) => `/#/${resourceSlug.BRAND}/${record.brand.id}/show`}
        title={(record) => record.brand.name}
        sortable={false}
      />
      <TextField source="betType" sortable={false} />
      <DecimalField source="amount" sortable={false} />
      <DecimalField source="earn" sortable={false} />
      <TextField source="currencyName" sortable={false} />
      <TextField source="betStatus" sortable={false} />
    </CustomizedNoPageList>
  );
}

export default LargeWinList;
