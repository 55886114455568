import React from 'react';
import {
  ReferenceField,
  useLocale,
  TextField,
  useTranslate,
  FunctionField,
  NumberField,
  useRecordContext,
} from 'react-admin';
import { Card, Chip } from '@material-ui/core';
import { ActionMenu } from '../bet/components/action.menu';
import Labeled from '../../base/components/ra/labeled';
import CommonShowWrapper from '../../base/components/customized/common-wrapper/CommonShowWrapper';
import { ShowSection } from '../../base/components/guesser/wealth-show.guesser';
import { AmountField, DateField } from '../../base/components/ra/fields';
import { DATE_DISPLAY_FORMAT } from '../../constant';

const BetBonusShow = (props) => {
  const t = useTranslate();

  return (
    <CommonShowWrapper actions={<ActionMenu type="toolbar" {...props} />} {...props}>
      <Card>
        <ShowSection title={t('common.show.general')}>
          <TextField source="id" label="common.fields.id" />
          <Labeled label="common.fields.created">
            <DateField source="created" dateFormat={DATE_DISPLAY_FORMAT} />
          </Labeled>
          <Labeled label="common.fields.updated">
            <DateField source="updated" dateFormat={DATE_DISPLAY_FORMAT} />
          </Labeled>
        </ShowSection>
        <BetInfoSection />
      </Card>
    </CommonShowWrapper>
  );
};

const BetInfoSection = () => {
  const t = useTranslate();
  const record = useRecordContext();
  const locale = useLocale();

  return (
    <ShowSection title={t('resources.bet-bonus.information')} record={record}>
      <ReferenceField source="groupId" label="resources.bet.fields.groupId" reference="group" link="show">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="brandId" label="resources.bet.fields.brandId" reference="brand" link="show">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="playerId" label="resources.bet.fields.player" reference="player" link="show">
        <TextField source="nativeId" />
      </ReferenceField>
      <ReferenceField source="gameId" label="resources.bet.fields.game" reference="game" link="show">
        <TextField source={`name.${locale}`} />
      </ReferenceField>
      <ReferenceField source="currencyId" label="resources.bet.fields.currency" reference="currency" link="show">
        <TextField source="name" />
      </ReferenceField>
      <FunctionField
        label="resources.bet.fields.betStatus"
        render={(record) => <Chip label={t(`common.betStatus.${record.betStatus}`)} />}
      />
      <FunctionField
        label="resources.bet.fields.betType"
        render={(record) => <Chip label={t(`common.betType.${record.betType}`)} />}
      />
      <NumberField source="betSize" label="resources.bet.fields.betSize" />
      <NumberField source="betLevel" label="resources.bet.fields.betLevel" />
      <Labeled label="resources.bet.fields.amount">
        <AmountField source="amount" />
      </Labeled>
      <Labeled label="resources.bet.fields.earn">
        <AmountField source="earn" />
      </Labeled>
      <Labeled label="resources.bet.fields.playerBalance">
        <AmountField source="playerBalance" />
      </Labeled>
      <Labeled label="resources.bet.fields.endingBalance">
        <AmountField source="endingBalance" />
      </Labeled>
      <TextField source="channel" label="resources.bet.fields.channel" />
      <Labeled label="resources.bet.fields.endTime">
        <DateField source="endTime" dateFormat={DATE_DISPLAY_FORMAT} />
      </Labeled>
    </ShowSection>
  );
};

export default BetBonusShow;
