import { CACHE_LIST_BY_PAGE, UPDATE_CURRENT_LIST_PAGE } from '../root.actions';

const initialStates = {};

export const listReducer = (state = initialStates, action) => {
  const newPage = action.payload?.page || 1;

  if (!action.payload?.resource) return state;

  switch (action.type) {
    case CACHE_LIST_BY_PAGE:
      return {
        ...state,
        [action.payload.resource]: {
          list:
            newPage === 1
              ? {
                  1: action.payload.data,
                }
              : {
                  ...state[action.payload.resource].list,
                  [newPage]: action.payload.data,
                },
          currentPage: newPage,
        },
      };
    case UPDATE_CURRENT_LIST_PAGE:
      return {
        ...state,
        [action.payload.resource]: {
          ...state[action.payload.resource],
          currentPage: action.payload.page,
        },
      };
    default:
      return state;
  }
};
