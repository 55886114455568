import { Upload as AntUpload, Image } from 'antd';
import React, { cloneElement, isValidElement, memo } from 'react';
import { Delete, Visibility, Publish } from '@material-ui/icons';
import { makeStyles, Box, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslate } from 'react-admin';
import Fieldset from '../Fieldset';

export const FileUploader = memo(
  ({
    src,
    label,
    message,
    dragDropMessage: customizedDragDropMessage,
    fileLimitMessage,
    isLoading,
    onChange,
    reviewViewProps,
    reviewView,
    disabled,
    error,
    sx,
    ...props
  }) => {
    const classes = useStyles({
      error,
    });
    const {
      imageClassName, ...restReviewViewProps
    } = reviewViewProps ?? {};
    const t = useTranslate();

    const dragDropMessage = customizedDragDropMessage ?? t('common.text.dragDropFile');

    return (
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        sx={sx}
      >
        {label && (
          <Fieldset
            mb={2.5}
            title={label}
          />
        )}
        {src ? (
          isValidElement(reviewView) ? (
            cloneElement(reviewView, reviewViewProps)
          ) : (
            <div
              {...restReviewViewProps}
              className={clsx(classes.imagePreview, reviewViewProps?.className)}
            >
              <Image
                src={src}
                wrapperClassName={classes.imageWrapper}
                className={clsx(classes.image, imageClassName)}
                preview={{
                  mask: (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        gap: 8,
                      }}
                    >
                      <Visibility className={classes.maskIcon} />
                      {!disabled && (
                        <Delete
                          className={clsx(classes.maskIcon, classes.errorText)}
                          onClick={e => {
                            e.preventDefault();
                            onChange?.('');
                          }}
                        />
                      )}
                    </Box>
                  ),
                }}
              />
            </div>
          )
        ) : (
          <>
            <Dragger
              disabled={isLoading}
              accept="image/*"
              className={clsx('w-full', props.className)}
              rootClassName={classes.upload}
              showUploadList={false}
              fileList={[]}
              {...props}
            >
              <Box
                width="100%"
                display="flex"
                justifyContent="center"
                my={1}
                height="fit-content"
              >
                {isLoading ? <CircularProgress size={32} /> : <Publish className={classes.uploadIcon} />}
              </Box>
              {isValidElement(dragDropMessage) ? (
                <div className={classes.dragMessage}>{dragDropMessage}</div>
              ) : (
                <div className={classes.dragMessage}>{dragDropMessage}</div>
              )}
              <Box className={classes.messageText}>{fileLimitMessage ?? t('common.text.uploadableImageFileType')}</Box>
            </Dragger>
            {message && (
              <Box
                mt={1}
                mx={3.5}
                className={clsx(classes.messageText, error && classes.errorText)}
              >
                {message}
              </Box>
            )}
          </>
        )}
      </Box>
    );
  },
);

export const { Dragger } = AntUpload;
export const Upload = AntUpload;

const useStyles = makeStyles(theme => ({
  messageText: {
    color: theme.palette.grey[500],
    fontSize: '0.75rem',
    lineHeight: 1.66,
  },
  errorText: {
    color: theme.palette.error.main,
  },
  uploadIcon: ({ error }) => ({
    width: 32,
    height: 32,
    color: error ? theme.palette.error.main : theme.palette.grey[600],
  }),
  imagePreview: {
    display: 'grid',
    padding: 4,
    border: `1px dashed ${theme.palette.primary[500]}`,
    background: theme.palette.primary[100],
    borderRadius: 10,
    height: '9.2rem',
    placeItems: 'center',
  },
  image: {
    borderRadius: 8,
    height: '100% !important',
    objectPosition: 'center',
    objectFit: 'contain',
  },
  imageWrapper: {
    height: '8.6rem',
  },
  upload: ({ error }) => ({
    height: '9.2rem',
    '&.ant-upload-wrapper .ant-upload-drag': {
      borderRadius: 10,
      background: theme.palette.grey[100],
      border: error ? `1px dashed ${theme.palette.error.main}` : `1px dashed ${theme.palette.grey[500]}`,
      '&:hover': {
        border: `1px dashed ${theme.palette.primary.main}`,
        background: theme.palette.primary[100],
      },
    },
  }),
  dragMessage: {
    padding: '0 8px',
    fontWeight: 500,
    color: theme.palette.grey[700],
  },
  maskIcon: {
    width: 20,
    height: 20,
    transition: 'transform',
    '&:hover': {
      transform: 'scale(1.1)',
      cursor: 'pointer',
    },
  },
}));
