import React from 'react';
import { useTranslate } from 'react-admin';
import UrlInput from '../../../base/components/customized/common-input/UrlInput';
import InputWithTooltipWrapper from '../../../base/components/common/InputWithTooltipWrapper';

export const TRANSFER_BONUS_URL_FIELD_KEY = 'transferBonusUrl';

const TransferBonusUrl = (props) => {
  const t = useTranslate();

  return (
    <InputWithTooltipWrapper
      source={TRANSFER_BONUS_URL_FIELD_KEY}
      title={t('resources.brand.fields.transferBonusUrl')}
      tooltip={t('resources.brand.fields.transferBonusUrlTooltip')}
      resettable
      helperText
      {...props}
    >
      <UrlInput />
    </InputWithTooltipWrapper>
  );
};

export default TransferBonusUrl;
