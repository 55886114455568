/* eslint-disable no-shadow, no-underscore-dangle, react/prop-types */
import PropTypes from 'prop-types';
import React, { memo, useMemo, Children, cloneElement } from 'react';
import { EditButton, getResources, Loading, useLocale, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import isNumber from 'lodash/isNumber';
import { useSchema, useAuthUser } from '../../hooks';
import { WealthListGuesserType } from '../../../constant';
import Datagrid from '../ra/data-grid';
import Pagination from '../customizable-colums/Pagination';
import { IdField } from '../ra/fields';
import List from '../ra/list/List';
import ListActions from './actions/list.action';
import ReportListGuesser from './report/report-list.guesser';
import WealthCustomQueryList from './wealth-custom-query-list';
import WealthEmptyPage from './wealth-empty-page';
import FilterGuesser from './wealth-filter.guesser';
import { guessProperty } from './wealth-guesser';
import RowActionField from '../ra/fields/row-action-field/row-action.field';

const WealthListGuesserComponent = memo((props) => {
  const translate = useTranslate();
  const locale = useLocale();
  const user = useAuthUser();
  const { role } = user;
  const { api, ref } = useSchema();

  const {
    basePath,
    hasList,
    resource,
    children,
    rowClick,
    hasEdit,
    hasShow,
    refManyEditGuesser,
    quickEdit, // edit button in edit view
    refManyEditAddButton,
    listType,
    optimized,
    permissions,
    resources,
    rowAction,
    hideAction,
    hasCreate,
    extraActions,
    excludeFields = [],
    includeFields = [],
    excludeFilterInputs = [],
    extraFilterInputs = [],
    filterProps,
    filterChildren,
    additionFilters,
    hideDeleteAction,
    fieldOrder,
    reportInterface: overrideReportInterface,
    ...rest
  } = props;

  const currentResource = resources?.filter((r) => r.name === resource)?.[0];
  if (!currentResource) {
    return <Loading />;
  }

  const {
    options: { hasDelete, reportInterface: defaultReportInterface, translatable, resourceId },
  } = currentResource;

  const reportInterface = overrideReportInterface || defaultReportInterface;

  const deletableInList = hasDelete && !hideDeleteAction;

  const reOrderFields = (properties) => (acc, currName, currIndex, array) => {
    // Get list field need to reorder
    const listIndexField = array.filter((property) => isNumber(properties[property]?.properties?.indexList?.default));

    // Omit reorder field, will add them later
    if (listIndexField.includes(currName)) {
      return acc;
    }

    // Put fields don't have index first
    acc.push(currName);

    if (currIndex < array.length - 1) {
      return acc;
    }

    // Sort reorder field to valid with its index
    const listIndexFieldValid = listIndexField
      .filter((item) => array.includes(item))
      .sort((firstEl, secondEl) => {
        const firstIndexEl = properties[firstEl]?.properties?.indexList?.default;
        const secondIndexEl = properties[secondEl]?.properties?.indexList?.default;
        return firstIndexEl - secondIndexEl;
      });

    // Add listName to the last position, and DONE!
    return acc.concat(listIndexFieldValid);
  };

  const {
    components = [],
    properties,
    required,
  } = useMemo(() => {
    if (!api || reportInterface) return {};

    const { paths } = api;
    const responseSchema = paths?.[basePath]?.get?.responses?.['200']?.content?.['application/json']?.schema;

    let responseComponent;
    let responseRef;
    if (responseSchema?.oneOf) {
      responseRef = responseSchema.oneOf.filter((r) => r.type === 'array')?.[0]?.items;

      responseRef = responseRef?.$ref || responseRef?.allOf?.filter((i) => i?.$ref)?.[0]?.$ref;

      responseComponent = ref.get(responseRef);
    }

    const { properties, required } = responseComponent;

    Object.keys(properties)
      .filter(
        (key) =>
          properties[key]?.properties?.hide?.default ||
          properties._metaData?.properties?.hiddenFields?.default?.includes(key) ||
          properties._metaMerchantData?.properties?.hiddenFields?.default?.includes(key),
      )
      .forEach((i) => {
        delete properties[i];
      });

    // skip custom properties passed to children
    let components = Object.keys(properties)
      ?.filter(
        (property) =>
          !properties[property]?.properties?.hideList &&
          !property.startsWith('_') &&
          property !== 'id' &&
          (children?.length > 0
            ? !children.map((child) => child.props?.source).includes(property)
            : children?.props?.source !== property),
      )
      ?.reduce(reOrderFields(properties), [])
      ?.filter((property) => !excludeFields?.includes(property))
      ?.filter((property) => (includeFields?.length > 0 ? includeFields?.includes(property) : property))
      ?.map((property) =>
        guessProperty({
          source: property,
          properties,
          required,
          apiRef: ref,
          view: 'list',
          resource,
          resources,
          hasShow,
          locale,
          translate,
          permissions,
        }),
      );

    if (properties._metaData) {
      const metaProps = properties._metaData?.properties;
      if (metaProps.hideSystemFields && properties._docMeta?.properties?.systemFields?.default?.length > 0) {
        components = components.filter(
          (i) => !properties._docMeta?.properties?.systemFields?.default.includes(i.props.source),
        );
      }
    }

    return {
      components,
      properties,
      required,
    };
  }, [
    translate,
    api,
    ref,
    children,
    resource,
    resources?.length,
    excludeFields,
    includeFields,
    locale,
    translate,
    permissions,
    reportInterface,
  ]);

  if (!hasList) {
    throw new Error('This resource does not support list');
  }

  if (!api) {
    return <Loading />;
  }

  const isCustomList = listType === WealthListGuesserType.custom;
  const ListComponent = isCustomList ? WealthCustomQueryList : List;

  const renderIdField = () => {
    const arrayChildren = Children.toArray(children);

    let ComponentIdCustom = null;
    Children.forEach(arrayChildren, (child) => {
      if (child.props.source === 'id') {
        ComponentIdCustom = cloneElement(child, {
          hasShow,
        });
      }
    });

    // Render Component ID Custom
    if (ComponentIdCustom) {
      return ComponentIdCustom;
    }

    // Render Component ID Default
    if (!ComponentIdCustom && !excludeFields.includes('id')) {
      return (
        <IdField
          source="id"
          hasShow={hasShow}
          // optionId={properties?.id?.properties?.optionId?.default}
        />
      );
    }

    // Render empty component
    return null;
  };

  const componentList = useMemo(() => {
    const list = [renderIdField(), ...components].concat(
      Children.map(children, (child) => (child?.props?.source === 'id' ? null : child)),
    );

    if (Array.isArray(fieldOrder)) {
      list.sort((a, b) => fieldOrder.indexOf(a?.props?.source) - fieldOrder.indexOf(b?.props?.source));
    }

    return list;
  }, [components, children, fieldOrder, renderIdField]);

  return (
    <>
      {reportInterface ? (
        <ReportListGuesser
          hasList={hasList}
          hasShow={hasShow}
          hasEdit={hasEdit}
          hasCreate={hasCreate}
          basePath={basePath}
          resource={resource}
          perPage={25}
          excludeFields={excludeFields}
          reportInterface={reportInterface}
          pagination={<Pagination />}
          permissions={permissions}
          extraActions={extraActions}
          sort={
            props.defaultSort || {
              field: 'id',
              order: 'ASC',
            }
          }
          rowClick={rowClick}
          filterProps={filterProps}
          {...rest}
        />
      ) : (
        <>
          <ListComponent
            hasList={hasList}
            hasShow={hasShow}
            hasEdit={hasEdit}
            hasCreate={hasCreate}
            basePath={basePath}
            resource={resource}
            empty={<WealthEmptyPage />}
            sort={
              props.defaultSort || {
                field: 'created',
                order: 'DESC',
              }
            }
            title={
              resource.includes('/')
                ? translate(`resources.${resource.replace('/', '.')}.name`)
                : translate(`resources.${resource}.name`)
            }
            filters={
              <FilterGuesser
                properties={properties}
                excludesInputs={[...excludeFilterInputs]}
                extraInputs={[extraFilterInputs]}
                filterProps={filterProps}
              >
                {filterChildren}
              </FilterGuesser>
            }
            perPage={25}
            bulkActionButtons={false}
            actions={
              <ListActions
                properties={properties}
                resourceId={resourceId}
                translatable={translatable}
                permissions={permissions}
                user={user}
              />
            }
            pagination={<Pagination />}
            permissions={permissions}
            additionFilters={additionFilters}
            {...rest}
          >
            <Datagrid
              defaultColumns={required
                ?.filter((i) => properties?.[i]?.format !== 'richtext')
                .filter((key) => key !== 'buildIn')}
              rowClick={refManyEditGuesser ? '' : rowClick}
              refManyEditAddButton={refManyEditAddButton}
              optimized={optimized}
              properties={properties}
            >
              {/* {!excludeFields.includes('id') && (
                <IdField
                  source="id"
                  hasShow={hasShow}
                  optionId={properties?.id?.properties?.optionId?.default}
                />
              )} */}

              {componentList}
              {rowAction &&
                !hideAction &&
                React.cloneElement(rowAction, {
                  ...props,
                })}
              {(deletableInList || extraActions) && !hideAction && (
                <RowActionField
                  label={translate('ra.field.action')}
                  hasDelete={deletableInList}
                  extraActions={extraActions}
                  sortable={false}
                  role={role}
                />
              )}
              {quickEdit && <EditButton />}
            </Datagrid>
          </ListComponent>
        </>
      )}
    </>
  );
});

const WealthListGuesser = (props) => {
  const originalResources = useSelector(getResources);

  const resources = useMemo(() => originalResources, [originalResources?.length]);

  if (!resources?.length) {
    return null;
  }

  return <WealthListGuesserComponent {...props} resources={resources} />;
};

WealthListGuesser.propTypes = {
  hasList: PropTypes.bool,
  basePath: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
  resource: PropTypes.string.isRequired,
  rowClick: PropTypes.string,
  hasEdit: PropTypes.bool,
  hasShow: PropTypes.bool,
  refManyEditGuesser: PropTypes.bool,
  quickEdit: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  refManyEditAddButton: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  listType: PropTypes.oneOf([WealthListGuesserType.default, WealthListGuesserType.custom]),
  optimized: PropTypes.bool,
  hideAction: PropTypes.bool,
  permissions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  includeFields: PropTypes.array,
  excludeFields: PropTypes.array,
  excludeFilterInputs: PropTypes.array,
  extraFilterInputs: PropTypes.array,
  filterChildren: PropTypes.node,
  hideDeleteAction: PropTypes.bool,
  fieldOrder: PropTypes.arrayOf(PropTypes.string),
  filterProps: PropTypes.object,
};

WealthListGuesser.defaultProps = {
  hasList: false,
  basePath: null,
  children: null,
  rowClick: '',
  hasEdit: false,
  hasShow: false,
  refManyEditGuesser: false,
  quickEdit: false,
  listType: WealthListGuesserType.default,
  optimized: false,
  permissions: [],
  excludeFields: [],
  includeFields: [],
  excludeFilterInputs: [],
  extraFilterInputs: [],
  filterChildren: null,
  hideAction: false,
  hideDeleteAction: false,
  fieldOrder: null,
  filterProps: {},
};

export default React.memo(WealthListGuesser);
