/* eslint-disable import/no-cycle, no-shadow */
import React, { useContext, useEffect } from 'react';
import { useLocation, useParams, Route } from 'react-router-dom';
import { SelectField, TextField, useLocale, useTranslate } from 'react-admin';
import { Card } from '@material-ui/core';
import { NavigationContext } from '../../../../App';
import CampaignDetailsTabs, { CAMPAIGN_TABS, CampaignTab } from '../CampaignDetailsTabs';
import { UUID_REGEX } from '../../../../constant';
import resourceSlug from '../../../../constant/resource-slug';
import { CUSTOM_ROUTES } from '../../../../constant/customRoutes';
import { DateField } from '../../../../base/components/ra/fields';
import { ACTION_MODULE } from '../../../../constant/campaign';
import ChangeLogFilterWrapper from './ChangeLogFilters';
import { useTableListStyles } from '../utils';
import CommonWrapper from '../../../../base/components/reverse-integration/common-wrapper';
import ChangeLogDetailDrawer from './ChangeLogDetailDrawer';
import { CustomizedNoPageList } from '../../../../base/components/customized/CustomizedNoPageList';

const ChangeLogList = (props) => {
  const t = useTranslate();
  const locale = useLocale();
  const { id } = useParams();
  const classes = useTableListStyles();

  const { setNavigationData } = useContext(NavigationContext);
  const { pathname, search } = useLocation();

  useEffect(() => {
    setNavigationData({
      pageTitleInfo: {
        label: t('resources.campaign.details-title'),
        showSubLabel: false,
      },
      breadcrumbs: [
        {
          label: t('ra.page.home'),
          link: '/',
        },
        {
          label: t('resources.group.boost.name'),
        },
        {
          label: t('resources.campaign.mkt-free-spin'),
          link: `/${resourceSlug.MKT_FREE_SPINS}`,
        },
        {
          label: `#${pathname
            .split('/')
            .find((path) => path.match(UUID_REGEX))
            .slice(0, 4)}`,
          isActive: true,
        },
      ],
    });
    return () => {
      setNavigationData({});
    };
  }, [t, pathname, locale]);

  return (
    <>
      <Card>
        <CampaignDetailsTabs currentTab={CAMPAIGN_TABS.CHANGE_LOG}>
          <CampaignTab value={CAMPAIGN_TABS.CHANGE_LOG} index={CAMPAIGN_TABS.CHANGE_LOG}>
            <CommonWrapper className={classes.wrapper}>
              <CustomizedNoPageList
                {...props}
                resource={`${resourceSlug.MKT_FREE_SPINS}/${id}/${resourceSlug.ACTION_LOG}`}
                filters={<ChangeLogFilterWrapper />}
                basePath={CUSTOM_ROUTES.CAMPAIGN_CHANGES_LOG}
                rowClick={(actionId) =>
                  `/${resourceSlug.MKT_FREE_SPINS}/${id}/${resourceSlug.ACTION_LOG}/${actionId}/show${search}`
                }
                className={classes.root}
                syncWithLocation
              >
                <DateField source="created" label="resources.campaign.fields.created" />
                <SelectField
                  source="module"
                  label="resources.campaign.fields.module"
                  choices={Object.values(ACTION_MODULE).map((module) => ({
                    id: module,
                    name: t(`resources.campaign.module.${module}`),
                  }))}
                  sortable={false}
                />
                <TextField source="actor" label="resources.campaign.fields.created-by" sortable={false} />
                <TextField source="actionType" label="resources.campaign.fields.action" sortable={false} />
              </CustomizedNoPageList>
            </CommonWrapper>
          </CampaignTab>
        </CampaignDetailsTabs>
      </Card>
      <Route
        exact
        path={`${CUSTOM_ROUTES.CAMPAIGN_CHANGES_LOG}/:actionId/show`}
        render={() => <ChangeLogDetailDrawer />}
      />
    </>
  );
};

export default ChangeLogList;
