import React, { memo } from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import MuiDrawer from '../../../../../../../base/components/mui/MuiDrawer';
import { useMissionCalender } from '../MissionCalenderContext';
import { formatDate } from '../../../../../../../services/util/date';
import { DATE_DISPLAY_FORMAT } from '../../../../../../../constant';

const MissionDrawer = memo(({ open, onClose, title, children }) => {
  const t = useTranslate();
  const classes = useStyles();
  const { missionDate } = useMissionCalender();

  return (
    <MuiDrawer
      open={open}
      anchor="right"
      onClose={onClose}
      title={
        <Box display="flex" flexDirection="column">
          {title}
          {!!missionDate && (
            <Box className={classes.title}>
              {t('resources.mission.mission-date', {
                date: formatDate(missionDate, DATE_DISPLAY_FORMAT),
              })}
            </Box>
          )}
        </Box>
      }
      classes={{
        paper: classes.paper,
      }}
    >
      {children}
    </MuiDrawer>
  );
});

export default MissionDrawer;

MissionDrawer.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
};

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: '24rem',
    width: '24rem',
    '& form': {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    "& [class*='Card']": {
      flexGrow: 1,
      padding: 0,
      overflow: 'scroll',
      height: 'calc(100% - 1em)',
    },
  },
  title: {
    fontSize: '0.9rem',
    color: theme.palette.grey[700],
    fontWeight: 600,
  },
}));
