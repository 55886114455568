import React from 'react';
import { Result, Button } from 'antd';
import { Box } from '@material-ui/core';

const ErrorFallback = ({ error }) => {
  console.error(error);

  return (
    <Box
      width="100%"
      height="100dvh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={(
          <Button
            href="/"
            type="primary"
          >
            Back Home
          </Button>
        )}
      />
    </Box>
  );
};

export default ErrorFallback;
