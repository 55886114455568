/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslate } from 'react-admin';
import WealthShowGuesser from '../../base/components/guesser/wealth-show.guesser';
import { ActionMenu } from './components/action.menu';
import PlayerWallets from './components/PlayerWallets';

const PlayerShow = (props) => {
  const t = useTranslate();

  return (
    <WealthShowGuesser
      {...props}
      contentFooter={<WagerLink />}
      actionMenu={<ActionMenu {...props} />}
      sections={[<PlayerWallets title={t('resources.player.wallet-info')} />]}
    />
  );
};

const WagerLink = ({ recordId }) => {
  const translate = useTranslate();

  return (
    <a href={`#/wager?displayedFilters=%7B"player.id"%3Atrue%7D&filter=%7B"player"%3A%7B"id"%3A"${recordId}"%7D%7D`}>
      {translate('ra.action.view_wager')}
    </a>
  );
};

export default PlayerShow;
