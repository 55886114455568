import { useState } from 'react';
import { useNotify, useRefresh, useTranslate } from 'react-admin';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { showConfirmDialog } from '../../services/redux/app/app.actions';
import resourceSlug from '../../constant/resource-slug';

export const useCancelCampaign = () => {
  const [isCanceling, setIsCanceling] = useState(false);
  const notify = useNotify();
  const t = useTranslate();
  const refresh = useRefresh();
  const dispatch = useDispatch();

  const confirm = (options) => {
    dispatch(
      showConfirmDialog({
        isOpen: true,
        title: 'resources.campaign.cancel-campaign-title',
        content: 'resources.campaign.cancel-campaign-desc',
        onConfirm: () => cancelCampaign(options),
      }),
    );
  };

  const cancelCampaign = async (campaignId) => {
    setIsCanceling(true);

    try {
      await axios.post(`/api/${resourceSlug.MKT_FREE_SPINS}/cancel`, {
        campaignId,
      });
      notify(t('resources.campaign.canceled-campaign-successfully'), 'success');
      refresh();
    } catch (error) {
      console.log(error);
    } finally {
      setIsCanceling(false);
    }
  };

  return {
    cancelCampaign: confirm,
    isCanceling,
  };
};
