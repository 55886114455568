/* eslint-disable import/no-cycle, no-param-reassign */
import React, { memo, useEffect, useMemo, useRef } from 'react';
import { required, SimpleForm, useInput, useTranslate } from 'react-admin';
import { useForm } from 'react-final-form';
import pick from 'lodash/pick';
import { Box, FormHelperText } from '@material-ui/core';
import MissionFormToolbar from '../MissionFormToolbar';
import { useMissionForm, useMissionValidate } from '../../utils';
import { WALLET_TYPES } from '../../../../../constant/campaign';
import MissionConfigCalendar from './MissionConfigCalender';
import { SelectInput } from '../../../../../base/components/ra/inputs';

const MissionSettingsStep = memo(() => {
  const { isSaving, updateData } = useMissionForm();

  return (
    <SimpleForm
      toolbar={null}
      saving={isSaving}
      save={(values) => {
        const settingsInfo = pick(values, ['missionList', 'destinationTransferWallet']);

        updateData(settingsInfo);
      }}
    >
      <SettingsForm />
      <MissionFormToolbar />
    </SimpleForm>
  );
});

const SettingsForm = memo(() => {
  const { initialValues } = useMissionForm();
  const { initialize } = useForm();
  const { missionConfigValidate } = useMissionValidate();
  const { uneditableFields, data } = useMissionForm();
  const t = useTranslate();
  const { startTime, endTime, currencyId } = data.current;
  const previousCurrencyId = useRef(currencyId);

  const {
    input: { value: missionList, onChange: onMissionListChange },
    meta: { error, submitError, touched },
  } = useInput({
    source: 'missionList',
    validate: missionConfigValidate,
  });

  const showMissionError = (submitError || error) && touched;

  const walletChoices = useMemo(
    () =>
      Object.values(WALLET_TYPES)
        .filter((type) => type !== WALLET_TYPES.NORMAL_WALLET)
        .map((type) => ({
          id: type,
          name: t(`common.wallet.${type}`),
        })),
    [t],
  );

  useEffect(() => {
    if (initialValues) {
      initialize(initialValues);
    }
  }, [initialValues]);

  useEffect(() => {
    if (
      !Object.keys(missionList || {}).length ||
      !currencyId ||
      !previousCurrencyId.current ||
      previousCurrencyId.current === currencyId
    ) {
      previousCurrencyId.current = currencyId;
      return;
    }

    const newMissionList = Object.keys(missionList).reduce((list, date) => {
      list[date] = {
        ...missionList[date],
        missionTasks: missionList[date].missionTasks.map((data) => ({
          ...data,
          rewards: [],
        })),
      };

      return list;
    }, {});

    onMissionListChange(newMissionList);
  }, [currencyId]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        gap: 4,
      }}
    >
      <SelectInput
        source="destinationTransferWallet"
        label="resources.check-in.fields.transfer-destination"
        isRequired
        validate={[required()]}
        choices={walletChoices}
        defaultValue={walletChoices?.length === 1 ? walletChoices[0].id : undefined}
        disabled={!!uneditableFields?.includes?.('walletConversion')}
      />
      <MissionConfigCalendar
        mode={uneditableFields?.includes?.('missionList') ? 'view' : 'edit'}
        startTime={startTime}
        endTime={endTime}
        data={missionList}
        onChange={onMissionListChange}
        error={showMissionError}
      />
      <Box
        component={FormHelperText}
        display={showMissionError ? 'flex' : 'none'}
        marginTop="3px !important"
        error={!!showMissionError}
        key={`helperText-${+showMissionError}`}
      >
        {error || submitError || ' '}
      </Box>
    </Box>
  );
});

export default MissionSettingsStep;
