import { Badge, Box, Chip, IconButton, makeStyles, useTheme } from '@material-ui/core';
import React, { memo, useMemo } from 'react';
import { useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Delete, ErrorRounded } from '@material-ui/icons';
import { formatNumber } from '../../../../../../../services/util/formatNumber';
import MissionToolbar from './MissionToolBar';
import { useMissionCalender } from '../MissionCalenderContext';
import { MISSION_LEVEL_TYPES, MISSION_TYPES } from '../../../../../../../constant/campaign';

const DailyMissionList = memo(({ isCurrentMonth, day, isOutRange, date, isPast, isToday }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { mode, missionList } = useMissionCalender();

  const { missionTasks = [], missionLevelType = MISSION_LEVEL_TYPES.ALL_AT_ONCE } = useMemo(
    () => missionList[date] || {},
    [date, missionList],
  );

  return (
    <Box className={classes.calendarBox} bgcolor={isOutRange ? theme.palette.grey[100] : theme.palette.common.white}>
      {/* Day */}
      <Box className={classes.calendarBoxHeader}>
        <Box
          fontSize="0.85rem"
          fontWeight={500}
          color={isCurrentMonth ? theme.palette.grey[900] : theme.palette.grey[400]}
        >
          {day}
        </Box>
        {!isOutRange && (
          <Box
            display="flex"
            sx={{
              gap: 4,
            }}
          >
            {mode === 'view' && missionLevelType === MISSION_LEVEL_TYPES.PROGRESSIVE && <ProgressiveBadge />}
            {mode === 'edit' && (
              <MissionToolbar disabled={isPast || isToday} date={date} missionLevelType={missionLevelType} />
            )}
          </Box>
        )}
      </Box>
      {!isOutRange && (
        <Box className={clsx(classes.calendarMissionList, 'no-scrollbar')}>
          {missionTasks?.map((task, idx) => (
            <MissionTask
              key={`${date}-${idx}`}
              data={{
                ...task,
                date,
              }}
              missionLevelType={missionLevelType}
              order={idx}
              mode={mode}
              disabled={isPast || isToday}
            />
          ))}
        </Box>
      )}
    </Box>
  );
});

const ProgressiveBadge = () => {
  const t = useTranslate();
  const classes = useStyles();

  return (
    <Chip
      classes={{
        root: classes.progressiveBadge,
        label: classes.progressiveBadgeLabel,
      }}
      label={t(`resources.mission.mission-level-type.${MISSION_LEVEL_TYPES.PROGRESSIVE}`)}
    />
  );
};

DailyMissionList.propTypes = {
  isCurrentMonth: PropTypes.bool,
  day: PropTypes.number,
  isOutRange: PropTypes.bool,
  date: PropTypes.string,
  isPast: PropTypes.bool,
  isToday: PropTypes.bool,
};

export default DailyMissionList;

const MissionTask = memo(({ data, order, missionLevelType, disabled }) => {
  const t = useTranslate();
  const isProgressive = missionLevelType === MISSION_LEVEL_TYPES.PROGRESSIVE;
  const classes = useStyles(isProgressive);
  const { changeAction, mode, onChange, missionList } = useMissionCalender();

  const label = useMemo(() => {
    const title = t(`resources.mission.mission-type-title.${data?.missionType}`, {
      smart_count: formatNumber('en', data?.target),
    });
    const label = isProgressive ? `${order + 1}. ${title}` : title;

    return label;
  }, [missionLevelType, order, t, data, isProgressive]);

  const hasNoRewards = useMemo(() => !data?.rewards?.length, [data?.rewards]);

  const handleDeleteMission = (order, data) => {
    const newMissionList = {
      ...missionList,
    };
    newMissionList[data.date].missionTasks.splice(order, 1);
    onChange(newMissionList);
  };

  return (
    <Badge
      invisible={!hasNoRewards}
      badgeContent={hasNoRewards && <ErrorRounded />}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      classes={{
        badge: classes.errorBadge,
      }}
    >
      <Chip
        label={
          <Box
            display="flex"
            width="100%"
            sx={{
              gap: 4,
            }}
          >
            <Box flexGrow={1} className={classes.missionTaskLabel}>
              {label}
            </Box>
            {mode === 'edit' && !disabled && (
              <IconButton
                className={classes.deleteButton}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleDeleteMission(order, data);
                }}
                disableRipple
              >
                <Delete />
              </IconButton>
            )}
          </Box>
        }
        className={clsx(classes.missionTaskChip, hasNoRewards && classes.errorMissionTaskChip)}
        onClick={() =>
          changeAction({
            missionDate: data.date,
            action: mode === 'view' || disabled ? 'viewMission' : 'editMission',
            missionOrder: order,
          })
        }
      />
    </Badge>
  );
});

MissionTask.propTypes = {
  data: PropTypes.shape({
    rewards: PropTypes.arrayOf(
      PropTypes.shape({
        rewardName: PropTypes.string,
        rewardId: PropTypes.string,
      }),
    ),
    target: PropTypes.number,
    missionType: PropTypes.oneOf(Object.values(MISSION_TYPES)),
    gameIds: PropTypes.arrayOf(PropTypes.string),
    date: PropTypes.string,
  }),
  order: PropTypes.number,
  missionLevelType: PropTypes.oneOf(Object.values(MISSION_LEVEL_TYPES)),
};

const useStyles = makeStyles((theme) => ({
  missionTaskChip: (isProgressive) => ({
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: isProgressive ? theme.palette.tertiary[300] : theme.palette.primary[300],
    },
    display: 'block',
    width: '100%',
    minHeight: 'fit-content',
    justifyContent: 'flex-start !important',
    backgroundColor: isProgressive ? theme.palette.tertiary[100] : theme.palette.primary[100],
    transition: 'backgroundColor',
    duration: 300,
    '&:hover button': {
      display: 'flex',
    },
    '& button': {
      display: 'none',
    },
  }),
  missionTaskLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'block',
    padding: '0px 6px !important',
    flexGrow: 1,
  },
  calendarBox: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 8,
    overflow: 'hidden',
    aspectRatio: '1 / 1',
    gap: 8,
    maxHeight: 120,
  },
  calendarBoxHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 8,
    alignItems: 'center',
  },
  calendarMissionList: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    overflowY: 'auto',
    gap: 4,
  },
  progressiveBadge: {
    backgroundColor: theme.palette.tertiary[200],
    fontSize: '0.6rem',
  },
  progressiveBadgeLabel: {
    padding: '2px 5px !important',
  },
  deleteButton: {
    fontSize: '1.2rem',
    padding: 0,
    color: theme.palette.error.main,
    height: 'fit-content',
    '& svg': {
      fontSize: 'inherit',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  errorMissionTaskChip: {
    backgroundColor: `${theme.palette.error[100]} !important`,
    '&:hover': {
      backgroundColor: `${theme.palette.error[300]} !important`,
    },
  },
  errorBadge: {
    minWidth: 'fit-content',
    width: 'fit-content',
    height: 'fit-content',
    color: theme.palette.error.main,
    padding: 0,
    '& > svg': {
      width: 14,
      height: 14,
    },
  },
}));
