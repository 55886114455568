import { Box, makeStyles, Tab, Tabs, Typography, useTheme, Button } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import chunk from 'lodash/chunk';
import { CardGiftcard } from '@material-ui/icons';
import { CHECK_IN_TYPES } from '../../../../../constant/campaign';
import resourceSlug from '../../../../../constant/resource-slug';

const RewardConfigTable = ({ campaignType, rewardConfig, defaultDay }) => {
  const classes = useStyles();
  const [currentDay, setCurrentDay] = useState(defaultDay || 1);

  const rewardDates = useMemo(() => Object.keys(rewardConfig || {}).map((item) => +item), [rewardConfig]);

  const currentRewards = useMemo(() => rewardConfig?.[currentDay]?.rewards || [], [rewardConfig, currentDay]);

  if (!rewardDates.length) return null;

  return (
    <Box mb={8} mt={-6} px={12.5}>
      <Box mb={2}>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={Math.floor(currentDay / 30)}
          classes={{
            indicator: classes.indicator,
          }}
        >
          {chunk(rewardDates, 30).map((group) => (
            <Box
              key={`group-${group}`}
              display="grid"
              gridTemplateColumns="repeat(10,1fr)"
              minWidth="100%"
              height="fit-content"
              sx={{
                gap: 4,
              }}
            >
              {group.map((day) => (
                <Tab
                  key={`tab-${day}`}
                  data-selected={day === currentDay}
                  classes={{
                    root: classes.tabRoot,
                    wrapper: classes.tabWrapper,
                  }}
                  label={<RewardConfigLabel day={day} campaignType={campaignType} />}
                  onClick={() => setCurrentDay(day)}
                />
              ))}
            </Box>
          ))}
        </Tabs>
      </Box>
      <Box
        display="flex"
        flexWrap="wrap"
        sx={{
          gap: 12,
        }}
      >
        {currentRewards?.map((item) => (
          <RewardBox key={item.rewardId} rewardName={item.rewardName} rewardId={item.rewardId} />
        ))}
      </Box>
    </Box>
  );
};

RewardConfigTable.propTypes = {
  campaignType: PropTypes.oneOf(Object.values(CHECK_IN_TYPES)),
  rewardConfig: PropTypes.object,
  defaultDay: PropTypes.number,
};

const RewardConfigLabel = ({ campaignType, day }) => {
  const t = useTranslate();

  return (
    <Box
      display="flex"
      sx={{
        gap: 8,
      }}
    >
      <Box component={CardGiftcard} width={18} />
      {campaignType === CHECK_IN_TYPES.CONSECUTIVE
        ? t('resources.check-in.consecutive-day', {
            smart_count: day,
          })
        : t('resources.check-in.cumulative-day', {
            smart_count: day,
          })}
    </Box>
  );
};

export const RewardBox = ({ rewardName, rewardId }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Button
      href={`/#/${resourceSlug.REWARD}/${rewardId}/details`}
      className={classes.rewardBox}
      bgcolor={theme.palette.grey[100]}
    >
      <Box display="flex" p={2.5} justifyContent="center" alignItems="center" flexDirection="column">
        <Box component={CardGiftcard} width={20} />
        <Box
          component={Typography}
          overflow="hidden"
          variant="body2"
          width="100%"
          display="-webkit-box"
          textAlign="center"
          sx={{
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3,
          }}
        >
          {rewardName}
        </Box>
      </Box>
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  rewardBox: {
    width: 120,
    aspectRatio: 1,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 8,
    flexDirection: 'column',
    display: 'flex',
    margin: 0,
  },
  tabRoot: {
    '&[data-selected="true"]': {
      '& $tabWrapper': {
        color: 'white',
      },
      backgroundColor: theme.palette.primary.dark,
      border: `1px solid ${theme.palette.primary.dark}`,
    },
    padding: 0,
    minWidth: 80,
    minHeight: 'fit-content',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 4,
  },
  tabWrapper: {
    textTransform: 'capitalize',
    color: 'black',
    fontWeight: 600,
    padding: 6,
    height: 'fit-content',
  },
  indicator: {
    height: 0,
  },
}));

export default RewardConfigTable;
