import React, { useMemo } from 'react';
import { Box, useTheme } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import { useLocale, useInput, useTranslate } from 'react-admin';
import PropTypes from 'prop-types';

import localeEn from 'antd/es/date-picker/locale/en_US';
import localeZhCn from 'antd/es/date-picker/locale/zh_CN';
import localeTh from 'antd/es/date-picker/locale/th_TH';
import moment from 'moment';
import DatePickerAntd from '../../antd/AntdDatePicker';
import { DATE_DISPLAY_FORMAT } from '../../../../constant';

const getAntDesignLocale = (locale) => {
  switch (locale) {
    case 'cn':
      return localeZhCn;
    case 'th':
      return localeTh;
    case 'en':
    default:
      return localeEn;
  }
};

const rootStyles = {
  marginTop: '8px',
  marginBottom: '4px',
};

const inputContainerStyles = ({ error, muiTheme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '40px',
  height: 'fit-content',
  border: `1px solid ${error ? muiTheme.palette.error.main : '#0000003B'}`,
  borderRadius: '4px',
  background: '#FFFFFF',
  marginTop: '8px',
  position: 'relative',
  color: error ? muiTheme.palette.error.main : alpha('#000000', 0.6),
});

const labelStyles = {
  position: 'absolute',
  top: -7,
  left: 9,
  padding: '0 6px',
  maxWidth: '1000px',
  background: '#FFFFFF',
  color: 'inherit',
  fontSize: '12px',
  fontFamily: 'Normal',
  fontWeight: 400,
  lineHeight: 1,
};

const DatePickerInput = (props) => {
  const { label, errorMessage, multiple, ...otherProps } = props;

  const translate = useTranslate();

  const locale = useLocale();
  const antDesignLocale = getAntDesignLocale(locale);

  const {
    isRequired,
    input,
    meta: { error, submitError, touched },
  } = useInput(props);
  const muiTheme = useTheme();

  const errorText = touched ? error || submitError || errorMessage : '';

  const value = useMemo(() => {
    if (!input.value) return undefined;
    if (multiple) {
      return input.value.map((item) => (item ? moment(item) : undefined));
    }

    return moment(input.value);
  }, [input.value, multiple]);

  return (
    <Box style={rootStyles} className="date-picker-input">
      <Box
        style={inputContainerStyles({
          error: errorText,
          muiTheme,
        })}
      >
        <Box component="label" style={labelStyles}>
          {translate(label)}
          {isRequired ? ' *' : ''}
        </Box>

        <DatePickerAntd
          showTime
          onChange={input.onChange}
          value={value}
          allowClear={false}
          bordered={false}
          suffixIcon={null}
          format={DATE_DISPLAY_FORMAT}
          locale={antDesignLocale}
          multiple={multiple}
          {...otherProps}
        />
      </Box>
      <Box
        style={{
          margin: '4px 14px 0px',
          color: muiTheme.palette.error.main,
          minHeight: '20px',
          fontSize: '0.75rem',
        }}
      >
        {errorText ? translate(errorText) : ''}
      </Box>
    </Box>
  );
};

DatePickerInput.propTypes = {
  label: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
};

DatePickerInput.defaultProps = {
  errorMessage: undefined,
};

export default DatePickerInput;
