import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslate } from 'react-admin';

const PlayerUpdateBatchAction = () => {
  const t = useTranslate();

  return <Typography variant="h4">{t('resources.campaign.player-update-batch')}</Typography>;
};

export default PlayerUpdateBatchAction;
