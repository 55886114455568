import React, { useMemo } from 'react';
import { FunctionField, ReferenceField, SelectField, TextField, useShowController, useTranslate } from 'react-admin';
import { Card } from '@material-ui/core';
import moment from 'moment-timezone';
import CheckInDetailsTabs, { CHECK_IN_TABS } from '../CheckInDetailsTabs';
import { ShowSection } from '../../../../../base/components/guesser/wealth-show.guesser';
import Labeled from '../../../../../base/components/ra/labeled';
import { DateField } from '../../../../../base/components/ra/fields';
import { DATE_DISPLAY_FORMAT } from '../../../../../constant';
import BrandList from './BrandList';
import Description from './Description';
import { CHECK_IN_TYPES, WALLET_TYPES } from '../../../../../constant/campaign';
import RewardConfigTable from './RewardConfigTable';
import ActiveLabel from '../../../../../base/components/ActiveLabel';
import CampaignStatus from '../../../../mkt-free-spin/components/CampaignStatus';

const CheckInGeneralInfo = (props) => {
  const t = useTranslate();
  const { record: campaign } = useShowController(props);

  const rewardConfig = useMemo(
    () =>
      campaign?.campaignSettings?.rewardConfigs?.reduce?.((acc, currentConfig) => {
        acc[currentConfig.totalCheckInDays] = {
          rewards: currentConfig.rewards || [],
        };
        return acc;
      }, {}) || {},
    [campaign],
  );

  return (
    <Card>
      <CheckInDetailsTabs currentTab={CHECK_IN_TABS.GENERAL_INFO}>
        <ShowSection title={t('resources.check-in.general-info')}>
          <TextField source="id" label="common.fields.id" />
          <TextField source="campaignName" label="resources.check-in.fields.name" />
          <TextField source="campaignCode" label="resources.campaign.fields.code" />
          <Labeled label="common.fields.status">
            <CampaignStatus />
          </Labeled>
          <ReferenceField reference="currency" source="currencyId" label="common.fields.currency" link="show">
            <TextField source="name" />
          </ReferenceField>
          <Labeled label="resources.campaign.fields.start-date">
            <DateField source="startTime" dateFormat={DATE_DISPLAY_FORMAT} />
          </Labeled>
          <Labeled label="resources.campaign.fields.end-date">
            <DateField source="endTime" dateFormat={DATE_DISPLAY_FORMAT} />
          </Labeled>
          <FunctionField
            source="timezone"
            label="resources.check-in.fields.timezone"
            render={(record) =>
              `${record.timezone} (GMT${record.timezone ? moment().tz(record.timezone).format('Z') : ''})`
            }
            sortable={false}
          />
        </ShowSection>
        <BrandList
          excludedGroupIds={campaign?.group?.excludes}
          groupIds={campaign?.group?.includes}
          excludedBrandIds={campaign?.brand?.excludes}
          brandIds={campaign?.brand?.includes}
        />
        <Description
          termAndCondition={campaign?.termAndCondition}
          title={campaign?.title}
          bannerConfigs={campaign?.bannerConfigs}
        />
        <ShowSection
          title={t('common.text.settings')}
          wrapperProps={{
            mt: -5,
          }}
        >
          <SelectField
            source="campaignSettings.missionType"
            label="resources.check-in.fields.type"
            choices={Object.values(CHECK_IN_TYPES).map((type) => ({
              id: type,
              name: t(`resources.check-in.type.${type}`),
            }))}
          />
          {campaign?.campaignSettings?.missionType === CHECK_IN_TYPES.CONSECUTIVE && (
            <FunctionField
              source="campaignSettings.requiredCheckInDays"
              label="resources.check-in.fields.max-consecutive-days"
              render={(record) => record.campaignSettings.requiredCheckInDays}
            />
          )}
          {campaign?.campaignSettings?.fromCheckInTime && campaign?.campaignSettings?.toCheckInTime && (
            <FunctionField
              label="resources.check-in.fields.time-frame"
              render={(record) =>
                `${record.campaignSettings.fromCheckInTime} - ${record.campaignSettings.toCheckInTime}`
              }
            />
          )}
          {campaign?.campaignSettings?.missionType === CHECK_IN_TYPES.CONSECUTIVE && (
            <FunctionField
              label="resources.check-in.fields.repeatable-streak"
              render={(record) => (
                <ActiveLabel
                  isActive={record.campaignSettings?.streakRepeatable}
                  activeLabel={t('ra.message.yes')}
                  inactiveLabel={t('ra.message.no')}
                />
              )}
            />
          )}
          {campaign?.campaignSettings?.missionType === CHECK_IN_TYPES.CONSECUTIVE &&
            !campaign?.campaignSettings?.streakRepeatable && (
              <FunctionField
                label="resources.check-in.fields.repeatable-reward"
                render={(record) => (
                  <ActiveLabel
                    isActive={record.campaignSettings?.rewardRepeatable}
                    activeLabel={t('ra.message.yes')}
                    inactiveLabel={t('ra.message.no')}
                  />
                )}
              />
            )}
          <SelectField
            source="walletConversion"
            label="resources.check-in.fields.transfer-destination"
            choices={Object.values(WALLET_TYPES).map((type) => ({
              id: type,
              name: t(`common.wallet.${type}`),
            }))}
          />
        </ShowSection>
        {!!campaign?.campaignSettings?.rewardConfigs?.length && (
          <RewardConfigTable
            rewardConfig={rewardConfig}
            defaultDay={campaign.campaignSettings.rewardConfigs[0]?.totalCheckInDays}
            campaignType={campaign?.campaignSettings?.missionType}
          />
        )}
      </CheckInDetailsTabs>
    </Card>
  );
};

export default CheckInGeneralInfo;
