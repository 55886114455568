import React from 'react';
import { useShowController, useTranslate } from 'react-admin';
import { Cancel, FilterNoneOutlined } from '@material-ui/icons';
import { Box, useTheme } from '@material-ui/core';
import CommonShowWrapper from '../../base/components/customized/common-wrapper/CommonShowWrapper';
import { CAMPAIGN_STATUS } from '../../constant/campaign';
import { useMissionNavigation } from './components/utils';
import MissionGeneralInfo from './components/MissionDetails/MissionGeneralInfo';
import { hasPermission } from '../../services/util/auth';
import resourceSlug from '../../constant/resource-slug';
import { useCancelCampaign } from '../../base/hooks/useCancelCampaign';

const MissionShow = (props) => {
  const t = useTranslate();
  const theme = useTheme();
  const { record: campaign } = useShowController(props);
  const { cancelCampaign } = useCancelCampaign();
  const hasUpdatePermission = hasPermission(resourceSlug.MISSION, 'update');
  const hasCreatePermission = hasPermission(resourceSlug.MISSION, 'create');
  const hasEditPermission = hasUpdatePermission && campaign?.status === CAMPAIGN_STATUS.ACTIVE;

  useMissionNavigation();

  return (
    <CommonShowWrapper
      defaultToolbarProps={{
        hasEdit: hasEditPermission,
        extraOptions: [
          {
            condition: hasCreatePermission,
            name: t('ra.action.duplicate'),
            icon: <Box component={FilterNoneOutlined} />,
            to: `/${resourceSlug.MISSION}/create?cloneId=${campaign?.id}`,
            hideLabel: true,
          },
          {
            condition: hasEditPermission,
            name: t('ra.action.cancel'),
            icon: <Box component={Cancel} color={theme.palette.error.main} />,
            onClick: () => cancelCampaign(campaign?.id),
            hideLabel: true,
          },
        ],
      }}
      {...props}
    >
      {campaign?.id && <MissionGeneralInfo {...props} />}
    </CommonShowWrapper>
  );
};

export default MissionShow;
