import React, { useCallback, memo } from 'react';
import { ReferenceArrayInput, useTranslate } from 'react-admin';
import { useField, useForm } from 'react-final-form';
import { Box } from '@material-ui/core';
import { AutocompleteArrayInput } from '../../../../../base/components/ra/inputs';
import Switcher from '../../../../../base/components/common/Switcher';
import { useCheckInForm } from '../../utils';

const GroupInput = () => {
  const t = useTranslate();
  const {
    input: { value: currencyId },
  } = useField('currencyId');
  const {
    input: { value: isSelectAll },
  } = useField('group.isSelectAll');
  const form = useForm();
  const { uneditableFields } = useCheckInForm();

  const disabled = !currencyId || !!uneditableFields?.includes?.('group');

  const groupValidator = useCallback(
    (groupIds, values) => {
      if (!groupIds?.length && !values.group?.isSelectAll) {
        return t('ra.validation.required');
      }
      return undefined;
    },
    [t],
  );

  const handleResetBrands = () => {
    form.change('brand.ids', []);
  };

  return (
    <Box>
      <Switcher
        source="group.isSelectAll"
        label="resources.check-in.fields.select-all-groups"
        defaultValue={false}
        onChange={() => handleResetBrands()}
        disabled={disabled}
      />
      <ReferenceArrayInput
        key={`group.ids-${isSelectAll}`}
        variant="outlined"
        label={isSelectAll ? 'resources.check-in.fields.excluded-groups' : 'resources.group.name'}
        source="group.ids"
        defaultValue={undefined}
        isRequired={!isSelectAll}
        onChange={() => handleResetBrands()}
        validate={groupValidator}
        reference="group"
        filterToQuery={(value) => ({
          'name||$cont': value,
        })}
        filter={{
          enabled: true,
          'currencies.id': currencyId,
        }}
        sort={{
          field: 'name',
          order: 'ASC',
        }}
        disabled={disabled}
        enableGetChoices={() => !disabled}
      >
        <AutocompleteArrayInput optionText="name" resettable enabledSelectAll={false} />
      </ReferenceArrayInput>
    </Box>
  );
};

export default memo(GroupInput);
