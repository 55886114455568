import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslate, useLocale, ReferenceInput } from 'react-admin';
import { useForm } from 'react-final-form';
import CustomizedFilterWrapper from '../../../base/components/customized/CustomizedFilterWrapper';
import DateRangeInput from '../../../base/components/guesser/date-range-input';
import { getBackofficeEnv } from '../../../services/util';
import resourceSlug from '../../../constant/resource-slug';
import { SelectInput, TextInput, AutocompleteInput } from '../../../base/components/ra/inputs';
import { BET_CHANNELS, BET_STATUS, BET_BONUS_TYPES } from '../../../constant';
import { useAuthUser, useEffectAfterMount } from '../../../base/hooks';
import GroupSelector from '../../../base/components/customized/common-input/GroupSelector';
import BrandSelector from '../../../base/components/customized/common-input/BrandSelector';

const BRAND_KEY = 'brandId';
const GROUP_KEY = 'groupId';
const GAME_KEY = 'gameId';

const BetBonusFilters = () => {
  const translate = useTranslate();

  return (
    <CustomizedFilterWrapper>
      <BetDateRangeInput
        source="created"
        label={translate('resources.bet.fields.created')}
        resource={resourceSlug.BET_BONUS}
      />
      <BetDateRangeInput
        source="updated"
        label={translate('resources.bet.fields.updated')}
        dateRange={[null, null]}
        allowClear
        resource={resourceSlug.BET_BONUS}
      />
      <BetDateRangeInput
        source="endTime"
        label={translate('resources.bet.fields.endTime')}
        dateRange={[null, null]}
        allowClear
        resource={resourceSlug.BET_BONUS}
      />
      <TextInput label="resources.bet.fields.id" source="id||$eq" resettable />
      <GameFilter />
      <TextInput label="common.fields.playerId" source="playerId||$eq" resettable />
      <GroupFilter />
      <BrandFilter />
      <CurrencyFilter />
      <SelectInput
        label="resources.bet.fields.betType"
        source="betType"
        choices={Object.values(BET_BONUS_TYPES).map((item) => ({
          id: item,
          name: translate(`common.betType.${item}`),
        }))}
        resettable
      />
      <SelectInput
        label="resources.bet.fields.betStatus"
        source="betStatus"
        choices={Object.values(BET_STATUS).map((item) => ({
          id: item,
          name: translate(`common.betStatus.${item}`),
        }))}
        resettable
      />
      <SelectInput
        label="resources.bet.fields.channel"
        source="channel"
        choices={Object.values(BET_CHANNELS).map((item) => ({
          id: item,
          name: item,
        }))}
        resettable
      />
    </CustomizedFilterWrapper>
  );
};

export default BetBonusFilters;

const BetDateRangeInput = (props) => {
  const { source, resource, label, ...rest } = props;
  const { BET_QUERY_SUPPORT_DAYS } = getBackofficeEnv();
  const today = moment().add(1, 'days').startOf('day');
  const yesterday = moment().subtract(1, 'days').startOf('day');

  const disabledDate = (current, dates) => {
    const startDate = dates[0] && moment(dates[0].format()).startOf('day');
    const endDate = dates[1] && moment(dates[1].format()).startOf('day');

    const currentDate = moment(current.format()).startOf('day');
    const now = moment();

    const numberOfDateLimit = 7;

    const tooLate = startDate
      ? currentDate.diff(startDate, 'days') >= numberOfDateLimit || currentDate.isAfter(now)
      : currentDate.isAfter(now);

    const tooEarly = endDate
      ? endDate.diff(currentDate, 'days') >= numberOfDateLimit ||
        currentDate.isBefore(now.subtract(BET_QUERY_SUPPORT_DAYS, 'days'))
      : now.diff(currentDate, 'days') >= BET_QUERY_SUPPORT_DAYS && currentDate.isBefore(now);

    return !!tooEarly || !!tooLate;
  };

  return (
    <DateRangeInput
      entity="report"
      source={source}
      resource={resource}
      label={label}
      prefix
      clearable={false}
      dateRange={[yesterday, today]}
      disabledDate={disabledDate}
      ranges={[]}
      {...rest}
    />
  );
};

BetDateRangeInput.propTypes = {
  source: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  label: PropTypes.string,
};

BetDateRangeInput.defaultProps = {
  label: '',
};

const GroupFilter = () => {
  const user = useAuthUser();
  const userGroupId = user.group?.id;

  return (
    <GroupSelector
      source={GROUP_KEY}
      defaultValue={userGroupId}
      disabled={Boolean(userGroupId)}
      resettable
      helperText={false}
    />
  );
};

const BrandFilter = () => {
  const user = useAuthUser();
  const form = useForm();
  const formValues = form.getState().values;
  const currentSelectedGroupId = formValues[GROUP_KEY];
  const groupId = currentSelectedGroupId;
  const userBrandId = user?.brands?.[0]?.id;

  useEffectAfterMount(() => {
    if (!formValues[BRAND_KEY] || !currentSelectedGroupId) return;
    form.change(BRAND_KEY, undefined);
  }, [currentSelectedGroupId]);

  return (
    <BrandSelector
      source={BRAND_KEY}
      defaultValue={userBrandId}
      disabled={Boolean(userBrandId)}
      resettable
      helperText={false}
      filter={{
        'group.id': groupId,
        enabled: true,
      }}
    />
  );
};

const GameFilter = () => {
  const locale = useLocale();
  const user = useAuthUser();
  const form = useForm();
  const formValues = form.getState().values;
  const currentSelectedBrandId = formValues[BRAND_KEY];

  const brandId = user?.brands?.[0]?.id || currentSelectedBrandId;

  useEffectAfterMount(() => {
    if (!formValues[GAME_KEY] || !currentSelectedBrandId) return;
    form.change(GAME_KEY, undefined);
  }, [currentSelectedBrandId]);

  return (
    <ReferenceInput
      helperText={false}
      label="resources.game.name"
      source={GAME_KEY}
      variant="outlined"
      reference={resourceSlug.GAME}
      filter={{
        brandId,
        enabled: true,
      }}
      filterToQuery={(value) => ({
        [`name->>'$.${locale}'||$cont`]: value,
      })}
      sort={{
        field: 'created',
        order: 'DESC',
      }}
      resettable
    >
      <AutocompleteInput optionText={`name.${locale}`} />
    </ReferenceInput>
  );
};

const CurrencyFilter = () => (
  <ReferenceInput
    helperText={false}
    label="resources.currency.name"
    source="currencyId"
    variant="outlined"
    reference={resourceSlug.CURRENCY}
    filter={{
      enabled: true,
    }}
    filterToQuery={(value) => ({
      'name||$cont': value,
    })}
    sort={{
      field: 'created',
      order: 'DESC',
    }}
    resettable
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>
);
