/* eslint-disable import/no-cycle, no-use-before-define  */
import React, { useMemo } from 'react';
import { FunctionField, ReferenceField, SelectField, TextField, useTranslate } from 'react-admin';
import { useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';
import moment from 'moment';
import { useTableListStyles } from '../../../../mkt-free-spin/components/utils';
import CommonWrapper from '../../../../../base/components/reverse-integration/common-wrapper';
import resourceSlug from '../../../../../constant/resource-slug';
import { AmountField, DateField } from '../../../../../base/components/ra/fields';
import ConversionHistoryFilters from './ConversionHistoryFilters';
import { CustomizedNoPageList } from '../../../../../base/components/customized/CustomizedNoPageList';
import CustomLinkField from '../../../../../base/components/ra/fields/customLink.field';
import { useCampaignDetailsUrl } from '../../../../../base/hooks/useCampaignDetailsUrl';
import { WALLET_TRANSACTION_STATUS, WALLET_TRANSFER_TYPE } from '../../../../../constant/transaction';
import WalletTransactionStatus from '../../../../wallet-transaction/components/WalletTransactionStatus';
import RetryButton from '../../../../wallet-transaction/components/RetryButton';
import { hasPermission } from '../../../../../services/util/auth';
import { FILTER_PREFIX_ON_VALUE, SERVER_DATETIME_FORMAT } from '../../../../../constant';

const ConversionHistoryList = () => {
  const t = useTranslate();
  const classes = useTableListStyles();
  const { id } = useParams();
  const { getCampaignDetailsUrl } = useCampaignDetailsUrl();
  const resource = `${resourceSlug.PLAYER}/${id}/wallet-conversion-history`;

  const filterDefaultValues = useMemo(() => {
    const today = moment().add(1, 'days').startOf('day').format(SERVER_DATETIME_FORMAT);
    const yesterday = moment().subtract(1, 'days').startOf('day').format(SERVER_DATETIME_FORMAT);

    return {
      created: `${FILTER_PREFIX_ON_VALUE.BETWEEN}${yesterday},${today}`,
    };
  }, []);

  return (
    <Box mt={3} component={CommonWrapper}>
      <CustomizedNoPageList
        resource={resource}
        basePath={`/${resourceSlug.PLAYER}/${id}/show`}
        filters={<ConversionHistoryFilters />}
        syncWithLocation={false}
        className={classes.root}
        sort={{
          field: 'created',
          order: 'DESC',
        }}
        rowClick={false}
        filterDefaultValues={filterDefaultValues}
      >
        <TextField source="id" label="common.fields.transactionId" />
        <DateField source="created" label="common.fields.created" />
        <DateField source="updated" label="common.fields.updated" />
        <FunctionField
          label={t('common.fields.sourceType')}
          render={(record) => (record.sourceType ? t(`common.sourceType.${record.sourceType}`) : '-')}
        />
        <CustomLinkField
          source="campaignCode"
          label="common.fields.campaignCode"
          href={(record) => getCampaignDetailsUrl(record.sourceType, record.campaignId)}
          title={(record) => record.campaignCode}
        />
        <SelectField
          source="transferType"
          label="resources.player.fields.transfer-type"
          choices={Object.values(WALLET_TRANSFER_TYPE).map((type) => ({
            id: type,
            name: t(`resources.player.transfer-type.${type}`),
          }))}
        />
        <ReferenceField source="currencyId" label="resources.currency.name" reference="currency" link="show">
          <TextField source="name" />
        </ReferenceField>
        <AmountField source="oldBalance" label="resources.player.fields.pre-conversion" />
        <AmountField source="amount" label="common.fields.transactionAmount" />
        <AmountField source="newBalance" label="resources.player.fields.post-conversion" />
        <FunctionField
          label="common.fields.status"
          render={(record) => <WalletTransactionStatus status={record.status} />}
        />
        {hasPermission(resourceSlug.WALLET_TRANSACTION, 'update') && (
          <FunctionField
            source="actions"
            label="ra.field.action"
            render={(record) => (
              <RetryButton
                resource={resource}
                playerId={id}
                transactionId={record.id}
                disabled={
                  record.status !== WALLET_TRANSACTION_STATUS.FAILED &&
                  record.status !== WALLET_TRANSACTION_STATUS.PROCESSING
                }
              />
            )}
          />
        )}
      </CustomizedNoPageList>
    </Box>
  );
};

export default ConversionHistoryList;
