import React, { memo } from 'react';
import { Image } from 'antd';
import { Box } from '@material-ui/core';
import useUploadFile from '../../../hooks/useUploadFile';
import { FileUploader } from './FileUploader';
import { getImageUrl } from '../../../../services/util/image';

const SingleFileUploader = React.forwardRef(({
  value, requireDimension, disabled, ...props
}, ref) => {
  const {
    uploadFile, isLoading,
  } = useUploadFile(requireDimension);

  if (disabled) {
    return (
      <Box
        component={Image}
        alt={props.label}
        src={value ? getImageUrl(value) : ''}
        sx={{
          objectFit: 'contain',
          maxHeight: '15rem',
        }}
      />
    );
  }

  return (
    <FileUploader
      src={value ? getImageUrl(value) : ''}
      isLoading={isLoading}
      action={async file => {
        const fileKey = await uploadFile(file);
        props.onChange?.(fileKey);
      }}
      {...props}
      ref={ref}
    />
  );
});
SingleFileUploader.displayName = 'SingleFileUploader';

export default memo(SingleFileUploader);
