import { useDispatch, useSelector } from 'react-redux';
import { toggleLoading as toggleLoadingAction } from '../../services/redux/app/app.actions';

const useLoading = () => {
  const dispatch = useDispatch();
  const loading = useSelector(({ app }) => app.isLoading);

  const toggleLoading = (visible) => {
    dispatch(toggleLoadingAction(visible));
  };

  return {
    toggleLoading,
    loading,
  };
};

export default useLoading;
