import React, { useEffect, useMemo } from 'react';
import { useFormState, useForm } from 'react-final-form';
import { FormDataConsumer, required, usePermissions } from 'react-admin';
import WealthCreateGuesser from '../../base/components/guesser/wealth-create.guesser';
import CustomSelectConfig, { customFieldsConfig } from '../wallet-integration/components/customSelectConfig';
import { useAuthUser, useQueryParams } from '../../base/hooks';
import { checkCheatModeEnv, isPreStagingEnv } from '../../services/util';
import CurrenciesConfigWrapper, { CURRENCIES_FIELD_KEY } from './components/currencies-config-wrapper';
import PlayerLimitWrapper, { PLAYER_LIMIT_FIELD_KEY } from './components/player-limit';
import { AutocompleteInput } from '../../base/components/ra/inputs';
import ReferenceInput from '../../base/components/ra/inputs/reference.input';
import GetPlayerTokenUrlInput, { GET_PLAYER_TOKEN_URL_FIELD_KEY } from './components/get-player-token-url-input';
import resourceSlug from '../../constant/resource-slug';
import FeaturesInput from './components/features.input';
import AutocompleteFreeSpins from './components/autocomplete-free-spin';
import TransferBonusUrl, { TRANSFER_BONUS_URL_FIELD_KEY } from './components/transfer-bonus-url';

const GROUP_FIELD_KEY = 'group';
export const CUSTOM_FIELDS = [
  CURRENCIES_FIELD_KEY,
  PLAYER_LIMIT_FIELD_KEY,
  GROUP_FIELD_KEY,
  TRANSFER_BONUS_URL_FIELD_KEY,
  GET_PLAYER_TOKEN_URL_FIELD_KEY,
  ...customFieldsConfig,
];

const CustomFieldPlaceBetPayoutConfig = (props) => (
  <FormDataConsumer>
    {({ formData }) =>
      customFieldsConfig.map((field) => (
        <CustomSelectConfig
          key={`brand-create-${field}`}
          source={field}
          implementation={formData.implementation}
          {...props}
        />
      ))
    }
  </FormDataConsumer>
);

const HandlePreFillGroup = () => {
  const { groupId } = useQueryParams();
  const form = useForm();
  const { values: formValues } = useFormState();

  const cleanUrlToCreateNewBrand = () => window.history.pushState(null, null, '/#/brand/create');

  useEffect(() => {
    if (groupId) {
      form.change('group.id', groupId);
    }
  }, []);

  useEffect(() => {
    if (formValues?.group?.id && formValues?.group?.id !== groupId) {
      cleanUrlToCreateNewBrand();
    }
  }, [formValues?.group?.id]);

  return null;
};

const BrandCreate = (props) => {
  const isInPreStaging = isPreStagingEnv();
  const isCheatModeEnv = checkCheatModeEnv();
  const { groupId } = useQueryParams();
  const user = useAuthUser();
  const { permissions } = usePermissions();
  const hasRegulationReadPermission =
    user?.superman ||
    (Array.isArray(permissions) && !!permissions?.find((item) => item.resource.name === resourceSlug.REGULATION)?.read);

  const excludeFields = useMemo(() => {
    const cheatModeExcludeFields = isCheatModeEnv ? [] : ['createPlayerUrl', 'editBalanceUrl', 'playerLimit'];
    const preStgExcludeFields = ['getPlayerTokenUrl'];
    const regulationInput = hasRegulationReadPermission ? [] : ['regulation'];
    return [...cheatModeExcludeFields, ...preStgExcludeFields, ...regulationInput, 'features'];
  }, [isInPreStaging, isCheatModeEnv, hasRegulationReadPermission]);

  const transform = (data) => {
    const dataBody = {
      ...data,
    };
    if (dataBody?.features) {
      dataBody.featureIds = dataBody.features;
      delete dataBody.features;
    }
    return dataBody;
  };

  return (
    <WealthCreateGuesser
      {...props}
      customFields={CUSTOM_FIELDS}
      transform={transform}
      excludeFields={excludeFields}
      fieldOrder={[
        'name',
        'regulation',
        'desc',
        'features',
        'domain',
        'languages',
        'defaultLanguage',
        'group',
        'implementation',
        'authorizeUrl',
        GET_PLAYER_TOKEN_URL_FIELD_KEY,
        'getBalanceUrl',
        'payOutUrl',
        'placeBetUrl',
        TRANSFER_BONUS_URL_FIELD_KEY,
        'betStatusUrl',
        'cancelBetUrl',
        'createPlayerUrl',
        'editBalanceUrl',
        'homeButtonEnabled',
        'enabled',
        'currencies',
      ]}
    >
      <FeaturesInput label="resources.feature-flag.name" initialGroupId={groupId} {...props} />

      <GetPlayerTokenUrlInput source={GET_PLAYER_TOKEN_URL_FIELD_KEY} />
      {isCheatModeEnv && <PlayerLimitWrapper />}
      <CurrenciesConfigWrapper initialGroupId={groupId} {...props} />
      <CustomFieldPlaceBetPayoutConfig {...props} />
      <TransferBonusUrl source={TRANSFER_BONUS_URL_FIELD_KEY} />

      <HandlePreFillGroup {...props} />
      <ReferenceInput
        validate={required()}
        reference="group"
        source={GROUP_FIELD_KEY}
        disabled={Boolean(groupId)}
        format={(data) => data?.id}
        filterToQuery={(value) => ({
          'name||$cont': value || '',
          enabled: true,
        })}
        parse={(id) => ({
          id,
        })}
      >
        <AutocompleteInput variant="outlined" optionText="name" />
      </ReferenceInput>
      {!user.group && <AutocompleteFreeSpins />}
    </WealthCreateGuesser>
  );
};

export default BrandCreate;
