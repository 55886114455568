import { Box, makeStyles, MenuItem, Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { FunctionField, SimpleShowLayout, useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import MDEditor from '@uiw/react-md-editor';
import union from 'lodash/union';
import MuiSelect from '../../../../../base/components/mui/MuiSelect';
import { useLanguageList } from '../../../../../base/hooks/useLanguageList';
import { BannerImage } from '../../../../check-in/components/CheckInDetails/CheckInGeneralInfo/Description';

const MissionTitle = ({ title, bannerConfigs }) => {
  const t = useTranslate();
  const [language, setLanguage] = useState('');
  const { data: allLanguages } = useLanguageList();
  const classes = useStyles();

  const supportLanguages = useMemo(() => {
    const titleLangs = title?.map((item) => item.langCode);
    const bannerLangs = bannerConfigs?.map((item) => item.langCode);

    return union(titleLangs, bannerLangs);
  }, [title, bannerConfigs]);

  useEffect(() => {
    if (supportLanguages?.length) {
      setLanguage(supportLanguages[0]);
    }
  }, [supportLanguages]);

  return (
    <Box px={12.5}>
      <Box
        component={Typography}
        variant="h4"
        sx={{
          margin: '10px 0 !important',
        }}
      >
        {t('resources.check-in.description')}
      </Box>
      <Box component={MuiSelect} width={200} value={language} onChange={(e) => setLanguage(e.target.value)}>
        {supportLanguages?.map((lang) => (
          <MenuItem key={lang} value={lang}>
            {allLanguages?.find?.((item) => item.code === lang)?.name}
          </MenuItem>
        ))}
      </Box>
      <SimpleShowLayout className={classes.section}>
        <FunctionField
          label="resources.check-in.fields.banner"
          render={(record) => {
            const src = record.bannerConfigs?.find?.((item) => item?.langCode === language)?.fileKey || '';
            return <BannerImage src={src} />;
          }}
        />
        <FunctionField
          label="resources.check-in.fields.title"
          render={(record) => (
            <MDEditor.Markdown source={record.title?.find?.((item) => item?.langCode === language)?.value || ''} />
          )}
        />
      </SimpleShowLayout>
    </Box>
  );
};

MissionTitle.propTypes = {
  title: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      langCode: PropTypes.string,
    }),
  ),
};

export default MissionTitle;

const useStyles = makeStyles({
  section: {
    padding: 0,
    marginTop: 12,
    '& .ra-field': {
      width: '100%',
      background: '#F0F1F2',
      borderRadius: '4px',
      padding: '10px 16px',
      wordBreak: 'break-all',
      marginBottom: '16px',
    },
  },
});
