import { useDataProvider } from 'react-admin';
import { useEffect, useState } from 'react';
import resourceSlug from '../../../constant/resource-slug';

const useGetRewardChangeLog = (rewardId) => {
  const dataProvider = useDataProvider();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(!!rewardId);

  const getRewardChangeLog = async () => {
    try {
      setLoading(true);
      const { data } = await dataProvider.getCustomList(`${resourceSlug.REWARD}/${rewardId}/change-log`);

      setData({
        data: data?.data?.map((item, idx) => ({
          id: idx,
          ...item,
        })),
      });
    } catch (err) {
      console.error('[ERROR] Get Reward Change Log', err?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (rewardId) {
      getRewardChangeLog(rewardId);
    }
  }, [rewardId]);

  return {
    data,
    loading,
  };
};

export default useGetRewardChangeLog;
