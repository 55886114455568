/* eslint-disable */
import { isObject, isNaN, get, keys } from 'lodash';
import { CondOperator } from '@nestjsx/crud-request';

import { storeDetailName } from '../redux/app/app.actions';
import { AppBrandEnum, APP_ROUTE, BODY_COLOR_VAR, FILTER_PREFIX_ON_VALUE, LIST_CHEAT_ENV } from '../../constant';
import { getAxiosRetryInstance } from '../axios/axios-retry';
import { genUUID } from './bet-setting.utils';
import { getHook } from './react-hooks-outside';

export const getBackofficeEnv = () => {
  if (isObject(window.backoffice_env)) {
    return {
      // ⬇ Firebase Env
      FIREBASE_API_KEY: get(window.backoffice_env, 'FIREBASE_API_KEY'),
      FIREBASE_AUTH_DOMAIN: get(window.backoffice_env, 'FIREBASE_AUTH_DOMAIN'),
      FIREBASE_DATABASE_URL: get(window.backoffice_env, 'FIREBASE_DATABASE_URL'),
      FIREBASE_PROJECT_ID: get(window.backoffice_env, 'FIREBASE_PROJECT_ID'),
      FIREBASE_STORAGE_BUCKET: get(window.backoffice_env, 'FIREBASE_STORAGE_BUCKET'),
      FIREBASE_MESSAGING_SENDER_ID: get(window.backoffice_env, 'FIREBASE_MESSAGING_SENDER_ID'),
      FIREBASE_APP_ID: get(window.backoffice_env, 'FIREBASE_APP_ID'),
      // ⬇ App Env
      ENV: get(window.backoffice_env, 'ENV'), // TODO: will remove and replace by APP_ENV | this one and APP_ENV are the same
      APP_ENV: get(window.backoffice_env, 'APP_ENV', 'default'),
      APP_BRAND: get(window.backoffice_env, 'APP_BRAND', 'naga'),
      APP_THEME: get(window.backoffice_env, 'APP_THEME', 'default'),
      LOBBY_PAGE_URL: get(window.backoffice_env, 'LOBBY_PAGE_URL'),
      BET_QUERY_SUPPORT_DAYS: parseInt(get(window.backoffice_env, 'BET_QUERY_SUPPORT_DAYS', 90), 10),
      REPORT_BET_QUERY_SUPPORT_DAYS: parseInt(get(window.backoffice_env, 'REPORT_BET_QUERY_SUPPORT_DAYS', 90), 10),
      COMPLETE_ROUND_PERIOD_OPTIONS: get(
        window.backoffice_env,
        'COMPLETE_ROUND_PERIOD_OPTIONS',
        '5,30,60,90,120,150,180,210,240,270,300,330,360,390,420,450,480,510,540,570,600,630,660,690,720',
      ),
      IMAGE_HOST_URL: get(window.backoffice_env, 'IMAGE_HOST_URL'),
    };
  }
  return {};
};

export const formatResources = (permittedResource = []) => {
  const boEnv = getBackofficeEnv();
  const data = permittedResource
    .filter((resource) => {
      if (boEnv.ENV === 'production' || process.env.BACKOFFICE_ENV === 'production') {
        return resource.children?.length > 0 && !['marketing-tool', 'game-shark'].includes(resource.name);
      }
      return resource.children?.length > 0;
    })
    .map((resource) => ({
      ...resource,
      children: resource.children.map((child) => {
        if (child.name !== resource.name) {
          return {
            ...child,
            name: child.prefix ? `${child.prefix}/${child.name}` : child.name,
          };
        }
        return child;
      }),
    }));

  // flatten resource
  const resources = data.reduce((result, d) => {
    const parent = d;
    if (d.children.length > 0) {
      result.push(
        ...d.children.map((child) => ({
          ...child,
          prefix: child.prefix,
          isChild: true,
        })),
      );
      // the children permission will be overridden if it has the same 'name' with parent
      const sameChild = d.children.find((x) => x.name === d.name);
      if (sameChild) {
        parent.id = sameChild.id;
        parent.list = sameChild.list;
        parent.update = sameChild.update;
        parent.create = sameChild.create;
        parent.read = sameChild.read;
        parent.delete = sameChild.delete;
      }
    }
    result.push(parent);
    return result;
  }, []);

  return resources;
};

export const handleDetailsData = (data, resource) => {
  const dispatch = getHook('useDispatch');
  let detailName = data?.name || '';

  if (resource === 'user') {
    detailName = data?.username || '';
  }

  dispatch(storeDetailName(detailName));
};

// noop is DO NOTHING!
export const noop = (f) => f;

export const goToLoginPage = (action = 'replace') => {
  if (action === 'replace') {
    window.location.replace(`/#${APP_ROUTE.LOGIN_ROUTE}`);
  } else {
    window.location.push(`/#${APP_ROUTE.LOGIN_ROUTE}`);
  }
};
export const goToHomePage = (action = 'replace') => {
  if (action === 'replace') {
    window.location.replace(`/#${APP_ROUTE.HOME}`);
  } else {
    window.location.push(`/#${APP_ROUTE.HOME}`);
  }
};

export const csvNumberFormatter = (number) => {
  if (isNaN(number)) {
    return number;
  }
  return number?.toString?.()?.replace?.('.', ',');
};

export const spreadComponents = (component) => {
  if (!component) {
    return [];
  }

  const result = [];
  if (Array.isArray(component)) {
    result.push(...component);
  } else {
    result.push(component);
  }

  return result;
};

export const checkArrayReferenceField = (sourceSchema) => {
  const hasReference = sourceSchema?.items?.$ref || sourceSchema?.items?.allOf;
  return sourceSchema?.type === 'array' && hasReference;
};

export const RoleType = {
  SYSTEM: 'SYSTEM',
  GROUP: 'GROUP',
};

export const checkSystemAdmin = (user) => user?.role?.type === RoleType.SYSTEM;

export const RoleAccessibility = {
  ALL_GROUP: 'ALL_GROUP',
  CUSTOM_ROLE: 'CUSTOM_ROLE',
};

export const RoleBuiltIn = {
  SYSTEM_ADMIN: genUUID('System Admin', 'Role'),
  GROUP_ADMIN: genUUID('Group Admin', 'Role'),
  SUPPORT: genUUID('Support', 'Role'),
  REPORTS: genUUID('Reports', 'Role'),
  BOOST: genUUID('Boost', 'Role'),
  MARKETING: genUUID('Marketing', 'Role'),
  CUSTOMER_SERVICE: genUUID('Customer Service', 'Role'),
  ACCOUNTING: genUUID('Accounting', 'Role'),
  BRAND_ADMIN: genUUID('Brand Admin', 'Role'),
  STREAMER: genUUID('Streamer', 'Role'),
  STREAMER_ADMIN: genUUID('Streamer Admin', 'Role'),
};

export const setBodyBackgroundColor = (value) => {
  const rootEl = document.querySelector(':root');
  rootEl.style.setProperty(BODY_COLOR_VAR, value);
};

export const getQueryOperation = ({ type, format, isEnum, isReference, isTextInput, source, translatableInput }) => {
  if (translatableInput) {
    return null;
  }

  const ignoreContainOperation = ['date', 'date-time', 'datetime-local'];

  // `id` & `nativeTransactionId`: let check `Transaction Transfer` module
  const sourcesIgnoreContainOp = ['id', 'nativeTransactionId'];

  const isReferenceDependOn = !isTextInput && isReference;

  if (
    (type === 'string' || isTextInput) &&
    !ignoreContainOperation.includes(format) &&
    !sourcesIgnoreContainOp.includes(source) &&
    !isEnum &&
    !isReferenceDependOn
  ) {
    return CondOperator.CONTAINS; // Operation => Contain!
  }
  return null;
};

export const clearDateRangePrefix = (dateRange, prefix = FILTER_PREFIX_ON_VALUE.BETWEEN) =>
  dateRange?.replace(prefix, '');

export const checkCheatModeEnv = () => {
  const { APP_ENV } = getBackofficeEnv();
  return LIST_CHEAT_ENV.includes(APP_ENV);
};

export const checkMboEnv = () => {
  const { APP_BRAND } = getBackofficeEnv();
  return APP_BRAND === AppBrandEnum.MBO;
};

export const isPreStagingEnv = () => {
  const { APP_ENV } = getBackofficeEnv();
  return APP_ENV === 'pre-staging';
};

export const setLoadingImage = (imagePath) => {
  const loadingImgEl = document.getElementById('wealth-loading-image');
  if (!loadingImgEl) {
    return;
  }

  loadingImgEl.src = imagePath;
};

export const checkTransatableField = (item, field, locale) => {
  const value = get(item, field);

  if ((value && typeof value === 'object') || item[locale] || item.en) {
    return true;
  }

  return false;
};

/**
 * Always fetch env for client before rendering the Web App.
 */
export const handleFetchingEnvironment = async () => {
  try {
    const axiosRetryInstance = getAxiosRetryInstance();
    const { data } = await axiosRetryInstance.get('/client-env');

    if (isObject(data)) {
      const initValue = Object.create(null);
      window.backoffice_env = keys(data).reduce(
        (envDataObj, envKey) => ({
          ...envDataObj,
          [envKey]: data[envKey],
        }),
        initValue,
      );

      Object.freeze(window.backoffice_env);
    }
  } catch (error) {
    console.debug('Error: at fetching environment\n', error);
  }
};

export const sanitizeObject = (obj) =>
  Object.keys(obj).reduce((accumulator, key) => {
    if (obj[key] != null && obj[key] != undefined) {
      accumulator[key] = obj[key];
    }
    return accumulator;
  }, {});

export const isDeepEqual = (object1, object2) => {
  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) return false;

  for (const key of objKeys1) {
    const value1 = object1[key];
    const value2 = object2[key];

    const isObjects = isObject(value1) && isObject(value2);

    if ((isObjects && !isDeepEqual(value1, value2)) || (!isObjects && value1 !== value2)) {
      return false;
    }
  }
  return true;
};

export const insertIf = (condition, ...items) => {
  return condition ? items : [];
};

export const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
 *
 */
export const getTextWidth = (text, font) => {
  // re-use canvas object for better performance
  const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
};
