import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import moment from 'moment';
import clsx from 'clsx';
import { getCalendarDays, useMissionCalendarData } from '../../../utils';
import DailyMissionList from './DailyMissionList';
import { MissionCalenderContext } from './MissionCalenderContext';
import MissionDetailsDrawer from './DailyMissionList/MissionDetailsDrawer';
import MissionOrderDrawer from './DailyMissionList/MissionOrderDrawer';
import MissionCloneDialog from './DailyMissionList/MissionCloneDialog';

const MissionConfigCalendar = memo(({ onChange, mode = 'view', startTime, endTime, data, error }) => {
  const classes = useStyles();
  const [{ month: currentMonth, year: currentYear }, setCurrentDisplayDate] = useState({
    month: moment(startTime).month(),
    year: moment(startTime).year(),
  });
  const [{ missionDate, action, missionOrder }, setAction] = useState({
    missionDate: null,
    action: 'idle',
    missionOrder: null,
  });

  const [{ month: startMonth, year: startYear }, { month: endMonth, year: endYear }] = useMemo(
    () => [
      {
        month: moment(startTime).month(),
        year: moment(startTime).year(),
      },
      {
        month: moment(endTime).month(),
        year: moment(endTime).year(),
      },
    ],
    [startTime, endTime],
  );

  useEffect(() => {
    if (startTime) {
      setCurrentDisplayDate({
        month: moment(startTime).month(),
        year: moment(startTime).year(),
      });
    }
  }, [startTime]);

  const clearAction = useCallback(() => {
    setAction({
      action: 'idle',
      missionDate: null,
      missionOrder: null,
    });
  }, []);

  const renderMonthHeader = useCallback(() => {
    const months = Array.from(
      {
        length: 12,
      },
      (_, i) => `${i + 1}`.padStart(2, '0'),
    );

    return (
      <Box className={clsx(classes.headerContent, error && classes.error)}>
        <IconButton
          onClick={() => {
            setCurrentDisplayDate((prev) => {
              const newDate = new Date(prev.year, prev.month - 1, 1);
              return {
                month: newDate.getMonth(),
                year: newDate.getFullYear(),
              };
            });
          }}
          disabled={currentMonth === startMonth && currentYear === startYear}
        >
          <ArrowBackIos className={classes.arrowButton} />
        </IconButton>
        <Box fontSize="1.2rem">{`${months[currentMonth]}/${currentYear}`}</Box>
        <IconButton
          onClick={() => {
            setCurrentDisplayDate((prev) => {
              const newDate = new Date(prev.year, prev.month + 1, 1);
              return {
                month: newDate.getMonth(),
                year: newDate.getFullYear(),
              };
            });
          }}
          disabled={currentMonth === endMonth && currentYear === endYear}
        >
          <ArrowForwardIos className={classes.arrowButton} />
        </IconButton>
      </Box>
    );
  }, [currentMonth, endMonth, currentYear, endYear, startMonth, startYear, classes, error]);

  return (
    <MissionCalenderContext.Provider
      value={{
        onChange,
        missionList: data,
        mode,
        changeAction: setAction,
        action,
        missionDate,
        missionOrder,
        clearAction,
      }}
    >
      <Box className={clsx(classes.root, error && classes.error)}>
        <Box className={classes.header}>{renderMonthHeader()}</Box>
        <MissionCalendar
          startTime={startTime}
          endTime={endTime}
          currentMonth={currentMonth}
          currentYear={currentYear}
          className={clsx(error && classes.errorGrid)}
        />
      </Box>
      <MissionDetailsDrawer
        open={action === 'viewMission' || action === 'editMission' || action === 'addMission'}
        onClose={clearAction}
      />
      <MissionOrderDrawer open={action === 'reorderMissions'} onClose={clearAction} />
      <MissionCloneDialog open={action === 'copyMissions'} onClose={clearAction} />
    </MissionCalenderContext.Provider>
  );
});

const MissionCalendar = memo(({ startTime, endTime, currentMonth, currentYear, className }) => {
  const classes = useStyles();
  const calendarDays = useMemo(() => getCalendarDays(currentMonth, currentYear), [currentMonth, currentYear]);

  const calenderData = useMissionCalendarData(startTime, endTime, calendarDays);

  return (
    <Box className={clsx(classes.grid, className)}>
      {calenderData?.map((item) => (
        <DailyMissionList key={`${item.day}-${item.month}-${item.year}`} {...item} />
      ))}
    </Box>
  );
});

export default MissionConfigCalendar;

MissionConfigCalendar.propTypes = {
  mode: PropTypes.oneOf(['view', 'edit']),
  startTime: PropTypes.object,
  endTime: PropTypes.object,
  data: PropTypes.object,
  onChange: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: 10,
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  header: {
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '1.6rem',
  },
  headerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    borderRadius: '10px 10px 0 0',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    padding: '4px 8px',
    gap: 4,
  },
  arrowButton: {
    width: 20,
    height: 20,
    fontSize: 24,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3,minmax(0,1fr))',
    backgroundColor: theme.palette.grey[300],
    borderRadius: '0 0 10px 10px',
    gap: 1,
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(7,minmax(0,1fr))',
    },
  },
  error: {
    borderColor: theme.palette.error.main,
  },
  errorGrid: {
    backgroundColor: theme.palette.error.main,
  },
}));
