import { CAMPAIGN_TYPES } from '../../constant/campaign';
import resourceSlug from '../../constant/resource-slug';

export const useCampaignDetailsUrl = () => ({
  getCampaignDetailsUrl: (campaignType, campaignId) => {
    if (campaignType === CAMPAIGN_TYPES.DAILY_CHECK_IN) {
      return `/#/${resourceSlug.CHECK_IN}/${campaignId}/show`;
    }
    if (campaignType === CAMPAIGN_TYPES.MARKETING_FREE_SPIN) {
      return `/#/${resourceSlug.MKT_FREE_SPINS}/${campaignId}/show`;
    }
    if (campaignType === CAMPAIGN_TYPES.DAILY_MISSION) {
      return `/#/${resourceSlug.MISSION}/${campaignId}/show`;
    }
    return '';
  },
});
