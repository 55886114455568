/* eslint-disable import/no-cycle, no-param-reassign */
import { makeStyles } from '@material-ui/core';
import { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { useLocale, useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { NavigationContext } from '../../../App';
import { UUID_REGEX } from '../../../constant';
import resourceSlug from '../../../constant/resource-slug';
import { CAMPAIGN_STATUS, MISSION_LEVEL_TYPES } from '../../../constant/campaign';
import { sanitizeObject } from '../../../services/util';

export const MissionFormContext = createContext({});

export const useMissionForm = () => {
  const values = useContext(MissionFormContext);

  if (values === null) {
    throw new Error('Make sure to use `MissionFormContext` before using the steps.');
  }

  return values;
};

export const useTableListStyles = makeStyles((theme) => ({
  root: {
    "& [class*='Paper']": {
      boxShadow: 'none',
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 8,
    },
  },
  wrapper: {
    margin: '16px 28px',
  },
}));

export const useMissionNavigation = () => {
  const t = useTranslate();
  const { setNavigationData } = useContext(NavigationContext);
  const { pathname } = useLocation();
  const locale = useLocale();

  useEffect(() => {
    setNavigationData({
      pageTitleInfo: {
        label: t('resources.mission.details-title'),
        showSubLabel: false,
      },
      breadcrumbs: [
        {
          label: t('ra.page.home'),
          link: '/',
        },
        {
          label: t('resources.group.boost.name'),
        },
        {
          label: t('resources.mission.name'),
          link: `/${resourceSlug.MISSION}`,
        },
        {
          label: `#${pathname
            .split('/')
            .find((path) => path.match(UUID_REGEX))
            .slice(0, 4)}`,
          isActive: true,
        },
      ],
    });
    return () => {
      setNavigationData({});
    };
  }, [t, pathname, locale]);
};

export const useMissionCampaignDuration = () => {
  const { data } = useMissionForm();

  const { startTime, endTime } = data.current;

  return useMemo(
    () =>
      startTime && endTime
        ? Math.abs(Math.ceil(moment(startTime).startOf('day').diff(moment(endTime).endOf('day'), 'days', true))) + 1
        : 0,
    [startTime, endTime],
  );
};

export const useMissionValidate = () => {
  const t = useTranslate();
  const { data } = useMissionForm();
  const { startTime, endTime } = data.current;

  const campaignNameValidator = useCallback(
    (value) => {
      const trimmedValue = value?.trim();
      if (!trimmedValue?.length) {
        return t('ra.validation.required');
      }

      if (trimmedValue.length >= 60) {
        return t('ra.validation.maxLength', {
          max: 60,
        });
      }

      return undefined;
    },
    [t],
  );

  const toMissionValidator = useCallback(
    (value, values) => {
      if (!value) {
        return t('ra.validation.required');
      }

      if (values.fromMissionTime && moment(value, 'hh:mm:ss').isBefore(moment(values.fromMissionTime, 'hh:mm:ss'))) {
        return t('resources.check-in.validation.to-check-in-time');
      }

      return undefined;
    },
    [t],
  );

  const endDateValidator = useCallback(
    (value, values) => {
      if (!value) {
        return t('ra.validation.required');
      }

      if (values.startTime && moment(value).isBefore(moment(values.startTime))) {
        return t('resources.check-in.validation.end-date');
      }

      return undefined;
    },
    [t],
  );

  const missionConfigValidate = useCallback(
    (value) => {
      const campaignDuration =
        startTime &&
        endTime &&
        Math.abs(Math.ceil(moment(startTime).startOf('day').diff(moment(endTime).endOf('day'), 'days', true))) + 1;

      const campaignList =
        value &&
        campaignDuration &&
        Object.keys(value || {}).filter((date) => moment(date).isBetween(startTime, endTime, undefined, '[]'));

      const [hasNotAllDaysConfig, hasNotAllDaysHasReward] = campaignList?.reduce?.(
        ([currentNotAllDaysConfigState, currentNotAllDaysHasRewardState], date) => {
          let hasNotAllDaysConfigState = currentNotAllDaysConfigState;
          let hasNotAllDaysHasRewardState = currentNotAllDaysHasRewardState;
          if (!hasNotAllDaysConfigState) {
            hasNotAllDaysConfigState = !value[date].missionTasks?.length;
            hasNotAllDaysHasRewardState = hasNotAllDaysConfigState;
          }

          if (!hasNotAllDaysHasRewardState) {
            hasNotAllDaysHasRewardState = !!value[date].missionTasks.find((task) => !task.rewards?.length);
          }

          return [hasNotAllDaysConfigState, hasNotAllDaysHasRewardState];
        },
        [false, false],
      ) || [true, true];

      if (hasNotAllDaysConfig) {
        return t('resources.mission.validation.empty-mission-config');
      }

      if (hasNotAllDaysHasReward) {
        return t('resources.mission.validation.empty-reward-config');
      }

      return undefined;
    },
    [t, startTime, endTime],
  );

  return useMemo(
    () => ({
      campaignNameValidator,
      toMissionValidator,
      endDateValidator,
      missionConfigValidate,
    }),
    [campaignNameValidator, toMissionValidator, endDateValidator, missionConfigValidate],
  );
};

export const formatMissionData = (data, isCloned = false) => {
  const campaignName = data?.campaignName || '';
  const selectAllGroup = !!data?.group?.isSelectAll;
  const selectAllBrand = !!data?.brand?.isSelectAll;

  return {
    campaignName: isCloned ? `${campaignName} (CLONED)` : campaignName,
    currencyId: data?.currencyId || '',
    timezone: data?.timezone || '',
    startTime: data?.startTime
      ? isCloned && moment(data.startTime).isSameOrBefore(moment())
        ? undefined
        : moment(data.startTime)
      : undefined,
    endTime: data?.endTime
      ? isCloned && moment(data.endTime).isSameOrBefore(moment())
        ? undefined
        : moment(data.endTime)
      : undefined,
    group: {
      isSelectAll: selectAllGroup,
      ids: (selectAllGroup ? data?.group?.excludes : data?.group?.includes) || [],
    },
    brand: {
      isSelectAll: selectAllBrand,
      ids: (selectAllBrand ? data?.brand?.excludes : data?.brand?.includes) || [],
    },
    title: data?.title || [],
    bannerConfigs: data?.bannerConfigs?.filter((banner) => !!banner.fileKey) || [],
    destinationTransferWallet: data?.walletConversion,
    missionList: data?.missionList?.reduce((list, mission) => {
      list[mission.date] = {
        missionLevelType: mission.missionLevelType,
        missionTasks: mission.missionTasks,
      };

      return list;
    }, {}),
  };
};

export const parseMissionData = (data) => {
  const selectAllGroup = !!data?.group?.isSelectAll;
  const selectAllBrand = !!data?.brand?.isSelectAll;
  const startTime = moment(data?.startTime).startOf('day');
  const endTime = moment(data?.endTime).endOf('day');

  return sanitizeObject({
    name: data?.campaignName,
    currencyId: data?.currencyId,
    group: {
      isSelectAll: selectAllGroup,
      ...(selectAllGroup
        ? {
            excludes: data?.group?.ids || [],
          }
        : {
            includes: data?.group?.ids || [],
          }),
    },
    brand: {
      isSelectAll: selectAllBrand,
      ...(selectAllBrand
        ? {
            excludes: data?.brand?.ids,
          }
        : {
            includes: data?.brand?.ids,
          }),
    },
    title: data?.title || undefined,
    bannerConfigs: data?.bannerConfigs || undefined,
    startTime: startTime.toISOString(),
    endTime: endTime.toISOString(),
    destinationTransferWallet: data?.destinationTransferWallet,
    status: CAMPAIGN_STATUS.ACTIVE,
    config: sanitizeObject({
      timezone: data?.timezone,
    }),
    missionList: Object.entries(data?.missionList || {}).reduce((list, [date, data]) => {
      if (moment(date).isBetween(startTime, endTime, undefined, '[]')) {
        list.push({
          date,
          missionLevelType: data?.missionLevelType || MISSION_LEVEL_TYPES.ALL_AT_ONCE,
          missionTasks:
            data?.missionTasks?.map((task) => ({
              rewardIds: task.rewards?.map((reward) => reward.rewardId),
              target: task.target,
              missionType: task.missionType,
              gameIds: task.gameIds?.map((game) => game.value || game),
            })) || [],
        });
      }

      return list;
    }, []),
  });
};

export const getCalendarDays = (month, year) => {
  const currentMonth = month;
  const currentYear = year;

  const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
  const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);

  let firstDayOfWeek = firstDayOfMonth.getDay();
  firstDayOfWeek = firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1;

  // Calculate days from previous month
  const daysFromPrevMonth = firstDayOfWeek;
  const prevMonth = new Date(currentYear, currentMonth, 0);
  const prevMonthDays = Array.from(
    {
      length: daysFromPrevMonth,
    },
    (_, i) => ({
      day: prevMonth.getDate() - daysFromPrevMonth + i + 1,
      month: prevMonth.getMonth(),
      year: prevMonth.getFullYear(),
      isCurrentMonth: false,
    }),
  );

  // Current month days
  const currentMonthDays = Array.from(
    {
      length: lastDayOfMonth.getDate(),
    },
    (_, i) => ({
      day: i + 1,
      month: currentMonth,
      year: currentYear,
      isCurrentMonth: true,
    }),
  );

  // Calculate days needed from next month
  const totalDaysDisplayed = Math.ceil((firstDayOfWeek + lastDayOfMonth.getDate()) / 7) * 7;
  const daysFromNextMonth = totalDaysDisplayed - (daysFromPrevMonth + lastDayOfMonth.getDate());
  const nextMonth = new Date(currentYear, currentMonth + 1, 1);
  const nextMonthDays = Array.from(
    {
      length: daysFromNextMonth,
    },
    (_, i) => ({
      day: i + 1,
      month: nextMonth.getMonth(),
      year: nextMonth.getFullYear(),
      isCurrentMonth: false,
    }),
  );

  // Combine all days
  const allDays = [...prevMonthDays, ...currentMonthDays, ...nextMonthDays];

  return allDays;
};

export const MISSION_DATE_FORMAT_NUMBER = 'yyyyMMdd';

export const useMissionCalendarData = (missionStartTime, missionEndTime, dayList) => {
  const currentDate = moment().startOf('day').unix();

  const startCampaignDate = moment(missionStartTime).startOf('day').unix();
  const endCampaignDate = moment(missionEndTime).startOf('day').unix();

  return useMemo(
    () =>
      dayList?.map((item) => {
        const date = moment({
          year: item.year,
          month: item.month,
          day: item.day,
        }).startOf('day');
        const dayNumber = date.unix();

        const isPast = dayNumber < currentDate;
        const isToday = dayNumber === currentDate;
        const isFuture = dayNumber > currentDate;
        const isOutRange = startCampaignDate > dayNumber || endCampaignDate < dayNumber;

        return {
          ...item,
          date: date.toISOString(),
          isPast,
          isToday,
          isFuture,
          isOutRange,
        };
      }),
    [dayList, currentDate, startCampaignDate, endCampaignDate],
  );
};
