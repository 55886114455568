import { Create, useDataProvider, useTranslate } from 'react-admin';
import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import resourceSlug from '../../constant/resource-slug';
import { useCreateCustom } from '../../base/hooks';
import { formatMissionData, parseMissionData } from './components/utils';
import MissionCreateUpdate from './components/MissionCreateEdit';
import DetailsSkeleton from '../../base/components/common/DetailsSkeleton';

const MissionCreate = (props) => {
  const { search } = useLocation();
  const dp = useDataProvider();
  const [clonedRecord, setClonedRecord] = useState();
  const t = useTranslate();
  const [loading, toggleLoading] = useState(false);

  const { create, createLoading: isCreating } = useCreateCustom({
    resource: resourceSlug.MISSION,
    redirectTo: 'list',
    successMessage: 'resources.check-in.created-successfully',
    resourceTranslated: t(`resources.${resourceSlug.MISSION}.name`),
  });

  const handleSave = (values) => {
    create({
      resource: `${resourceSlug.MISSION}/create`,
      payload: {
        data: parseMissionData(values),
      },
    });
  };

  const handleGetClonedCampaign = async (id) => {
    toggleLoading(true);

    try {
      const { data } = await dp.getOne(resourceSlug.MISSION, {
        id,
      });
      setClonedRecord(formatMissionData(data, true));
    } catch (error) {
      setClonedRecord({});
    } finally {
      toggleLoading(false);
    }
  };

  useEffect(() => {
    if (!search) return;

    const queryString = search.replace('?', '');
    const queryObject = parse(queryString);
    if (!queryObject.cloneId) return;
    handleGetClonedCampaign(queryObject.cloneId);
  }, [search]);

  return (
    <Box component={Create} {...props} width="100%" maxWidth={1200} mx="auto">
      {loading ? (
        <DetailsSkeleton p={6} />
      ) : (
        <MissionCreateUpdate onSave={handleSave} isSaving={isCreating} initialValues={clonedRecord} />
      )}
    </Box>
  );
};

export default MissionCreate;
