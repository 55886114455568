import React from 'react';
import PropTypes from 'prop-types';
import { useListContext } from 'react-admin';
import Pagination from '../customizable-colums/Pagination';
import WealthEmptyPage from '../guesser/wealth-empty-page';
import List from '../ra/list/List';
import EmptyTable from '../guesser/report/emptyTable';
import Datagrid from '../ra/data-grid';

const CustomizedList = ({ children, emptyProps, paginationProps, loadingProps, ...otherProps }) => (
  <List
    perPage={25}
    bulkActionButtons={false}
    sort={{
      field: 'created',
      order: 'DESC',
    }}
    pagination={<Pagination {...paginationProps} />}
    empty={<WealthEmptyPage {...emptyProps} />}
    hasCreate={false}
    hasEdit={false}
    hasShow={false}
    hasList
    exporter={false}
    {...otherProps}
  >
    <ListContent rowClick={otherProps.rowClick} loadingProps={loadingProps}>
      {children}
    </ListContent>
  </List>
);

const ListContent = ({ children, rowClick, loadingProps, ...props }) => {
  const { loaded, ids, total, loading } = useListContext();

  return loaded && (!ids?.length || total === 0) && !loading ? (
    <EmptyTable />
  ) : (
    <Datagrid rowClick={rowClick} loadingProps={loadingProps} {...props}>
      {children}
    </Datagrid>
  );
};

ListContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CustomizedList;

CustomizedList.propTypes = {
  children: PropTypes.node.isRequired,
  emptyProps: PropTypes.object,
};

CustomizedList.defaultProps = {
  emptyProps: null,
};
