import React, { memo } from 'react';
import { Box, Dialog, DialogActions, DialogContent, IconButton, makeStyles, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import clsx from 'clsx';

const MuiDialog = ({ open, onClose, actions, title, children, classes: defaultClasses, ...props }) => {
  const classes = useStyles();

  if (!open) return null;

  return (
    <Dialog
      open={open}
      onClose={(e, reason) => reason !== 'backdropClick' && onClose?.()}
      scroll="paper"
      classes={{
        ...defaultClasses,
        paper: clsx(classes.paper, defaultClasses?.paper),
      }}
      {...props}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" pl={6} py={1} pr={3}>
        <Typography variant="h3">{title}</Typography>

        <IconButton onClick={() => onClose?.()}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent dividers>{children}</DialogContent>
      {actions && (
        <Box
          component={DialogActions}
          sx={{
            padding: '8px 24px !important',
          }}
        >
          {actions}
        </Box>
      )}
    </Dialog>
  );
};

export default memo(MuiDialog);

const useStyles = makeStyles({
  paper: {
    maxWidth: 900,
    minWidth: 600,
    width: '60%',
    maxHeight: '80vh',
    height: 'fit-content',
  },
});
