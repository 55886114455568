import React, { memo, useCallback, useMemo } from 'react';
import { minValue, required, SelectField, SimpleForm, useGetList, useInput, useTranslate } from 'react-admin';
import { Box, FormHelperText, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useField } from 'react-final-form';
import DrawerSimpleShowLayout from '../../../../../../../base/components/ra/drawers/DrawerSimpleShowLayout';
import { MISSION_TYPES } from '../../../../../../../constant/campaign';
import { AmountField, NumberField } from '../../../../../../../base/components/ra/fields';
import Labeled from '../../../../../../../base/components/ra/labeled';
import { GameListField } from '../../../../../../reward/components/RewardDetailsDrawer/DetailsTab';
import { useMissionCalender } from '../MissionCalenderContext';
import MissionDrawer from './MissionDrawer';
import DrawerToolBar from '../../../../../../../base/components/ra/drawers/DrawerToolBar';
import { IntegerInput, SelectInput } from '../../../../../../../base/components/ra/inputs';
import GameListInput from './GameListInput';
import AmountInput from '../../../../../../../base/components/ra/inputs/AmountInput';
import AddRewardModal from '../../../../../../check-in/components/CheckInCreateEdit/SettingsStep/Rewards/RewardConfiguration/AddRewardModal';
import { RewardBox } from '../../../../../../check-in/components/CheckInCreateEdit/SettingsStep/Rewards/RewardConfiguration';
import { useEffectAfterMount } from '../../../../../../../base/hooks';
import resourceSlug from '../../../../../../../constant/resource-slug';
import { useMissionForm } from '../../../../utils';
import { RewardBox as ViewRewardBox } from '../../../../../../check-in/components/CheckInDetails/CheckInGeneralInfo/RewardConfigTable';

const MissionDetailsDrawer = memo(({ open, onClose }) => {
  const t = useTranslate();
  const { action } = useMissionCalender();

  const title = useMemo(() => {
    if (action === 'addMission') {
      return t('resources.mission.add-new-mission');
    }
    if (action === 'editMission') {
      return t('resources.mission.edit-mission');
    }
    return t('resources.mission.details-title');
  }, [action, t]);

  return (
    <MissionDrawer open={open} onClose={onClose} title={title}>
      {open ? action === 'viewMission' ? <MissionViewDetails /> : <MissionConfigForm /> : null}
    </MissionDrawer>
  );
});

export default MissionDetailsDrawer;

MissionDetailsDrawer.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

const MissionViewDetails = memo(() => {
  const t = useTranslate();
  const { missionList, missionOrder, missionDate } = useMissionCalender();

  const data = useMemo(
    () => ({
      ...(missionList?.[missionDate]?.missionTasks?.[missionOrder] || {}),
      date: missionDate,
    }),
    [missionList, missionOrder, missionDate],
  );

  return (
    <Box
      component={DrawerSimpleShowLayout}
      sx={{
        flexGrow: 1,
        overflow: 'scroll',
      }}
      record={data}
    >
      <Box
        component={Typography}
        variant="h4"
        sx={{
          marginBottom: '10px !important',
        }}
      >
        {t('resources.mission.mission-info')}
      </Box>
      <SelectField
        source="missionType"
        label="resources.mission.fields.mission-type"
        choices={Object.values(MISSION_TYPES).map((type) => ({
          id: type,
          name: t(`resources.mission.mission-type.${type}`),
        }))}
      />
      {data?.missionType === MISSION_TYPES.SPIN && (
        <Labeled label={t('resources.mission.fields.total-spins')}>
          <NumberField source="target" emptyText="-" />
        </Labeled>
      )}
      {data?.missionType === MISSION_TYPES.TURNOVER && (
        <Labeled label={t('resources.mission.fields.total-bet')}>
          <AmountField source="target" />
        </Labeled>
      )}
      <Labeled label={t('resources.mission.fields.game-list')}>
        <GameListField source="gameIds" />
      </Labeled>
      <Box
        component={Typography}
        variant="h4"
        sx={{
          margin: '10px 0 !important',
        }}
      >
        {t('resources.mission.reward-info')}
      </Box>
      <Box
        display="flex"
        flexWrap="wrap"
        sx={{
          gap: 12,
        }}
      >
        {data?.rewards?.map((reward) => (
          <ViewRewardBox key={reward.rewardId} rewardName={reward.rewardName} rewardId={reward.rewardId} />
        ))}
      </Box>
    </Box>
  );
});

const MissionConfigForm = memo(() => {
  const t = useTranslate();
  const { onChange, missionDate, missionList, clearAction, action, missionOrder } = useMissionCalender();

  const { data: games, loading: isLoadingGames } = useGetList(
    resourceSlug.GAME,
    {
      page: 1,
      perPage: 1000,
    },
    undefined,
    {
      enabled: true,
      isMcEnabled: true,
    },
    {
      enabled: true,
    },
  );

  const data = useMemo(() => {
    const taskData = missionList?.[missionDate]?.missionTasks?.[missionOrder] || {};
    return {
      ...taskData,
      date: missionDate,
      gameIds: taskData.gameIds?.length === 0 ? Object.keys(games || {}) : taskData.gameIds,
    };
  }, [missionList, missionOrder, missionDate, games]);

  const handleSave = (missionTask) => {
    const newMissionList = {
      ...missionList,
    };

    const newMissionTask = {
      ...missionTask,
      gameIds: missionTask.gameIds.length === Object.keys(games || {}).length ? [] : missionTask.gameIds,
    };

    if (action === 'addMission') {
      newMissionList[missionDate] = {
        ...newMissionList[missionDate],
        missionTasks: [...(newMissionList[missionDate]?.missionTasks || []), newMissionTask],
      };
    } else if (action === 'editMission') {
      newMissionList[missionDate].missionTasks[missionOrder] = newMissionTask;
    }

    onChange(newMissionList);
    clearAction();
  };

  return (
    <SimpleForm initialValues={data} toolbar={<DrawerToolBar disabled={isLoadingGames} />} save={handleSave}>
      <Box
        component={Typography}
        variant="h4"
        sx={{
          marginBottom: '10px !important',
        }}
        whiteSpace="nowrap"
      >
        {t('resources.mission.mission-info')}
      </Box>
      <MissionTypeInput />
      <TargetInput source="target" />
      <GameListInput source="gameIds" games={games} />
      <Box
        component={Typography}
        variant="h4"
        sx={{
          marginBottom: '10px !important',
        }}
        whiteSpace="nowrap"
      >
        {t('resources.mission.reward-info')}
      </Box>
      <RewardsConfig />
    </SimpleForm>
  );
});

const MissionTypeInput = memo(() => {
  const t = useTranslate();

  return (
    <SelectInput
      source="missionType"
      label="resources.mission.fields.mission-type"
      choices={Object.values(MISSION_TYPES).map((type) => ({
        id: type,
        name: t(`resources.mission.mission-type.${type}`),
      }))}
      isRequired
      validate={required(t('ra.validation.required'))}
      fullWidth
    />
  );
});

const TargetInput = memo(({ source }) => {
  const t = useTranslate();
  const {
    input: { value: missionType },
  } = useField('missionType');
  const {
    input: { onChange: onTargetChange },
  } = useField('target');

  useEffectAfterMount(() => {
    if (missionType) {
      onTargetChange();
    }
  }, [missionType]);

  return missionType === MISSION_TYPES.TURNOVER ? (
    <AmountInput source={source} label={t('resources.mission.fields.total-bet')} />
  ) : (
    <IntegerInput
      source={source}
      label="resources.mission.fields.total-spins"
      fullWidth
      isRequired
      validate={[
        required(t('ra.validation.required')),
        minValue(
          1,
          t('ra.validation.greater', {
            min: 0,
          }),
        ),
      ]}
      resettable
    />
  );
});

const RewardsConfig = memo(() => {
  const classes = useStyles();
  const t = useTranslate();
  const { data } = useMissionForm();
  const { currencyId } = data.current;
  const {
    input: { value: rewards, onChange: onRewardChange },
    meta: { error, submitError, touched },
  } = useInput({
    source: 'rewards',
    validate: [
      required(t('ra.validation.required')),
      minValue(
        1,
        t('ra.validation.minLengthArray', {
          smart_count: 0,
        }),
      ),
    ],
  });

  const showMissionError = (submitError || error) && touched;

  const onDeleteReward = useCallback(
    (rewardId) => {
      const newRewards = rewards.filter((reward) => reward.rewardId !== rewardId);
      onRewardChange(newRewards);
    },
    [rewards, onRewardChange],
  );

  const onAddReward = useCallback(
    (newRewards) => {
      onRewardChange([...rewards, ...newRewards]);
    },
    [rewards, onRewardChange],
  );

  return (
    <>
      <Box
        display="flex"
        flexWrap="wrap"
        sx={{
          gap: 8,
        }}
      >
        {rewards?.map?.((item) => (
          <RewardBox key={item.rewardId} rewardName={item.rewardName} onDelete={() => onDeleteReward(item.rewardId)} />
        ))}
        <AddRewardModal
          onChange={onAddReward}
          defaultRewards={rewards}
          currencyId={currencyId}
          className={showMissionError && classes.error}
        />
      </Box>
      <Box
        component={FormHelperText}
        display={showMissionError ? 'flex' : 'none'}
        marginTop="3px !important"
        error={!!showMissionError}
        key={`helperText-${+showMissionError}`}
      >
        {error || submitError || ' '}
      </Box>
    </>
  );
});

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
}));
