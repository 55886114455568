/* eslint-disable no-shadow, no-use-before-define */
import React, { useState } from 'react';
import { Box, IconButton, Tooltip, makeStyles } from '@material-ui/core';
import { FunctionField, SelectField, TextField, useRefresh, useTranslate } from 'react-admin';
import { useDispatch } from 'react-redux';
import { CloudDownloadOutlined, RemoveCircle } from '@material-ui/icons';
import { useParams } from 'react-router-dom';
import { CAMPAIGN_STATUS, PLAYER_BATCH_SOURCE_TYPE, PLAYER_BATCH_STATUS } from '../../../../../constant/campaign';
import { showConfirmDialog } from '../../../../../services/redux/app/app.actions';
import { useRemoveCampaignPlayer } from '../../utils';
import { transformIdValue } from '../../../../../services/util/stringUtil';
import PlayerBatchDetailsDrawer from './PlayerBatchDetailsDrawer';
import { hasPermission, isSystemAdmin } from '../../../../../services/util/auth';
import resourceSlug from '../../../../../constant/resource-slug';
import PlayerUpdateBatchAction from './PlayerUpdateBatchActions';
import { DateField } from '../../../../../base/components/ra/fields';
import PlayerUpdateBatchFilterWrapper from './PlayerUpdateBatchFilters';
import { useAuthUser } from '../../../../../base/hooks';
import { CustomizedNoPageList } from '../../../../../base/components/customized/CustomizedNoPageList';

const useStyles = makeStyles((theme) => ({
  table: {
    padding: '0px 4px',
  },
  withdrawButton: {
    width: 36,
    height: 36,
    '&,&:hover': {
      color: theme.palette.error.main,
      boxShadow: 'none',
    },
  },
  downloadButton: {
    width: 36,
    height: 36,
    '&,&:hover': {
      color: theme.palette.primary.main,
      boxShadow: 'none',
    },
  },
  actionColumn: {
    background: 'white',
  },
  row: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const PlayerUpdateBatchTable = ({ campaign }) => {
  const dispatch = useDispatch();
  const t = useTranslate();
  const classes = useStyles();
  const [batchDetails, setBatchDetails] = useState();
  const { id } = useParams();
  const refresh = useRefresh();
  const isSA = isSystemAdmin();
  const user = useAuthUser();
  const isOperator = !!user?.group?.id;
  const hasEditPermission = hasPermission(resourceSlug.MKT_FREE_SPINS, 'update');
  const isAuthorizedOperator = isOperator && !!campaign?.operatorUpdateEnable;

  const canUpdateBatches =
    campaign?.status !== CAMPAIGN_STATUS.EXPIRED &&
    campaign?.status !== CAMPAIGN_STATUS.CANCELED &&
    hasEditPermission &&
    (isSA || isAuthorizedOperator);

  const { removePlayer, isRemoving } = useRemoveCampaignPlayer(id);

  const onWithdrawConfirm = (batch) => {
    dispatch(
      showConfirmDialog({
        isOpen: true,
        title: 'resources.campaign.cancel',
        content: 'resources.campaign.cancel-batch-desc',
        onConfirm: () => onWithdraw(batch),
      }),
    );
  };

  const onWithdraw = async (batch) => {
    removePlayer(null, batch.id, {
      onSuccess: () => {
        refresh();
      },
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        gap: 12,
        pt: 1.5,
      }}
    >
      {!!batchDetails && <PlayerBatchDetailsDrawer batch={batchDetails} onClose={() => setBatchDetails()} />}
      <CustomizedNoPageList
        resource={`${resourceSlug.MKT_FREE_SPINS}/${id}/player-batch`}
        basePath={`/${resourceSlug.MKT_FREE_SPINS}/${id}/player-batch`}
        actionsProps={{
          sx: {
            flexDirection: 'row-reverse',
            justifyContent: 'space-between !important',
            width: '100%',
          },
        }}
        syncWithLocation={false}
        filters={<PlayerUpdateBatchFilterWrapper />}
        actions={<PlayerUpdateBatchAction />}
        rowClick={(_, __, record) => setBatchDetails(record)}
        className={classes.root}
        sort={{
          field: 'issuedDate',
          order: 'DESC',
        }}
      >
        <FunctionField
          label="resources.campaign.fields.player-batch-code"
          render={(record) =>
            transformIdValue(record.batchCode, {
              prefix: '',
            })
          }
        />
        <DateField source="created" label="resources.campaign.fields.created-date" sortable={false} />
        <TextField source="actor" label="resources.campaign.fields.created-by" sortable={false} />
        <SelectField
          source="source"
          label="resources.campaign.fields.source-type"
          choices={Object.values(PLAYER_BATCH_SOURCE_TYPE).map((source) => ({
            id: source,
            name: t(`resources.campaign.player-batch-source-type.${source}`),
          }))}
          sortable={false}
        />
        <DateField source="issuedDate" label="resources.campaign.fields.scheduled-date" sortable={false} />
        <SelectField
          source="status"
          label="resources.campaign.fields.status"
          choices={Object.values(PLAYER_BATCH_STATUS).map((status) => ({
            id: status,
            name: t(`resources.campaign.player-batch-status.${status}`),
          }))}
          sortable={false}
        />
        <FunctionField
          label="ra.field.action"
          render={(batch) => (
            <Box
              display="flex"
              justifyContent="center"
              sx={{
                gap: 4,
              }}
            >
              {canUpdateBatches && (
                <Tooltip title={t('resources.campaign.cancel')}>
                  <Box>
                    <IconButton
                      className={classes.withdrawButton}
                      disabled={batch.status !== PLAYER_BATCH_STATUS.SCHEDULED || isRemoving}
                      onClick={(e) => {
                        e.stopPropagation();
                        onWithdrawConfirm(batch);
                      }}
                    >
                      <RemoveCircle />
                    </IconButton>
                  </Box>
                </Tooltip>
              )}
              <Tooltip title={t('resources.campaign.download-player-batch')}>
                <Box>
                  <IconButton
                    disableRipple
                    className={classes.downloadButton}
                    href={`/api/${resourceSlug.MKT_FREE_SPINS}/${campaign?.id}/download-players-in-batch?playerBatchId=${batch.id}`}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <CloudDownloadOutlined />
                  </IconButton>
                </Box>
              </Tooltip>
            </Box>
          )}
        />
      </CustomizedNoPageList>
    </Box>
  );
};

export default PlayerUpdateBatchTable;
