/* eslint-disable no-undef */

import axios from 'axios';
import { useCallback, useState, useMemo } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { getRatioString, getImageDimensions } from '../../services/util/image';

const MAX_NUMBER = 99999999;
const EPSILON_RATIO = 0.1;

const useUploadFile = (requireDimension = {}) => {
  const {
    width,
    height,
    isRatio,
    minWidth = 0,
    minHeight = 0,
    maxWidth = MAX_NUMBER,
    maxHeight = MAX_NUMBER,
  } = requireDimension;
  const notify = useNotify();
  const t = useTranslate();
  const [file, setFile] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const uploadFile = useCallback(
    async (file) => {
      if (!file) return '';
      setIsLoading(true);

      if (requireDimension) {
        const dimension = await getImageDimensions(file);

        if (minHeight > dimension.height) {
          notify(
            t('error.minHeight', {
              value: t('common.text.pixels', {
                value: minHeight,
              }),
            }),
            'error',
          );
          setIsLoading(false);
          return '';
        }
        if (maxHeight < dimension.height) {
          notify(
            t('error.maxHeight', {
              value: t('common.text.pixels', {
                value: maxHeight,
              }),
            }),
            'error',
          );
          setIsLoading(false);
          return '';
        }
        if (minWidth > dimension.width) {
          notify(
            t('error.minWidth', {
              value: t('common.text.pixels', {
                value: minWidth,
              }),
            }),
            'error',
          );
          setIsLoading(false);

          return '';
        }
        if (maxWidth < dimension.width) {
          notify(
            t('error.maxWidth', {
              value: t('common.text.pixels', {
                value: maxWidth,
              }),
            }),
            'error',
          );
          setIsLoading(false);

          return '';
        }
        if (isRatio) {
          if (Math.abs(dimension.width / dimension.height - width / height) >= EPSILON_RATIO) {
            notify(
              t('error.ratioRequired', {
                imgRatio: getRatioString(dimension.width, dimension.height),
                ratio: getRatioString(requireDimension.width, requireDimension.height),
              }),
              'error',
            );
            setIsLoading(false);

            return '';
          }
        } else if ((width && dimension.width !== width) || (height && dimension.height !== height)) {
          notify(
            t('error.dimensionRequired', {
              dimension: t('common.text.pixelDimension', {
                width,
                height,
              }),
            }),
            'error',
          );
          setIsLoading(false);

          return '';
        }
      }

      try {
        const { name } = file;
        const fileName = `${Date.now()}-${name}`;
        const { data } = await axios.post('/api/file-tab/pre-signed-url', {
          fileNames: [fileName],
        });

        if (!data?.[0]) {
          setIsLoading(false);
          return '';
        }

        const { fileKey, preSignedURL } = data?.[0];

        await axios.put(preSignedURL, file, {
          headers: {
            'x-ms-blob-type': 'BlockBlob',
            'Content-Type': 'application/octet-stream',
          },
        });

        setFile(file);
        setIsLoading(false);

        return fileKey;
      } catch (error) {
        setIsLoading(false);
        notify(t('error.uploadImageFailed'), 'error');
        return undefined;
      }
    },
    [requireDimension, t, notify],
  );

  return useMemo(
    () => ({
      uploadFile,
      file,
      isLoading,
    }),
    [uploadFile, file, isLoading],
  );
};

export default useUploadFile;
