import React, { useMemo } from 'react';
import { CreateButton, useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';
import CustomTooltip from '../../../../base/components/custom-tooltip';
import { hasPermission } from '../../../../services/util/auth';
import resourceSlug from '../../../../constant/resource-slug';

const RewardListAction = () => {
  const t = useTranslate();
  const { search } = useLocation();

  const createLocation = useMemo(
    () => ({
      pathname: `/${resourceSlug.REWARD}/create${search}`,
      state: {
        _scrollToTop: true,
      },
    }),
    [search],
  );
  return (
    <>
      {hasPermission(resourceSlug.REWARD, 'create') && (
        <CustomTooltip title={t('ra.action.create')} placement="top">
          <CreateButton label="" to={createLocation} />
        </CustomTooltip>
      )}
    </>
  );
};

export default RewardListAction;
