import { createContext, useContext } from 'react';

export const MissionCalenderContext = createContext({});

export const useMissionCalender = () => {
  const values = useContext(MissionCalenderContext);

  if (values === null) {
    throw new Error('Make sure to use `MissionCalenderContext` before using the steps.');
  }

  return values;
};
