import React from 'react';
import { useInput } from 'react-admin';
import PropTypes from 'prop-types';
import SingleFileUploader from '../../common/SingleFileUploader';

const ImageInput = ({ label, isRequired, source, validate, format, parse, ...props }) => {
  const {
    meta: { error, touched, submitError },
    input: { onChange, value },
  } = useInput({
    source,
    validate,
    isRequired,
    parse,
    format,
  });

  const showError = (submitError || error) && touched;

  return <SingleFileUploader label={label} value={value} onChange={onChange} error={showError} {...props} />;
};

ImageInput.propTypes = {
  source: PropTypes.string.isRequired,
};

export default ImageInput;
