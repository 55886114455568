import React, { useEffect } from 'react';
import { AutocompleteInput, useTranslate } from 'react-admin';
import { useField } from 'react-final-form';
import { ReferenceInput } from '../../../../../base/components/ra/inputs';
import { usePrevious } from '../../../../../base/hooks';

const GroupInput = ({ source, ...otherProps }) => {
  const translate = useTranslate();
  const {
    input: { value: group },
  } = useField(source);
  const {
    input: { onChange: onChangeBrand },
  } = useField('brandId');

  const prevGroup = usePrevious(group);

  useEffect(() => {
    if (group === prevGroup || !prevGroup) return;
    onChangeBrand(undefined);
  }, [group]);

  return (
    <ReferenceInput
      variant="outlined"
      label={translate('resources.group.name')}
      source={source}
      reference="group"
      filterToQuery={(value) => ({
        'name||$cont': value,
      })}
      filter={{
        enabled: true,
        enableMkt: true,
      }}
      sort={{
        field: 'name',
        order: 'ASC',
      }}
      {...otherProps}
    >
      <AutocompleteInput optionText="name" resettable />
    </ReferenceInput>
  );
};

GroupInput.propTypes = {};

GroupInput.defaultProps = {};

export default GroupInput;
