import React from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import ImageInput from '../../../base/components/ra/inputs/ImageInput';
import { getRatioString } from '../../../services/util/image';

const GameIconUploader = ({ source }) => {
  const t = useTranslate();
  const classes = useStyles();

  return (
    <ImageInput
      label={t('resources.game.fields.icon')}
      isRequired
      source={source}
      requireDimension={{
        width: 100,
        height: 100,
        minHeight: 100,
        minWidth: 100,
        isRatio: true,
      }}
      message={t('common.text.resolutionRequired', {
        resolution: `${getRatioString(100, 100)} (${t('common.text.pixelDimension', {
          width: 100,
          height: 100,
        })})`,
      })}
      sx={{
        mb: 4,
      }}
      className={classes.size}
      dragDropMessage=""
      reviewViewProps={{
        className: classes.size,
      }}
      parse={(fileKey) => ({
        fileKey,
      })}
      format={(gameIcon) => gameIcon?.fileKey}
    />
  );
};

export default GameIconUploader;

const useStyles = makeStyles({
  size: {
    width: '9.2rem',
    height: '9.2rem',
  },
});
