/* eslint-disable */
import React, { useMemo } from 'react';
import moment from 'moment';
import { FILTER_PREFIX_ON_VALUE, SERVER_DATETIME_FORMAT } from '../../constant';
import { AmountField } from '../../base/components/ra/fields';
import resourceSlug from '../../constant/resource-slug';
import { DefaultNoPagePagination, useNoPageListData } from '../../base/components/customized/CustomizedNoPageList';
import CustomLinkField from '../../base/components/ra/fields/customLink.field';
import { FunctionField, TextField, useTranslate } from 'react-admin';
import { Chip } from '@material-ui/core';
import WalletTransactionStatus from './components/WalletTransactionStatus';
import { useCampaignDetailsUrl } from '../../base/hooks/useCampaignDetailsUrl';
import { WALLET_TRANSACTION_STATUS, WALLET_TRANSFER_TYPE } from '../../constant/transaction';
import { hasPermission } from '../../services/util/auth';
import RetryButton from './components/RetryButton';
import WealthListGuesser from '../../base/components/guesser/wealth-list.guesser';
import { CAMPAIGN_PLAYER_STATUS, CAMPAIGN_TYPES, WALLET_TYPES } from '../../constant/campaign';
import keyBy from 'lodash/keyBy';

const WalletTransactionList = (props) => {
  const t = useTranslate();

  const walletTransactions = useNoPageListData(resourceSlug.WALLET_TRANSACTION);
  const { getCampaignDetailsUrl } = useCampaignDetailsUrl();

  const filterDefaultValues = useMemo(() => {
    const today = moment().add(1, 'days').startOf('day').format(SERVER_DATETIME_FORMAT);
    const yesterday = moment().subtract(1, 'days').startOf('day').format(SERVER_DATETIME_FORMAT);

    return {
      created: `${FILTER_PREFIX_ON_VALUE.BETWEEN}${yesterday},${today}`,
      walletType: WALLET_TYPES.NORMAL_WALLET,
    };
  }, []);

  return (
    <WealthListGuesser
      rowClick={false}
      reportInterface={{
        filter: [
          {
            label: 'resources.bet.fields.created',
            source: 'created',
            inputType: 'date-range',
          },
          {
            label: 'resources.bet.fields.updated',
            source: 'updated',
            inputType: 'date-range',
            props: {
              allowClear: true,
            },
          },
          {
            label: 'common.fields.walletType',
            source: 'walletType',
            inputType: 'select',
            choices: Object.values(WALLET_TYPES).map((type) => ({
              id: type,
              name: t(`common.wallet.${type}`),
            })),
            props: {
              resettable: false,
              isRequired: true,
            },
          },
          { label: 'common.fields.nativeId', operator: '$eq', source: 'nativeId' },
          {
            label: 'resources.group.name',
            source: 'group',
            reference: resourceSlug.GROUP,
            inputType: 'reference',
            optionText: ['name'],
            choiceLimit: 25,
          },
          {
            label: 'resources.brand.name',
            source: 'brand',
            reference: resourceSlug.BRAND,
            inputType: 'reference',
            optionText: ['name'],
            choiceLimit: 25,
          },
          { label: 'common.fields.campaignCode', operator: '$eq', source: 'campaignCode' },
          {
            label: 'common.fields.sourceType',
            source: 'sourceType',
            inputType: 'select',
            choices: Object.values(CAMPAIGN_TYPES).map((type) => ({
              id: type,
              name: t(`common.sourceType.${type}`),
            })),
          },
          {
            label: 'resources.currency.name',
            source: 'currency',
            reference: resourceSlug.CURRENCY,
            inputType: 'reference',
            optionText: ['code'],
            choiceLimit: 25,
            filterOn: {},
          },
          {
            label: 'common.fields.transferType',
            source: 'transferType',
            inputType: 'select',
            choices: Object.values(WALLET_TRANSFER_TYPE).map((type) => ({
              id: type,
              name: t(`resources.player.transfer-type.${type}`),
            })),
          },
          {
            label: 'common.fields.status',
            source: 'status',
            inputType: 'select',
            choices: Object.values(CAMPAIGN_PLAYER_STATUS).map((item) => ({
              id: item,
              name: t(`resources.campaign.player-status.${item}`),
            })),
          },
        ],
        response: {
          id: {
            custom: <TextField label={t('common.fields.transactionId')} source="id" sortable={false} />,
            index: 0,
          },
          created: { label: t('resources.bet.fields.created'), format: 'date', index: 1 },
          updated: { label: t('resources.bet.fields.updated'), format: 'date', index: 2, sortable: false },
          playerId: {
            custom: (
              <CustomLinkField
                source="nativeId"
                label={t('common.fields.nativeId')}
                href={(record) => `/#/${resourceSlug.PLAYER}/${record.playerId}/show`}
                title={(record) => record.nativeId}
              />
            ),
            index: 3,
          },
          groupId: {
            label: t('resources.group.name'),
            reference: resourceSlug.GROUP,
            optionText: 'name',
            index: 4,
          },
          brandId: {
            label: t('resources.brand.name'),
            reference: resourceSlug.BRAND,
            optionText: 'name',
            index: 5,
          },
          rewardName: {
            custom: (
              <FunctionField
                label={t('resources.reward.fields.reward-name')}
                render={(record) => record.rewardName || '-'}
              />
            ),
            index: 6,
          },
          campaignId: {
            custom: (
              <CustomLinkField
                source="campaignCode"
                label={t('common.fields.campaignCode')}
                href={(record) => getCampaignDetailsUrl(record.sourceType, record.campaignId)}
                title={(record) => record.campaignCode}
              />
            ),
            index: 7,
          },
          sourceType: {
            custom: (
              <FunctionField
                label={t('common.fields.sourceType')}
                render={(record) => (record.sourceType ? t(`common.sourceType.${record.sourceType}`) : '-')}
              />
            ),
            index: 8,
          },
          currencyCode: {
            custom: (
              <CustomLinkField
                source="currencyCode"
                label={t('common.fields.currencyCode')}
                href={(record) => `/#/${resourceSlug.CURRENCY}/${record.currencyId}/show`}
                title={(record) => record.currencyCode}
              />
            ),
            index: 9,
            sortable: false,
          },
          walletType: {
            custom: (
              <FunctionField
                sortable={false}
                label="common.fields.walletType"
                render={(record) => t(`common.wallet.${record.walletType}`)}
              />
            ),
            index: 10,
          },
          transferType: {
            custom: (
              <FunctionField
                sortable={false}
                label="resources.player.fields.transfer-type"
                render={(record) => <Chip label={t(`resources.player.transfer-type.${record.transferType}`)} />}
              />
            ),
            index: 11,
          },
          amount: {
            custom: <AmountField source="amount" sortable={false} label="common.fields.transactionAmount" />,
            index: 12,
          },
          newBalance: {
            custom: <AmountField source="newBalance" sortable={false} label="common.fields.postBalance" />,
            index: 13,
          },
          status: {
            custom: (
              <FunctionField
                label={t('common.fields.status')}
                render={(record) => <WalletTransactionStatus status={record.status} />}
              />
            ),
            index: 14,
          },
          oldBalance: { hidden: true },
          currencyId: { hidden: true },
          nativeId: { hidden: true },
          campaignCode: { hidden: true },
          maxTotalWinReward: { hidden: true },
          turnoverRequirement: { hidden: true },
        },
      }}
      controllerProps={{
        data: keyBy(walletTransactions, 'id'),
        ids: walletTransactions?.map(({ id }) => id),
      }}
      pagination={<DefaultNoPagePagination resource={resourceSlug.WALLET_TRANSACTION} />}
      filterDefaultValues={filterDefaultValues}
      sort={{
        field: 'created',
        order: 'DESC',
      }}
      extraActions={hasPermission(resourceSlug.WALLET_TRANSACTION, 'update') && <ExtraActions {...props} />}
      {...props}
    />
  );
};

export default WalletTransactionList;

function ExtraActions(props) {
  const { record } = props;

  return (
    <RetryButton
      resource={resourceSlug.WALLET_TRANSACTION}
      playerId={record.playerId}
      transactionId={record.id}
      disabled={
        record.status !== WALLET_TRANSACTION_STATUS.FAILED && record.status !== WALLET_TRANSACTION_STATUS.PROCESSING
      }
    />
  );
}
