/* eslint-disable */
import * as React from 'react';
import { Children, cloneElement, FC } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { ComponentPropType, defaultExporter, useListContext, getListControllerProps, useVersion } from 'ra-core';

import Title, { TitlePropType } from 'ra-ui-materialui/lib/layout/Title';
import DefaultPagination from 'ra-ui-materialui/lib/list/pagination/Pagination';
import BulkDeleteButton from 'ra-ui-materialui/lib/button/BulkDeleteButton';
import BulkActionsToolbar from 'ra-ui-materialui/lib/list/BulkActionsToolbar';
import DefaultActions from 'ra-ui-materialui/lib/list/ListActions';
import Empty from 'ra-ui-materialui/lib/list/Empty';
import ListToolbar from './ListToolbar';

export const ListView = (props) => {
  const {
    actions,
    aside,
    filters,
    bulkActionButtons,
    pagination,
    children,
    className,
    classes: classesOverride,
    component: Content,
    exporter = defaultExporter,
    title,
    empty,
    searchPanel,
    hideExporter,
    additionFilters,
    ...rest
  } = props;
  const controllerProps = getListControllerProps(props); // deprecated, to be removed in v4
  const listContext = useListContext(props);
  const classes = useStyles(props);
  const { defaultTitle, total, loaded, loading, hasCreate, filterValues, selectedIds } = listContext;
  const version = useVersion();

  const renderList = () => (
    <>
      {(filters || actions) && (
        <ListToolbar
          filters={filters}
          {...controllerProps} // deprecated, use ListContext instead, to be removed in v4
          actions={actions}
          exporter={hideExporter ? false : exporter} // deprecated, use ListContext instead, to be removed in v4
          searchPanel={searchPanel}
        />
      )}
      {additionFilters}
      <div className={classes.main}>
        <Content
          className={classnames(classes.content, {
            [classes.bulkActionsDisplayed]: selectedIds.length > 0,
          })}
          key={version}
        >
          {bulkActionButtons !== false && bulkActionButtons && (
            <BulkActionsToolbar {...controllerProps}>{bulkActionButtons}</BulkActionsToolbar>
          )}
          {children &&
            // @ts-ignore-line
            cloneElement(Children.only(children), {
              ...controllerProps, // deprecated, use ListContext instead, to be removed in v4
              hasBulkActions: bulkActionButtons !== false,
            })}
          {pagination && cloneElement(pagination, listContext)}
        </Content>
        {aside && cloneElement(aside, listContext)}
      </div>
    </>
  );

  const shouldRenderEmptyPage = hasCreate && loaded && !loading && total === 0 && !Object.keys(filterValues).length;

  return (
    <div className={classnames('list-page', classes.root, className)} {...sanitizeRestProps(rest)}>
      <Title title={title} defaultTitle={defaultTitle} />
      {shouldRenderEmptyPage && React.isValidElement(empty) ? cloneElement(empty, listContext) : renderList()}
    </div>
  );
};

ListView.propTypes = {
  // @ts-ignore-line
  actions: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  aside: PropTypes.element,
  basePath: PropTypes.string,
  // @ts-ignore-line
  bulkActionButtons: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  children: PropTypes.element,
  className: PropTypes.string,
  classes: PropTypes.object,
  component: ComponentPropType,
  // @ts-ignore-line
  currentSort: PropTypes.shape({
    field: PropTypes.string.isRequired,
    order: PropTypes.string.isRequired,
  }),
  data: PropTypes.any,
  defaultTitle: PropTypes.string,
  displayedFilters: PropTypes.object,
  // @ts-ignore-line
  exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  filterDefaultValues: PropTypes.object,
  filters: PropTypes.element,
  filterValues: PropTypes.object,
  hasCreate: PropTypes.bool,
  hideFilter: PropTypes.func,
  ids: PropTypes.array,
  loading: PropTypes.bool,
  onSelect: PropTypes.func,
  onToggleItem: PropTypes.func,
  onUnselectItems: PropTypes.func,
  page: PropTypes.number,
  // @ts-ignore-line
  pagination: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  perPage: PropTypes.number,
  refresh: PropTypes.func,
  resource: PropTypes.string,
  selectedIds: PropTypes.array,
  setFilters: PropTypes.func,
  setPage: PropTypes.func,
  setPerPage: PropTypes.func,
  setSort: PropTypes.func,
  showFilter: PropTypes.func,
  title: TitlePropType,
  total: PropTypes.number,
  version: PropTypes.number,
};

const DefaultBulkActionButtons = (props) => <BulkDeleteButton {...props} />;

ListView.defaultProps = {
  actions: <DefaultActions />,
  classes: {},
  component: Card,
  bulkActionButtons: <DefaultBulkActionButtons />,
  pagination: <DefaultPagination />,
  empty: <Empty />,
};

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    main: {
      display: 'flex',
    },
    content: {
      marginTop: 0,
      transition: theme.transitions.create('margin-top'),
      position: 'relative',
      flex: '1 1 auto',
      overflow: 'inherit',
    },
    bulkActionsDisplayed: {
      marginTop: -theme.spacing(8),
      transition: theme.transitions.create('margin-top'),
    },
    actions: {
      zIndex: 2,
      display: 'flex',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
    },
    noResults: {
      padding: 20,
    },
  }),
  {
    name: 'RaList',
  },
);

const sanitizeRestProps =
  (props) =>
  ({
    basePath,
    currentSort,
    data,
    defaultTitle,
    displayedFilters,
    filterDefaultValues,
    filterValues,
    hasCreate,
    hasEdit,
    hasList,
    hasShow,
    hideFilter,
    history,
    ids,
    loading,
    loaded,
    location,
    match,
    onSelect,
    onToggleItem,
    onUnselectItems,
    options,
    page,
    permissions,
    perPage,
    resource,
    selectedIds,
    setFilters,
    setPage,
    setPerPage,
    setSort,
    showFilter,
    sort,
    total,
    ...rest
  }) =>
    rest;

export default ListView;
