import React, { memo, useCallback, useMemo } from 'react';
import { useInput, useTranslate } from 'react-admin';
import CountryLanguage from '@ladjs/country-language';
import ctzc from 'country-tz-currency';
import { useSelector } from 'react-redux';
import { useField } from 'react-final-form';
import MultiLangMarkdown from '../../../../../base/components/ra/inputs/MultiLangMarkdown';
import { useMissionForm } from '../../utils';
import { getRatioString } from '../../../../../services/util/image';
import MultiLangFileUploader from '../../../../../base/components/ra/inputs/MultiLangFileUploader';

const TitleAndBannerInputs = memo(() => {
  const t = useTranslate();
  const {
    input: { value: currencyId },
  } = useField('currencyId');
  const { uneditableFields } = useMissionForm();
  const currencyCode = useSelector(({ admin }) => admin.resources.currency.data?.[currencyId]?.code);

  const validate = useCallback(
    (value) => {
      if (!value?.find((item) => item.value || item.fileKey)) {
        return t('ra.validation.required');
      }
      return undefined;
    },
    [t],
  );

  const {
    meta: { error: titleError, touched: titleTouched, submitError: titleSubmitError },
    input: { onChange: handleTitleChange, value: title = [] },
  } = useInput({
    source: 'title',
    validate,
  });

  const showTitleError = (titleSubmitError || titleError) && titleTouched;

  const {
    meta: { error: bannerError, touched: bannerTouched, submitError: bannerSubmitError },
    input: { onChange: handleBannerChange, value: banner = [] },
  } = useInput({
    source: 'bannerConfigs',
    validate,
  });

  const showBannerError = (bannerSubmitError || bannerError) && bannerTouched;

  const langCode = useMemo(() => getLanguageCodeByCurrencyCode(currencyCode), [currencyCode]);

  return (
    <>
      <MultiLangFileUploader
        source="bannerConfigs"
        onChange={handleBannerChange}
        value={banner}
        label={t('resources.mission.fields.banner')}
        uploaderProps={{
          requireDimension: {
            width: 515,
            minWidth: 515,
            height: 175,
            minHeight: 175,
            isRatio: true,
          },
        }}
        isRequired
        langCode={langCode}
        helperText={t('common.text.resolutionRequired', {
          resolution: `${getRatioString(515, 175)} (${t('common.text.pixelDimension', {
            width: 515,
            height: 175,
          })})`,
        })}
        error={!!showBannerError}
        disabled={!!uneditableFields?.includes?.('bannerConfigs')}
      />
      <MultiLangMarkdown
        source="title"
        value={title}
        onChange={handleTitleChange}
        label={t('resources.check-in.fields.title')}
        langCode={langCode}
        isRequired
        error={!!showTitleError}
        helperText={showTitleError ? titleError || titleSubmitError : undefined}
        disabled={!!uneditableFields?.includes?.('title')}
      />
    </>
  );
});

const getLanguageCodeByCurrencyCode = (currencyCode, defaultLang = 'EN') => {
  if (!currencyCode) return defaultLang;
  const [countryCode] =
    Object.entries(ctzc.getAllCountries()).find(([, country]) => country.currencyCode === currencyCode) || [];

  if (!countryCode) return defaultLang;

  return CountryLanguage.getCountryLanguages(countryCode, (err, languages) => {
    if (err) {
      return defaultLang;
    }
    return languages[0]?.iso639_1?.toUpperCase() || defaultLang;
  });
};

export default TitleAndBannerInputs;
