import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Box,
  makeStyles,
  Switch,
  withStyles,
} from '@material-ui/core';
import { Add, Delete, FileCopy, Reorder, Settings } from '@material-ui/icons';
import React, { memo, useState } from 'react';
import { useTranslate } from 'react-admin';
import { useMissionCalender } from '../MissionCalenderContext';
import { MISSION_LEVEL_TYPES } from '../../../../../../../constant/campaign';

const MissionToolbar = memo(({ date, missionLevelType, disabled }) => (
  <Box
    display="flex"
    alignItems="center"
    sx={{
      gap: 4,
    }}
  >
    <AddNewMission disabled={disabled} date={date} />
    <MissionProgressiveMode disabled={disabled} date={date} missionLevelType={missionLevelType} />
    <MissionSettings disabled={disabled} missionLevelType={missionLevelType} date={date} />
  </Box>
));

const AddNewMission = memo(({ disabled, date }) => {
  const classes = useStyles();
  const t = useTranslate();
  const { changeAction } = useMissionCalender();

  return (
    <Tooltip title={t('resources.mission.add-new-mission')} placement="top">
      <div>
        <IconButton
          className={classes.iconButton}
          onClick={() =>
            changeAction({
              missionDate: date,
              action: 'addMission',
            })
          }
          disabled={disabled}
        >
          <Add />
        </IconButton>
      </div>
    </Tooltip>
  );
});

const MissionProgressiveMode = memo(({ disabled, date, missionLevelType }) => {
  const t = useTranslate();
  const { onChange, missionList } = useMissionCalender();

  const handleModeChange = (event) => {
    const newMissionLevelType = event.target.checked
      ? MISSION_LEVEL_TYPES.PROGRESSIVE
      : MISSION_LEVEL_TYPES.ALL_AT_ONCE;
    const newMissionList = {
      ...missionList,
    };

    if (newMissionList[date]) {
      newMissionList[date].missionLevelType = newMissionLevelType;
    } else {
      newMissionList[date] = {
        date,
        missionLevelType,
        missionTasks: [],
      };
    }

    onChange(newMissionList);
  };

  return (
    <Tooltip title={t(`resources.mission.mission-level-type.${missionLevelType}`)} placement="top">
      <div>
        <ProgressiveSwitch
          disabled={disabled}
          checked={missionLevelType === MISSION_LEVEL_TYPES.PROGRESSIVE}
          onChange={handleModeChange}
        />
      </div>
    </Tooltip>
  );
});

const MissionSettings = memo(({ disabled, missionLevelType, date }) => {
  const classes = useStyles();
  const t = useTranslate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { onChange, missionList, changeAction } = useMissionCalender();

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleRemoveAllTasks = () => {
    const newMissionList = {
      ...missionList,
    };
    newMissionList[date].missionTasks = [];
    onChange(newMissionList);
  };

  return (
    <>
      <IconButton onClick={handleOpenMenu} className={classes.iconButton}>
        <Settings />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleCloseMenu} getContentAnchorEl={null}>
        <MenuButton
          onClick={() => {
            handleCloseMenu();
            changeAction({
              missionDate: date,
              action: 'copyMissions',
            });
          }}
          icon={<FileCopy />}
          label={t('resources.mission.copy-to-other-days')}
          disabled={!missionList?.[date]?.missionTasks?.length || disabled}
        />
        {missionLevelType === MISSION_LEVEL_TYPES.PROGRESSIVE && (
          <MenuButton
            onClick={() => {
              handleCloseMenu();
              changeAction({
                missionDate: date,
                action: 'reorderMissions',
              });
            }}
            disabled={!missionList?.[date]?.missionTasks?.length || disabled}
            icon={<Reorder />}
            label={t('resources.mission.reorder-missions')}
          />
        )}
        <MenuButton
          onClick={() => {
            handleCloseMenu();
            handleRemoveAllTasks();
          }}
          disabled={!missionList?.[date]?.missionTasks?.length || disabled}
          className={classes.errorButton}
          icon={<Delete />}
          label={t('resources.mission.clear-all-missions')}
        />
      </Menu>
    </>
  );
});

const MenuButton = ({ icon, label, ...props }) => {
  const classes = useStyles();

  return (
    <MenuItem
      classes={{
        root: classes.menuItem,
      }}
      {...props}
    >
      <ListItemIcon className={classes.menuIcon}>{icon}</ListItemIcon>
      <ListItemText className={classes.menuLabel}>{label}</ListItemText>
    </MenuItem>
  );
};

export default MissionToolbar;

const useStyles = makeStyles((theme) => ({
  iconButton: {
    fontSize: '1.2rem',
    padding: 2,
    height: 'fit-content',
    '& svg': {
      fontSize: 'inherit',
    },
  },
  errorButton: {
    color: theme.palette.error.main,
  },
  menuItem: {
    padding: '0px 12px',
  },
  menuLabel: {
    color: 'inherit',
    '&>*': {
      fontSize: '0.85rem',
    },
  },
  menuIcon: {
    fontSize: '1rem',
    color: 'inherit',
    minWidth: 'fit-content',
    marginRight: 4,
    '& svg': {
      fontSize: 'inherit',
    },
  },
}));

const ProgressiveSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.tertiary.dark,
        borderColor: theme.palette.tertiary.dark,
      },
      '&[class*="disabled"]': {
        opacity: 0.8,
        '& + $track': {
          backgroundColor: theme.palette.tertiary.main,
          borderColor: theme.palette.tertiary.main,
        },
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);
