import { Box, Button, IconButton, makeStyles } from '@material-ui/core';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { FunctionField, TextField, SelectField, useTranslate } from 'react-admin';
import { Add, Search } from '@material-ui/icons';
import keyBy from 'lodash/keyBy';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import resourceSlug from '../../../../../../../constant/resource-slug';
import CustomizedFilterWrapper from '../../../../../../../base/components/customized/CustomizedFilterWrapper';
import { SelectInput, TextInput } from '../../../../../../../base/components/ra/inputs';
import { REWARD_TYPES } from '../../../../../../../constant/reward';
import MuiDialog from '../../../../../../../base/components/mui/MuiDialog';
import { CustomizedNoPageList } from '../../../../../../../base/components/customized/CustomizedNoPageList';

const AddRewardModal = memo(({ onChange, defaultRewards, currencyId, className }) => {
  const t = useTranslate();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Button startIcon={<Add />} onClick={() => setOpen(true)} className={clsx(classes.box, className)}>
        {t('ra.action.add')}
      </Button>
      {open && (
        <RewardModal
          open
          onClose={handleClose}
          onChange={onChange}
          defaultRewards={defaultRewards}
          currencyId={currencyId}
        />
      )}
    </>
  );
});

AddRewardModal.propTypes = {
  onChange: PropTypes.func,
  currencyId: PropTypes.string.isRequired,
};

export default AddRewardModal;

const RewardModal = memo(({ open, onClose, onChange, defaultRewards, currencyId }) => {
  const t = useTranslate();
  const [selectedIds, setSelectedIds] = useState([]);
  const { list: rewardList } = useSelector((state) => state.list[resourceSlug.REWARD] || {});

  const defaultRewardIds = useMemo(() => defaultRewards?.map?.((reward) => reward.rewardId) || [], [defaultRewards]);

  const handleSave = useCallback(() => {
    const allRewards = keyBy(
      Object.values(rewardList).reduce((acc, current) => acc.concat(current.data), []),
      'id',
    );
    onChange(
      selectedIds.map((id) => ({
        rewardId: id,
        rewardName: allRewards[id].rewardName,
      })),
    );
    onClose();
  }, [rewardList, selectedIds]);

  const handleToggleReward = useCallback(
    (rewardId) => {
      const index = selectedIds.indexOf(rewardId);
      if (index > -1) {
        setSelectedIds([...selectedIds.slice(0, index), ...selectedIds.slice(index + 1)]);
      } else {
        setSelectedIds([...selectedIds, rewardId]);
      }
    },
    [selectedIds],
  );

  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      title={t('resources.reward.name')}
      actions={
        <Button variant="contained" onClick={handleSave} disableElevation disabled={!selectedIds?.length}>
          {t('ra.action.add')}
        </Button>
      }
    >
      <Box
        component={CustomizedNoPageList}
        sx={{
          "& [class*='MuiTableContainer']": {
            maxHeight: '300px !important',
          },
          "& [class*='MuiToolbar']": {
            width: '100%',
          },
        }}
        actionsProps={{
          overrideDefault: true,
        }}
        actions={undefined}
        resource={resourceSlug.REWARD}
        basePath="/reward"
        disabledAutoRefetch
        syncWithLocation={false}
        filter={{
          isEnabled: true,
          currencyId,
          'id||$notin||': defaultRewardIds,
        }}
        filters={<RewardFilterWrapper />}
        selectedIds={selectedIds}
        onSelect={setSelectedIds}
        onToggleItem={handleToggleReward}
        onUnselectItems={() => setSelectedIds([])}
        bulkActionButtons
        paginationProps={{
          rowsPerPageOptions: [25],
        }}
        loadingProps={{
          nbFakeLines: 5,
        }}
        rowClick={false}
      >
        <TextField source="rewardName" label="resources.reward.fields.name" sortable={false} />
        <SelectField
          source="rewardType"
          label="resources.reward.fields.reward-type"
          choices={Object.values(REWARD_TYPES).map((type) => ({
            id: type,
            name: t(`resources.reward.reward-type.${type}`),
          }))}
          sortable={false}
        />
        <FunctionField
          label="resources.reward.fields.expiry"
          render={(record) =>
            t(`resources.reward.expiry.${record.validPeriodUnit}`, {
              smart_count: record.validPeriod,
            })
          }
        />
      </Box>
    </MuiDialog>
  );
});

const RewardFilterWrapper = () => {
  const t = useTranslate();
  const classes = useStyles();

  const rewardTypeChoices = useMemo(
    () =>
      Object.values(REWARD_TYPES).map((type) => ({
        id: type,
        name: t(`resources.reward.reward-type.${type}`),
      })),
    [t],
  );

  return (
    <CustomizedFilterWrapper
      searchButton={
        <IconButton color="primary" className={classes.searchButton}>
          <Search />
        </IconButton>
      }
      className={classes.filterWrapper}
    >
      <TextInput source="rewardName||$cont" label="resources.reward.fields.reward-name" resettable helperText={false} />
      <SelectInput
        source="rewardType"
        label="resources.reward.fields.reward-type"
        choices={rewardTypeChoices}
        helperText={false}
        resettable
      />
    </CustomizedFilterWrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  box: {
    width: 120,
    aspectRatio: 1,
    border: `1px dashed ${theme.palette.grey[300]}`,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
  },
  filterWrapper: {
    '& form': {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  searchButton: {
    backgroundColor: `${theme.palette.grey[300]} !important`,
    borderRadius: 8,
    marginTop: -8,
    padding: '8px !important',
  },
}));
