/* eslint-disable */
import React, { useMemo } from 'react';
import moment from 'moment';
import { FILTER_PREFIX_ON_VALUE, SERVER_DATETIME_FORMAT } from '../../constant';
import { AmountField, DateField } from '../../base/components/ra/fields';
import BetBonusFilters from './components/BetBonusFilters';
import resourceSlug from '../../constant/resource-slug';
import { CustomizedNoPageList } from '../../base/components/customized/CustomizedNoPageList';
import CustomLinkField from '../../base/components/ra/fields/customLink.field';
import { transformIdValue } from '../../services/util/stringUtil';
import { ReferenceField, TextField, useLocale, NumberField, FunctionField, useTranslate } from 'react-admin';
import { Chip } from '@material-ui/core';

const BetBonusList = () => {
  const t = useTranslate();
  const locale = useLocale();
  const filterDefaultValues = useMemo(() => {
    const today = moment().add(1, 'days').startOf('day').format(SERVER_DATETIME_FORMAT);
    const yesterday = moment().subtract(1, 'days').startOf('day').format(SERVER_DATETIME_FORMAT);

    return {
      created: `${FILTER_PREFIX_ON_VALUE.BETWEEN}${yesterday},${today}`,
    };
  }, []);

  return (
    <CustomizedNoPageList
      filters={<BetBonusFilters />}
      filterDefaultValues={filterDefaultValues}
      resource={resourceSlug.BET_BONUS}
      basePath="/bet-bonus"
    >
      <CustomLinkField
        source="id"
        label="common.fields.id"
        href={(record) => `/#/${resourceSlug.BET_BONUS}/${record.id}/show`}
        title={(record) => transformIdValue(record.id)}
      />
      <DateField source="created" showSecond />
      <DateField source="updated" sortable={false} showSecond />
      <DateField source="endTime" sortable={false} showSecond />
      <ReferenceField
        source="groupId"
        label="resources.bet.fields.groupId"
        reference="group"
        sortable={false}
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="brandId"
        label="resources.bet.fields.brandId"
        reference="brand"
        sortable={false}
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="currencyId"
        label="resources.bet.fields.currency"
        reference="currency"
        sortable={false}
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="playerId"
        label="resources.bet.fields.player"
        reference="player"
        sortable={false}
        link="show"
      >
        <TextField source="nativeId" />
      </ReferenceField>
      <ReferenceField source="gameId" label="resources.bet.fields.game" reference="game" sortable={false} link="show">
        <TextField source={`name.${locale}`} />
      </ReferenceField>
      <FunctionField
        sortable={false}
        label="resources.bet.fields.betStatus"
        render={(record) => <Chip label={t(`common.betStatus.${record.betStatus}`)} />}
      />
      <FunctionField
        sortable={false}
        label="resources.bet.fields.betType"
        render={(record) => <Chip label={t(`common.betType.${record.betType}`)} />}
      />
      <NumberField source="betSize" sortable={false} label="resources.bet.fields.betSize" />
      <NumberField source="betLevel" sortable={false} label="resources.bet.fields.betLevel" />
      <AmountField source="amount" sortable={false} label="resources.bet.fields.amount" />
      <AmountField source="earn" sortable={false} label="resources.bet.fields.earn" />
      <AmountField source="playerBalance" sortable={false} label="resources.bet.fields.playerBalance" />
      <AmountField source="endingBalance" sortable={false} label="resources.bet.fields.endingBalance" />
      <TextField source="channel" sortable={false} label="resources.bet.fields.channel" />
    </CustomizedNoPageList>
  );
};

export default BetBonusList;
