import { Box, makeStyles, MenuItem, Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { FunctionField, SimpleShowLayout, useTranslate } from 'react-admin';
import union from 'lodash/union';
import PropTypes from 'prop-types';
import MDEditor from '@uiw/react-md-editor';
import { Empty, Image } from 'antd';
import MuiSelect from '../../../../../base/components/mui/MuiSelect';
import { useLanguageList } from '../../../../../base/hooks/useLanguageList';
import { getImageUrl } from '../../../../../services/util/image';

const Description = ({ termAndCondition, title, bannerConfigs }) => {
  const t = useTranslate();
  const [language, setLanguage] = useState('');
  const { data: allLanguages } = useLanguageList();
  const classes = useStyles();

  const supportLanguages = useMemo(() => {
    const termLangs = termAndCondition?.map((item) => item.langCode);
    const titleLangs = title?.map((item) => item.langCode);
    const bannerLangs = bannerConfigs?.map((item) => item.langCode);

    return union(termLangs, titleLangs, bannerLangs);
  }, [termAndCondition, title, bannerConfigs]);

  useEffect(() => {
    if (supportLanguages?.length) {
      setLanguage(supportLanguages[0]);
    }
  }, [supportLanguages]);

  return (
    <Box px={12.5}>
      <Box
        component={Typography}
        variant="h4"
        sx={{
          margin: '10px 0 !important',
        }}
      >
        {t('resources.check-in.description')}
      </Box>
      <Box component={MuiSelect} width={200} value={language} onChange={(e) => setLanguage(e.target.value)}>
        {supportLanguages?.map((lang) => (
          <MenuItem key={lang} value={lang}>
            {allLanguages?.find?.((item) => item.code === lang)?.name}
          </MenuItem>
        ))}
      </Box>
      <SimpleShowLayout className={classes.section}>
        <FunctionField
          label="resources.check-in.fields.banner"
          render={(record) => {
            const src = record.bannerConfigs?.find?.((item) => item?.langCode === language)?.fileKey || '';
            return <BannerImage src={src} />;
          }}
        />
        <FunctionField
          label="resources.check-in.fields.title"
          render={(record) => (
            <MDEditor.Markdown source={record.title?.find?.((item) => item?.langCode === language)?.value || ''} />
          )}
        />
        <FunctionField
          label="resources.check-in.fields.terms-and-conditions"
          render={(record) => (
            <MDEditor.Markdown
              source={record.termAndCondition?.find?.((item) => item?.langCode === language)?.value || ''}
            />
          )}
        />
      </SimpleShowLayout>
    </Box>
  );
};

export const BannerImage = ({ src }) => {
  const classes = useStyles();

  return src ? (
    <Image src={getImageUrl(src)} className={classes.bannerImage} wrapperClassName={classes.bannerWrapper} />
  ) : (
    <Empty description="" />
  );
};

Description.propTypes = {
  termAndCondition: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      langCode: PropTypes.string,
    }),
  ),
  title: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      langCode: PropTypes.string,
    }),
  ),
};

export default Description;

const useStyles = makeStyles({
  section: {
    padding: 0,
    marginTop: 12,
    '& .ra-field': {
      width: '100%',
      background: '#F0F1F2',
      borderRadius: '4px',
      padding: '10px 16px',
      wordBreak: 'break-all',
      marginBottom: '16px',
    },
  },
  bannerImage: {
    maxHeight: '8.5rem',
    objectFit: 'contain',
    borderRadius: 6,
  },
  bannerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 6,
  },
});
