import React, { memo, useMemo } from 'react';
import { SimpleForm, useInput, useTranslate } from 'react-admin';
import { List, ListItem, ListItemText, ListItemIcon, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import uniqueId from 'lodash/uniqueId';
import clsx from 'clsx';
import { Reorder } from '@material-ui/icons';
import { useMissionCalender } from '../MissionCalenderContext';
import DrawerToolBar from '../../../../../../../base/components/ra/drawers/DrawerToolBar';
import { formatNumber } from '../../../../../../../services/util/formatNumber';
import MissionDrawer from './MissionDrawer';

const MissionOrderDrawer = memo(({ open, onClose }) => {
  const t = useTranslate();
  const { missionList, missionDate } = useMissionCalender();

  const initialData = useMemo(
    () =>
      open
        ? {
            ...missionList?.[missionDate],
            date: missionDate,
            missionTasks: missionList?.[missionDate]?.missionTasks?.map((task) => ({
              id: uniqueId('mission'),
              ...task,
            })),
          }
        : {},
    [open],
  );

  return (
    <MissionDrawer open={open} onClose={onClose} title={t('resources.mission.reorder-missions')}>
      {open && <MissionOrderForm initialData={initialData} />}
    </MissionDrawer>
  );
});

export default MissionOrderDrawer;

MissionOrderDrawer.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

const MissionOrderForm = memo(({ initialData }) => {
  const { onChange, missionDate, missionList, clearAction } = useMissionCalender();

  const handleReorder = (data) => {
    const newMissionList = {
      ...missionList,
    };
    newMissionList[missionDate].missionTasks = data?.missionTasks;

    onChange(newMissionList);
    clearAction();
  };

  return (
    <SimpleForm initialValues={initialData} toolbar={<DrawerToolBar />} save={handleReorder} record={initialData}>
      <DraggableMissionList />
    </SimpleForm>
  );
});

const DraggableMissionList = memo(() => {
  const classes = useStyles();
  const {
    input: { onChange, value: missions },
  } = useInput({
    source: 'missionTasks',
  });

  const handleDragEnd = ({ destination, source }) => {
    // dropped outside the list
    if (!destination) return;

    const newMissionTasks = reorder(missions, source.index, destination.index);

    onChange(newMissionTasks);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="mission-droppable-list">
        {(provided) => (
          <List ref={provided.innerRef} className={classes.list} {...provided.droppableProps}>
            {missions?.map((item, index) => (
              <DraggableMissionItem item={item} index={index} key={item.id} />
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
});

const DraggableMissionItem = ({ item, index }) => {
  const t = useTranslate();
  const label = useMemo(
    () =>
      t(`resources.mission.mission-type-title.${item?.missionType}`, {
        smart_count: formatNumber('en', item?.target),
      }),
    [t, item],
  );
  const classes = useStyles();

  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={clsx(classes.draggableMissionItem, snapshot.isDragging && classes.draggingMissionItem)}
        >
          <ListItemIcon>
            <Reorder />
          </ListItemIcon>
          <ListItemText primary={label} />
        </ListItem>
      )}
    </Draggable>
  );
};

const useStyles = makeStyles((theme) => ({
  draggableMissionItem: {
    backgroundColor: theme.palette.tertiary[100],
    borderRadius: 8,
    "&>[class*='Icon']": {
      fontSize: '1.5rem',
      color: theme.palette.tertiary.dark,
      minWidth: 'fit-content',
      marginRight: 8,
      '& svg': {
        fontSize: 'inherit',
      },
    },
    "&>[class*='Text']": {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'block',
    },
  },
  draggingMissionItem: {
    opacity: 0.6,
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
  },
}));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
