/* eslint-disable import/no-cycle */
import React, { useEffect, memo } from 'react';
import { SimpleForm, useTranslate } from 'react-admin';
import { useForm } from 'react-final-form';
import { Box, Typography } from '@material-ui/core';
import pick from 'lodash/pick';
import CheckInFormToolbar from '../CheckInFormToolbar';
import Rewards from './Rewards';
import Conditions from './Conditions';
import { useCheckInForm } from '../../utils';

const SettingsStep = () => {
  const { isSaving, updateData } = useCheckInForm();

  return (
    <SimpleForm
      toolbar={null}
      saving={isSaving}
      save={(values) => {
        const settingsInfo = pick(values, [
          'type',
          'requiredCheckInDays',
          'fromCheckInTime',
          'toCheckInTime',
          'streakRepeatable',
          'destinationTransferWallet',
          'rewardRepeatable',
          'rewardConfig',
        ]);

        updateData(settingsInfo);
      }}
    >
      <SettingsForm />
      <CheckInFormToolbar />
    </SimpleForm>
  );
};

const SettingsForm = memo(() => {
  const { initialValues } = useCheckInForm();
  const { initialize } = useForm();
  const t = useTranslate();

  useEffect(() => {
    if (initialValues) {
      initialize(initialValues);
    }
  }, [initialValues]);

  return (
    <>
      <Box
        component={Typography}
        variant="h3"
        sx={{
          marginTop: '-12px !important',
        }}
      >
        {t('resources.check-in.conditions')}
      </Box>
      <Conditions />
      <Box
        component={Typography}
        variant="h3"
        sx={{
          marginTop: '24px !important',
          marginBottom: '12px !important',
        }}
      >
        {t('resources.check-in.rewards')}
      </Box>
      <Rewards />
    </>
  );
});

export default memo(SettingsStep);
