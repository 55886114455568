import { useDataProvider } from 'react-admin';
import { useEffect, useState } from 'react';
import resourceSlug from '../../../constant/resource-slug';

const useGetRewardDetails = (rewardId) => {
  const dataProvider = useDataProvider();

  const [reward, setReward] = useState();
  const [loading, setLoading] = useState(!!rewardId);

  const getRewardDetails = async () => {
    try {
      setLoading(true);
      const { data } = await dataProvider.getOne(resourceSlug.REWARD, {
        id: rewardId,
      });

      if (data) {
        setReward(data);
      }
    } catch (err) {
      console.error('[ERROR] Get Reward Details', err?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (rewardId) {
      getRewardDetails(rewardId);
    }
  }, [rewardId]);

  return {
    reward,
    loading,
  };
};

export default useGetRewardDetails;
