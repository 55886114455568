import { CACHE_LIST_BY_PAGE, UPDATE_CURRENT_LIST_PAGE } from '../root.actions';

export const cacheListByPage = ({ data, page, resource }) => ({
  type: CACHE_LIST_BY_PAGE,
  payload: {
    data,
    resource,
    page,
  },
});

export const updateCurrentListPage = ({ page, resource }) => ({
  type: UPDATE_CURRENT_LIST_PAGE,
  payload: {
    resource,
    page,
  },
});
