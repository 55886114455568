import React, { useEffect, useMemo, useState } from 'react';
import { Tabs, Tab as MuiTab, Box, makeStyles } from '@material-ui/core';
import { useRecordContext, useTranslate } from 'react-admin';
import BonusWallet from './BonusWallet';
import MainWallet from './MainWallet';

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    borderBottom: `2px solid ${theme.palette.grey[200]}`,
  },
  tabRoot: {
    "&[class*='selected'] $tabWrapper": {
      color: theme.palette.primary.main,
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      fontWeight: 700,
    },
    padding: 0,
  },
  tabWrapper: {
    textTransform: 'capitalize',
    color: 'black',
    fontWeight: 600,
    padding: '10px 32px',
  },
}));

const Tab = (props) => {
  const classes = useStyles();

  return (
    <MuiTab
      classes={{
        root: classes.tabRoot,
        wrapper: classes.tabWrapper,
      }}
      {...props}
    />
  );
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
    value: index,
  };
}

const PlayerWallets = () => {
  const t = useTranslate();
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);
  const record = useRecordContext();

  const [hasMainWallet, hasBonusWallet] = useMemo(
    () => [!!record?.wallets?.find((wallet) => !!wallet?.cash), !!record?.wallets?.find((wallet) => !!wallet?.bonus)],
    [record],
  );

  useEffect(() => {
    if (!record) return;
    let activeTab = 0;
    if (!hasMainWallet) {
      activeTab = 1;
    }
    setCurrentTab(activeTab);
  }, [hasMainWallet]);

  if (!hasMainWallet && !hasBonusWallet) {
    return null;
  }

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <Tabs
          value={currentTab}
          classes={{
            flexContainer: classes.flexContainer,
          }}
        >
          <Box
            component={Tab}
            label={t('resources.player.main-wallet')}
            onClick={() => setCurrentTab(0)}
            hidden={!hasMainWallet}
            {...a11yProps(0)}
          />
          <Box
            component={Tab}
            label={t('resources.player.bonus-wallet')}
            onClick={() => setCurrentTab(1)}
            hidden={!hasBonusWallet}
            {...a11yProps(1)}
          />
          {/* Temporary hide this tab
          {hasBonusWallet && (
            <Tab
              label={t('resources.player.conversion-history')}
              onClick={() => setCurrentTab(2)}
              {...a11yProps(2)}
            />
          )} */}
        </Tabs>
      </Box>
      {currentTab === 0 && <MainWallet />}
      {currentTab === 1 && <BonusWallet />}
      {/* Temporary hide this tab
      {currentTab === 2 && <ConversionHistoryList />} */}
    </Box>
  );
};

export default PlayerWallets;
