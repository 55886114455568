import { Box, alpha } from '@material-ui/core';
import { useLocale, useTranslate } from 'react-admin';
import localeEn from 'antd/es/date-picker/locale/en_US';
import localeZhCn from 'antd/es/date-picker/locale/zh_CN';
import localeTh from 'antd/es/date-picker/locale/th_TH';
import moment from 'moment';
import React from 'react';
import { range } from 'lodash';
import { DATETIME_DISPLAY_WITH_HOURS } from '../../../../../constant';
import AntdDatePicker from '../../../../../base/components/antd/AntdDatePicker';

const getAntDesignLocale = (locale) => {
  switch (locale) {
    case 'cn':
      return localeZhCn;
    case 'th':
      return localeTh;
    case 'en':
    default:
      return localeEn;
  }
};

const inputContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '40px',
  border: '1px solid #0000003B',
  borderRadius: '4px',
  background: '#FFFFFF',
  marginTop: '8px',
  position: 'relative',
};

const labelStyles = {
  position: 'absolute',
  top: -7,
  left: 9,
  padding: '0 6px',
  maxWidth: '1000px',
  background: '#FFFFFF',
  color: alpha('#000000', 0.6),
  fontSize: '12px',
  fontFamily: 'Normal',
  fontWeight: 400,
  lineHeight: 1,
};

const IssuedDate = (props) => {
  const { campaignData } = props;
  const t = useTranslate();
  const locale = useLocale();
  const antDesignLocale = getAntDesignLocale(locale);

  const getDisabledDate = (current) => {
    if (current == null) {
      return null;
    }

    const isProcessedCampaign = campaignData?.startTime && moment(campaignData?.startTime).isAfter(moment());

    const startDate = isProcessedCampaign ? moment(campaignData?.startTime) : moment();

    const isBeforeToday = moment(current).isBefore(moment(startDate).startOf('day'));

    const isAfterEndDate = moment(current).isAfter(moment(campaignData.endTime), 'day');

    return isBeforeToday || isAfterEndDate;
  };

  const getDisabledTime = (current) => {
    const isProcessedCampaign = campaignData?.startTime && moment(campaignData?.startTime).isAfter(moment());

    const startDate = isProcessedCampaign ? moment(campaignData?.startTime) : moment();
    const startHour = isProcessedCampaign ? moment(campaignData?.startTime).hours() : moment().hours();

    if (current && moment(current).isSame(startDate, 'day')) {
      return {
        disabledHours: () => range(0, startHour),
      };
    }

    if (current && moment(current).isSame(campaignData.endTime, 'day')) {
      return {
        disabledHours: () => range(moment(campaignData?.endTime).hours() + 1, 24),
      };
    }

    return {
      disabledHours: () => [],
    };
  };

  return (
    <Box sx={inputContainerStyles}>
      <Box component="label" sx={labelStyles}>
        {t('resources.campaign.fields.scheduled-date')}
      </Box>

      <AntdDatePicker
        showTime={{
          showHour: true,
          defaultValue: moment('00:00', 'HH:mm'),
        }}
        disabledDate={getDisabledDate}
        disabledTime={getDisabledTime}
        bordered={false}
        suffixIcon={null}
        format={DATETIME_DISPLAY_WITH_HOURS}
        locale={antDesignLocale}
        popupStyle={{
          zIndex: 9999,
        }}
        showNow={false}
        {...props}
      />
    </Box>
  );
};

export default IssuedDate;
