/* eslint-disable import/no-cycle, no-use-before-define  */
import React, { useEffect, useState } from 'react';
import {
  FunctionField,
  RecordContext,
  RecordContextProvider,
  TextField,
  useDataProvider,
  useTranslate,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { Card, makeStyles } from '@material-ui/core';
import ReactJson from 'react-json-view';
import resourceSlug from '../../../../constant/resource-slug';
import { DateField } from '../../../../base/components/ra/fields';
import { useMissionNavigation, useTableListStyles } from '../utils';
import MissionDetailsTabs, { MISSION_TABS } from './MissionDetailsTabs';
import ArrayWithPaginationField from '../../../../base/components/ra/fields/arrayWithPagination.field';
import MuiDrawer from '../../../../base/components/mui/MuiDrawer';
import { ShowSection } from '../../../../base/components/guesser/wealth-show.guesser';
import Labeled from '../../../../base/components/ra/labeled';
import CommonWrapper from '../../../../base/components/reverse-integration/common-wrapper';

const MissionChangeLog = () => {
  const { id } = useParams();
  const classes = useTableListStyles();
  const dataProvider = useDataProvider();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState();

  useMissionNavigation();

  const handleFetchChangeLog = async (id) => {
    try {
      const { data } = await dataProvider.getCustomList(`${resourceSlug.MISSION}/${id}/change-log`);

      setData({
        data: data?.data?.map((item, idx) => ({
          id: idx,
          ...item,
        })),
      });
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!id) return;
    handleFetchChangeLog(id);
  }, [id]);

  return (
    <Card>
      <MissionDetailsTabs currentTab={MISSION_TABS.CHANGE_LOG}>
        <CommonWrapper className={classes.wrapper}>
          <RecordContextProvider value={data}>
            <ArrayWithPaginationField
              source="data"
              label={false}
              loading={loading}
              loaded={!!data}
              rowClick={(_, __, record) => setDetails(record)}
            >
              <DateField source="created" label="resources.reward.fields.created" sortable={false} />
              <TextField source="createdBy" label="resources.reward.fields.actor" />
              <TextField source="action" label="resources.reward.fields.action" sortable={false} />
            </ArrayWithPaginationField>
          </RecordContextProvider>
        </CommonWrapper>

        <ChangeLogDetailDrawer data={details} onClose={() => setDetails(undefined)} open={!!details} />
      </MissionDetailsTabs>
    </Card>
  );
};

const ChangeLogDetailDrawer = ({ data, onClose, open }) => {
  const t = useTranslate();
  const classes = useStyles();

  return (
    <MuiDrawer
      open={open}
      anchor="right"
      onClose={onClose}
      title={t('resources.reward.change-log-details')}
      classes={{
        paper: classes.paper,
      }}
    >
      <RecordContext.Provider value={data}>
        <ShowSection
          classes={{
            root: classes.row,
          }}
          className={classes.detailsWrapper}
          title={null}
        >
          <Labeled label="resources.reward.fields.created">
            <DateField source="created" />
          </Labeled>
          <TextField source="createdBy" label="resources.reward.fields.actor" />
          <TextField source="action" label="resources.reward.fields.action" sortable={false} />
          <FunctionField
            label="resources.reward.fields.payload"
            render={(record) => (
              <ReactJson src={record.payload} name={null} collapsed enableClipboard displayDataTypes={false} />
            )}
          />
        </ShowSection>
      </RecordContext.Provider>
    </MuiDrawer>
  );
};

const useStyles = makeStyles({
  row: {
    display: 'flex',
    flexDirection: 'column',
    gap: 18,
    '& .ra-field': {
      width: '100%',
    },
  },
  paper: {
    width: '29rem',
  },
  detailsWrapper: {
    padding: 0,
  },
});

export default MissionChangeLog;
