import React from 'react';
import { useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useReportContext } from '../ReportLayout';
import { AutocompleteInput, ReferenceInput } from '../../ra/inputs';
import FormField from './FormField';
import { TooltipForDisabledInput } from '../../custom-tooltip';
import { useAuthUser } from '../../../hooks';

const BrandInput = ({ source, tooltipProps, ...otherProps }) => {
  const { currentFilters } = useReportContext();
  const user = useAuthUser();
  const form = useForm();

  const translate = useTranslate();

  const group = currentFilters?.group || user?.group?.id;

  const hasNoGroup = !group;

  return (
    <>
      {!hasNoGroup && (
        <OnChange name="group">
          {() => {
            form.change(source, null);
          }}
        </OnChange>
      )}
      <FormField name={source}>
        {() => (
          <>
            <TooltipForDisabledInput
              title={translate('ra.message.pleaseSelectFirst', {
                smart_name: translate('resources.group.name'),
              })}
              placement="top-start"
              showMessage={hasNoGroup}
              {...tooltipProps}
            >
              <ReferenceInput
                label={translate('resources.brand.name')}
                source={source}
                reference="brand"
                filter={{
                  'group.id': group,
                }}
                filterToQuery={(value) => ({
                  'name||$cont': value,
                })}
                sort={{
                  field: 'name',
                  order: 'ASC',
                }}
                disabled={hasNoGroup}
                shouldRenderSuggestions={() => !hasNoGroup}
                {...otherProps}
              >
                <AutocompleteInput optionText="name" resettable />
              </ReferenceInput>
            </TooltipForDisabledInput>
          </>
        )}
      </FormField>
    </>
  );
};

BrandInput.propTypes = {
  source: PropTypes.string,
  tooltipProps: PropTypes.object,
};

BrandInput.defaultProps = {
  source: 'brand',
  tooltipProps: {},
};

export default BrandInput;
