/* eslint-disable react/destructuring-assignment, react/prop-types */
import { FormDataConsumer, required, regex } from 'ra-core';
import React, { useEffect } from 'react';
import { omit } from 'lodash';
import { usePermissions } from 'react-admin';
import WealthEditGuesser from '../../base/components/guesser/wealth-edit.guesser';
import WealthInputWrapper from '../../base/components/guesser/wealth-input-wrapper';
import { TextInput, BooleanInput, SelectInput } from '../../base/components/ra/inputs';
import { useAuthUser, useEnumOptions } from '../../base/hooks';
import { checkCheatModeEnv, sanitizeObject } from '../../services/util';
import { REGEX_URL } from '../../services/util/validate/regularExpression';
import CustomSelectConfig, { customFieldsConfig } from '../wallet-integration/components/customSelectConfig';
import { THIN_ENABLED_CURRENCIES_FIELD } from '../../base/components/currencies-config/thin-enabled-currencies-config';
import { CURRENCIES_FIELD_KEY } from './components/currencies-config-wrapper';
import PlayerLimitWrapper, { PLAYER_LIMIT_FIELD_KEY } from './components/player-limit';
import LocalStorage, { REDIRECT_KEY } from '../../services/local-storage';
import CurrenciesConfigForBrandEdit from './components/currencies-config-for-edit-brand';
import GetPlayerTokenUrlInput, { GET_PLAYER_TOKEN_URL_FIELD_KEY } from './components/get-player-token-url-input';
import resourceSlug from '../../constant/resource-slug';
import FeaturesInput from './components/features.input';
import AutocompleteFreeSpins from './components/autocomplete-free-spin';
import TransferBonusUrl, { TRANSFER_BONUS_URL_FIELD_KEY } from './components/transfer-bonus-url';

const BrandEdit = (props) => {
  const requiredFieldsSeamless = ['payOutUrl', 'placeBetUrl'];
  const enumImplementation = useEnumOptions('wallet-integration', 'implementation');
  const user = useAuthUser();
  const { permissions } = usePermissions();
  const hasRegulationReadPermission =
    user?.superman ||
    (Array.isArray(permissions) && !!permissions?.find((item) => item.resource.name === resourceSlug.REGULATION)?.read);

  const isCheatMode = checkCheatModeEnv();

  const validate = [required(), regex(REGEX_URL, 'wa.exception.invalidUrl')];
  const validateWithoutRequired = [regex(REGEX_URL, 'wa.exception.invalidUrl')];

  /**
   * To redirect to player state management detail page after setting up the Get Player Token Url successfully
   */
  const redirectTo = LocalStorage.getItem(REDIRECT_KEY);

  const transform = (data) => {
    const {
      betStatusUrl,
      cancelBetUrl,
      getBalanceUrl,
      createPlayerUrl,
      editBalanceUrl,
      features,
      brandSettings,
      ...rest
    } = data;

    const dataBody = {
      ...rest,
      betStatusUrl: betStatusUrl || null,
      getBalanceUrl: getBalanceUrl || null,
      cancelBetUrl: cancelBetUrl || null,
      createPlayerUrl: isCheatMode ? createPlayerUrl || null : null,
      editBalanceUrl: isCheatMode ? editBalanceUrl || null : null,
      featureIds: features,
      brandSettings: {
        roundOpenPeriod: brandSettings?.roundOpenPeriod || 0,
      },
    };

    // Remove custom fields from body request
    delete dataBody[THIN_ENABLED_CURRENCIES_FIELD];

    if (isCheatMode) {
      return sanitizeObject(dataBody);
    }

    return sanitizeObject(omit(dataBody, ['createPlayerUrl', 'editBalanceUrl', 'playerLimit']));
  };

  useEffect(
    () => () => {
      /**
       * To clear redirect url to player state management detail page
       */
      LocalStorage.removeItem(REDIRECT_KEY);
    },
    [],
  );

  return (
    <WealthEditGuesser
      {...props}
      customFields={[
        'implementation',
        TRANSFER_BONUS_URL_FIELD_KEY,
        GET_PLAYER_TOKEN_URL_FIELD_KEY,
        'authorizeUrl',
        CURRENCIES_FIELD_KEY,
        PLAYER_LIMIT_FIELD_KEY,
        ...requiredFieldsSeamless,
        'homeButtonEnabled',
        'enabled',
        'betStatusUrl',
        'cancelBetUrl',
        'getBalanceUrl',
        'createPlayerUrl',
        'editBalanceUrl',
        'features',
        ...customFieldsConfig,
      ]}
      excludeFields={hasRegulationReadPermission ? ['playerLimit'] : ['playerLimit', 'regulation']}
      transform={transform}
      redirectUrl={redirectTo}
      fieldOrder={[
        'name',
        'regulation',
        'desc',
        'domain',
        'languages',
        'defaultLanguage',
        'group',
        'implementation',
        'features',
        'authorizeUrl',
        GET_PLAYER_TOKEN_URL_FIELD_KEY,
        'getBalanceUrl',
        'payOutUrl',
        'placeBetUrl',
        TRANSFER_BONUS_URL_FIELD_KEY,
        'betStatusUrl',
        'cancelBetUrl',
        'createPlayerUrl',
        'editBalanceUrl',
        'homeButtonEnabled',
        'enabled',
        'currencies',
      ]}
    >
      <FeaturesInput source="features" label="resources.feature-flag.name" />
      <CurrenciesConfigForBrandEdit />
      <FormDataConsumer>
        {({ formData }) => {
          const enabledComponent = (
            <WealthInputWrapper source="enabled">
              <BooleanInput />
            </WealthInputWrapper>
          );

          const homeButtonEnabled = (
            <WealthInputWrapper source="homeButtonEnabled" label="resources.brand.fields.homeButtonEnabled">
              <BooleanInput />
            </WealthInputWrapper>
          );

          const commonComponents = (
            <>
              <SelectInput
                fullWidth
                label="resources.brand.fields.implementation"
                source="implementation"
                choices={enumImplementation}
                validate={required()}
                disabled={!!formData?.walletIntegration}
              />

              <WealthInputWrapper source="authorizeUrl" label="resources.brand.fields.authorizeUrl" validate={validate}>
                <TextInput />
              </WealthInputWrapper>
              <GetPlayerTokenUrlInput source={GET_PLAYER_TOKEN_URL_FIELD_KEY} />
              <TransferBonusUrl source={TRANSFER_BONUS_URL_FIELD_KEY} />
              <WealthInputWrapper
                source="getBalanceUrl"
                label="resources.brand.fields.getBalanceUrl"
                validate={validateWithoutRequired}
              >
                <TextInput />
              </WealthInputWrapper>
            </>
          );

          if (formData.implementation === 'TRANSFER') {
            return (
              <>
                {commonComponents}
                {homeButtonEnabled}
                {enabledComponent}
              </>
            );
          }

          return (
            <>
              {commonComponents}
              {isCheatMode && <PlayerLimitWrapper />}
              {requiredFieldsSeamless.map((source) => (
                <WealthInputWrapper source={source} key={source} validate={validate}>
                  <TextInput />
                </WealthInputWrapper>
              ))}
              <WealthInputWrapper source="betStatusUrl" key="betStatusUrl" validate={validateWithoutRequired}>
                <TextInput />
              </WealthInputWrapper>
              <WealthInputWrapper source="cancelBetUrl" key="cancelBetUrl" validate={validateWithoutRequired}>
                <TextInput />
              </WealthInputWrapper>
              {isCheatMode && (
                <WealthInputWrapper source="createPlayerUrl" key="createPlayerUrl" validate={validateWithoutRequired}>
                  <TextInput />
                </WealthInputWrapper>
              )}
              {isCheatMode && (
                <WealthInputWrapper source="editBalanceUrl" key="editBalanceUrl" validate={validateWithoutRequired}>
                  <TextInput />
                </WealthInputWrapper>
              )}
              {homeButtonEnabled}
              {enabledComponent}
              {customFieldsConfig.map((field) => (
                <CustomSelectConfig key={`brand-edit-${field}`} source={field} {...props} />
              ))}
            </>
          );
        }}
      </FormDataConsumer>
      {!user.group && <AutocompleteFreeSpins />}
    </WealthEditGuesser>
  );
};

export default BrandEdit;
