import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { MultiLanguageInput } from './MultiLanguageLabel';
import SingleFileUploader from '../../common/SingleFileUploader';

const MultiLangFileUploader = ({
  source,
  value,
  onChange,
  langCode,
  label,
  uploaderProps,
  error,
  helperText,
  isRequired,
  disabled,
}) => (
  <MultiLanguageInput
    source={source}
    value={value}
    onChange={onChange}
    label={label}
    error={error}
    helperText={helperText}
    langCode={langCode}
    isRequired={isRequired}
    disabled={disabled}
    valueKey="fileKey"
  >
    <SingleFileUploader {...uploaderProps} />
  </MultiLanguageInput>
);

MultiLangFileUploader.propTypes = {
  source: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      fileKey: PropTypes.string,
      langCode: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  langCode: PropTypes.string,
  label: PropTypes.node,
  error: PropTypes.bool,
  helperText: PropTypes.node,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default memo(MultiLangFileUploader);
