import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { SimpleShowLayout } from 'react-admin';

const DrawerSimpleShowLayout = (props) => {
  const classes = useStyles();

  return <SimpleShowLayout {...props} className={clsx(classes.wrapper, props.className)} />;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
    '&:first-child': {
      paddingTop: 0,
    },
    '& .ra-field': {
      '&>*:first-child[class*="MuiFormControl"]': {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 10,
        marginTop: 0,
        marginBottom: 0,
        padding: '8px 0',
        borderBottom: '1px dashed lightgray',
        minHeight: 37,
      },
      '&>div>label': {
        flex: 3,
        transform: 'none',
        fontWeight: 500,
        whiteSpace: 'nowrap',
        color: theme.palette.text.primary,
        fontSize: 14,
        '&+*': {
          textAlign: 'right',
          flex: 7,
          padding: 0,
        },
      },
    },
  },
}));

export default DrawerSimpleShowLayout;
