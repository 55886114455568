import React from 'react';
import { useLocale, useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import { useReportContext } from '../ReportLayout';
import { AutocompleteInput, ReferenceInput } from '../../ra/inputs';
import FormField from './FormField';
import { useAuthUser } from '../../../hooks';
import resourceSlug from '../../../../constant/resource-slug';

const GameInput = ({ source, ...otherProps }) => {
  const { currentFilters } = useReportContext();
  const user = useAuthUser();
  const locale = useLocale();

  const translate = useTranslate();

  const brand = currentFilters?.brand || user?.brands?.[0]?.id;

  return (
    <FormField name={source}>
      {() => (
        <>
          <ReferenceInput
            label={translate('resources.game.name')}
            source={source}
            reference={resourceSlug.GAME}
            filter={{
              brandId: brand,
            }}
            filterToQuery={(value) => ({
              [`name->>'$.${locale}'||$cont`]: value,
            })}
            sort={{
              field: 'name',
              order: 'ASC',
            }}
            {...otherProps}
          >
            <AutocompleteInput optionText={`name.${locale}`} resettable />
          </ReferenceInput>
        </>
      )}
    </FormField>
  );
};

GameInput.propTypes = {
  source: PropTypes.string,
};

GameInput.defaultProps = {
  source: 'game',
};

export default GameInput;
