import React, { memo, useState } from 'react';
import {
  FunctionField,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  SelectField,
  TextField,
  useLocale,
  useRedirect,
  useTranslate,
} from 'react-admin';
import { Box, Chip, makeStyles, Popover, Typography } from '@material-ui/core';
import { useParams, useLocation } from 'react-router-dom';
import resourceSlug from '../../../../constant/resource-slug';
import { REWARD_TYPES } from '../../../../constant/reward';
import DrawerSimpleShowLayout from '../../../../base/components/ra/drawers/DrawerSimpleShowLayout';
import ActiveLabel from '../../../../base/components/ActiveLabel';
import { WithListContext } from '../../../../base/components/ra/list/WithListContext';
import { formatNumber } from '../../../../services/util/formatNumber';
import { EditButton } from '../../../../base/components/ra/buttons';
import { TooltipForDisabledInput } from '../../../../base/components/custom-tooltip';
import { AmountField } from '../../../../base/components/ra/fields';
import Labeled from '../../../../base/components/ra/labeled';
import useGetRewardDetails from '../../hooks/useGetRewardDetails';
import DetailsSkeleton from '../../../../base/components/common/DetailsSkeleton';

const DetailsTab = () => <Details />;

const Details = memo(() => {
  const t = useTranslate();

  const redirect = useRedirect();
  const { id } = useParams();
  const { search } = useLocation();
  const { reward: record, loading } = useGetRewardDetails(id);

  if (loading && !record) {
    return (
      <DetailsSkeleton
        sx={{
          mt: 6,
        }}
      />
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="calc(100% - 2em)"
      sx={{
        overflowY: 'auto',
      }}
      mt={6}
    >
      <Box
        component={DrawerSimpleShowLayout}
        sx={{
          flexGrow: 1,
          overflow: 'scroll',
        }}
        record={record}
      >
        <Box
          component={Typography}
          variant="h4"
          sx={{
            marginBottom: '10px !important',
          }}
        >
          {t('resources.reward.basic-info')}
        </Box>
        <TextField source="id" label="common.fields.id" />
        <FunctionField
          source="isEnabled"
          label="resources.reward.fields.status"
          render={(record) => <ActiveLabel isActive={record?.isEnabled} />}
        />
        <TextField source="rewardName" label="resources.reward.fields.reward-name" />
        <SelectField
          source="rewardType"
          label="resources.reward.fields.reward-type"
          choices={Object.values(REWARD_TYPES).map((type) => ({
            id: type,
            name: t(`resources.reward.reward-type.${type}`),
          }))}
        />
        <NumberField source="issuedQuantity" label="resources.reward.fields.issued-quantity" emptyText="-" />
        <NumberField source="usedQuantity" label="resources.reward.fields.used-quantity" emptyText="-" />

        <Box
          component={Typography}
          variant="h4"
          sx={{
            margin: '10px 0 !important',
          }}
        >
          {t(`resources.reward.reward-type.${record?.rewardType}`)}
        </Box>
        <ReferenceField
          label="resources.reward.fields.currency-code"
          reference={resourceSlug.CURRENCY}
          source="currencyId"
          link={false}
        >
          <TextField source="code" />
        </ReferenceField>
        {[REWARD_TYPES.FREE_SPIN, REWARD_TYPES.GOLD_CARD, REWARD_TYPES.RUBY_CARD].includes(record?.rewardType) && (
          <Labeled
            label={
              record?.rewardType === REWARD_TYPES.FREE_SPIN
                ? t('resources.reward.fields.game')
                : t('resources.reward.fields.game-list')
            }
          >
            <GameListField />
          </Labeled>
        )}
        {[REWARD_TYPES.FREE_SPIN, REWARD_TYPES.GOLD_CARD, REWARD_TYPES.RUBY_CARD].includes(record?.rewardType) && (
          <NumberField source="freeSpinQuantity" label="resources.reward.fields.free-spins-count" />
        )}
        {[REWARD_TYPES.FREE_SPIN, REWARD_TYPES.GOLD_CARD, REWARD_TYPES.RUBY_CARD].includes(record?.rewardType) && (
          <Labeled label={t('resources.reward.fields.bet-amount')}>
            <AmountField label="resources.reward.fields.bet-amount" source="betAmount" />
          </Labeled>
        )}
        {[REWARD_TYPES.FREE_SPIN, REWARD_TYPES.GOLD_CARD, REWARD_TYPES.RUBY_CARD].includes(record?.rewardType) &&
          record?.maxWin != null && (
            <Labeled label={t('resources.reward.fields.max-win')}>
              <AmountField source="maxWin" />
            </Labeled>
          )}
        {record?.rewardType === REWARD_TYPES.CASH_VOUCHER && (
          <Labeled label={t('resources.reward.fields.cash-amount')}>
            <AmountField source="amount" />
          </Labeled>
        )}
        <FunctionField
          label="resources.reward.fields.expiry"
          render={(record) =>
            t(`resources.reward.expiry.${record.validPeriodUnit}`, {
              smart_count: record.validPeriod,
            })
          }
        />
        <FunctionField
          label="resources.reward.fields.turnover-requirement"
          render={(record) => `x${formatNumber('en', record.turnoverPercent / 100)}`}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end" mt="auto" pt={2}>
        <TooltipForDisabledInput
          placement="top-start"
          showMessage={!record.isUpdatable}
          title={t('resources.reward.can-not-update')}
        >
          <EditButton
            disabled={!record.isUpdatable}
            icon={<></>}
            onClick={() => redirect(`/${resourceSlug.REWARD}/${id}/edit${search}`)}
          />
        </TooltipForDisabledInput>
      </Box>
    </Box>
  );
});

export default DetailsTab;

export const GameListField = ({ source = 'gameIds', ...props }) => {
  const t = useTranslate();
  const locale = useLocale();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <ReferenceArrayField reference={resourceSlug.GAME} source={source} {...props}>
      <WithListContext
        render={({ data }) => {
          if (!Object.values(data).length) return t('common.text.allGames');
          const displayed = Object.values(data).slice(0, 4);
          const rest = Object.values(data).slice(4);

          return (
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="flex-end"
              maxWidth={300}
              sx={{
                gap: 4,
              }}
            >
              {displayed.map((game) => (
                <Chip key={game.id} label={game.name?.[locale]} />
              ))}
              {!!rest?.length && (
                <>
                  <Box
                    component={Typography}
                    sx={{
                      lineHeight: '28px !important',
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }}
                    variant="body2"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                  >
                    {`+${rest.length}`}
                  </Box>
                  <Popover
                    classes={{
                      root: classes.gamePopover,
                      paper: classes.gamePopoverPaper,
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                  >
                    {rest.map((game) => (
                      <Chip key={game.id} label={game.name?.[locale]} />
                    ))}
                  </Popover>
                </>
              )}
            </Box>
          );
        }}
      />
    </ReferenceArrayField>
  );
};

const useStyles = makeStyles({
  gamePopover: {
    pointerEvents: 'none',
  },
  gamePopoverPaper: {
    zIndex: 1000,
    padding: 12,
    display: 'flex',
    flexWrap: 'wrap',
    gap: 4,
    background: 'rgba(0, 0, 0, 0.7)',
    maxWidth: 600,
  },
});
