import React from 'react';
import { FunctionField, TextField, useLocale } from 'react-admin';
import { Box } from '@material-ui/core';
import WealthShowGuesser from '../../base/components/guesser/wealth-show.guesser';
import GameIcon from './components/GameIcon';

export default function GameShow(props) {
  const locale = useLocale();
  return (
    <WealthShowGuesser
      {...props}
      excludeFields={['baseBet', 'desc']}
      informFieldOrder={[
        'gameIcon',
        `name.${locale}`,
        'code',
        'gameId',
        'gameType.id',
        'slotType',
        'playUrl',
        `preview.${locale}.blobUrl`,
        'isMcEnabled',
        'isHotGame',
        'isNewGame',
        'baseBet',
      ]}
      extraBlocks={[<TextField source={`desc.${locale}`} label="resources.game.fields.desc" />]}
      extraInformBlocks={[
        <FunctionField
          source="gameIcon"
          label="resources.game.fields.icon"
          render={(record) => <GameIcon src={record.gameIcon?.fileKey} />}
        />,
        <FunctionField
          source="baseBet"
          render={(record) => {
            if (Array.isArray(record.gameConfig?.multiBaseBets)) {
              return (
                <Box
                  display="flex"
                  sx={{
                    gap: 8,
                  }}
                >
                  {record.gameConfig?.multiBaseBets
                    .sort((a, b) => a - b)
                    .map((item) => (
                      <Box component="span" key={item}>
                        {item}
                      </Box>
                    ))}
                </Box>
              );
            }
            return record.baseBet;
          }}
        />,
      ]}
    />
  );
}
