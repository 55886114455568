import React from 'react';
import { Box } from '@material-ui/core';
import { useShowController } from 'react-admin';
import PropTypes from 'prop-types';
import { Show } from '../../ra/views';
import ToolbarMenu from '../../action-menu/toolbar-menu';

const CommonShowWrapper = ({
  extendedActions, defaultToolbarProps, actions, ...otherProps
}) => {
  const {
    basePath, resource,
  } = otherProps;
  const showController = useShowController(otherProps);
  const { record } = showController;

  return (
    <Show
      actions={(
        <Box
          display="flex"
          justifyContent="flex-end"
        >
          {React.isValidElement(actions) ? (
            React.cloneElement(actions, {
              record,
              basePath,
              resource,
            })
          ) : (
            <>
              <ToolbarMenu
                basePath={basePath}
                record={record}
                resource={resource}
                hasList
                hasEdit
                {...defaultToolbarProps}
              />
              {extendedActions}
            </>
          )}
        </Box>
      )}
      {...otherProps}
    />
  );
};

CommonShowWrapper.propTypes = {
  extendedActions: PropTypes.node,
  defaultToolbarProps: PropTypes.object,
  basePath: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
};

CommonShowWrapper.defaultProps = {
  extendedActions: null,
  defaultToolbarProps: {},
};

export default CommonShowWrapper;
