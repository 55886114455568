/* eslint-disable import/no-cycle */
import React, { useState, useMemo, useEffect, useRef, memo } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { useTranslate } from 'react-admin';
import { useParams } from 'react-router-dom';
import BasicInfoStep from './BasicInfoStep';
import { MissionFormContext, useMissionForm } from '../utils';
import MissionSettingsStep from './MissionSettingsStep';

const MissionCreateUpdate = memo(({ initialValues, onSave, isSaving, uneditableFields }) => {
  const [activeStep, setActiveStep] = useState(0);
  const data = useRef({});
  const { id } = useParams();

  useEffect(() => {
    if (!initialValues) return;
    data.current = initialValues;
  }, [initialValues]);

  return (
    <MissionFormContext.Provider
      value={{
        activeStep,
        nextStep: () => setActiveStep(activeStep + 1),
        backStep: () => setActiveStep(activeStep - 1),
        initialValues,
        onSave,
        isSaving,
        data,
        updateData: (newData) => {
          data.current = {
            ...data.current,
            ...newData,
          };
        },
        uneditableFields,
        id,
      }}
    >
      <Box
        px={{
          xs: '16px',
          md: '28px',
        }}
      >
        <MissionStepper />
        <Box hidden={activeStep !== 0}>
          <BasicInfoStep />
        </Box>
        <Box hidden={activeStep !== 1}>
          <MissionSettingsStep />
        </Box>
      </Box>
    </MissionFormContext.Provider>
  );
});

const MissionStepper = () => {
  const classes = useStyles();
  const t = useTranslate();
  const { activeStep } = useMissionForm();

  const steps = useMemo(() => [t('resources.check-in.basic-info'), t('resources.mission.mission-settings')], [t]);

  return (
    <Stepper className={classes.root} activeStep={activeStep}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: '500px',
    margin: 'auto',
  },
});

export default MissionCreateUpdate;
