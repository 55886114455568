import React, { useMemo } from 'react';
import { FunctionField, ReferenceField, SelectField, TextField, useShowController, useTranslate } from 'react-admin';
import { Card, Box } from '@material-ui/core';
import moment from 'moment-timezone';
import MissionDetailsTabs, { MISSION_TABS } from '../MissionDetailsTabs';
import { ShowSection } from '../../../../../base/components/guesser/wealth-show.guesser';
import Labeled from '../../../../../base/components/ra/labeled';
import { DateField } from '../../../../../base/components/ra/fields';
import { DATE_DISPLAY_FORMAT } from '../../../../../constant';
import BrandList from './BrandList';
import CampaignStatus from '../../../../mkt-free-spin/components/CampaignStatus';
import MissionTitle from './MissionTitle';
import { WALLET_TYPES } from '../../../../../constant/campaign';
import MissionConfigCalendar from '../../MissionCreateEdit/MissionSettingsStep/MissionConfigCalender';
import { formatMissionData } from '../../utils';

const MissionGeneralInfo = (props) => {
  const t = useTranslate();
  const { record: campaign } = useShowController(props);

  const missions = useMemo(() => formatMissionData(campaign).missionList, [campaign?.missionList]);

  return (
    <Card>
      <MissionDetailsTabs currentTab={MISSION_TABS.GENERAL_INFO}>
        <ShowSection title={t('resources.check-in.general-info')}>
          <TextField source="id" label="common.fields.id" />
          <TextField source="campaignName" label="resources.check-in.fields.name" />
          <TextField source="campaignCode" label="resources.campaign.fields.code" />
          <Labeled label="common.fields.status">
            <CampaignStatus />
          </Labeled>
          <ReferenceField reference="currency" source="currencyId" label="common.fields.currency" link="show">
            <TextField source="name" />
          </ReferenceField>
          <Labeled label="resources.campaign.fields.start-date">
            <DateField source="startTime" dateFormat={DATE_DISPLAY_FORMAT} />
          </Labeled>
          <Labeled label="resources.campaign.fields.end-date">
            <DateField source="endTime" dateFormat={DATE_DISPLAY_FORMAT} />
          </Labeled>
          <FunctionField
            source="config.timezone"
            label="resources.check-in.fields.timezone"
            render={(record) =>
              `${record?.timezone} (GMT${record?.timezone ? moment().tz(record?.timezone).format('Z') : ''})`
            }
            sortable={false}
          />
        </ShowSection>
        <BrandList
          excludedGroupIds={campaign?.group?.excludes}
          groupIds={campaign?.group?.includes}
          excludedBrandIds={campaign?.brand?.excludes}
          brandIds={campaign?.brand?.includes}
        />
        <MissionTitle title={campaign?.title} bannerConfigs={campaign?.bannerConfigs} />
        <ShowSection
          title={t('common.text.settings')}
          wrapperProps={{
            mt: -5,
          }}
        >
          <SelectField
            source="walletConversion"
            label="resources.check-in.fields.transfer-destination"
            choices={Object.values(WALLET_TYPES).map((type) => ({
              id: type,
              name: t(`common.wallet.${type}`),
            }))}
          />
        </ShowSection>

        {Array.isArray(campaign?.missionList) && (
          <Box px={12.5} pb={4} mt={-5}>
            <MissionConfigCalendar
              startTime={campaign.startTime}
              endTime={campaign.endTime}
              data={missions}
              mode="view"
            />
          </Box>
        )}
      </MissionDetailsTabs>
    </Card>
  );
};

export default MissionGeneralInfo;
