import React from 'react';
import { required } from 'react-admin';
import PropTypes from 'prop-types';
import { AutocompleteInput } from '../../../../base/components/ra/inputs';
import ReferenceInput from '../../../../base/components/ra/inputs/reference.input';
import resourceSlug from '../../../../constant/resource-slug';

const CurrencyInput = ({ disabled }) => (
  <ReferenceInput
    source="currencyId"
    isRequired
    resettable
    filter={{
      enabled: true,
    }}
    label="resources.reward.fields.currency"
    reference={resourceSlug.CURRENCY}
    validate={required()}
    filterToQuery={(value) => ({
      'name||$cont': value,
    })}
    disabled={disabled}
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>
);

export default CurrencyInput;

CurrencyInput.propTypes = {
  disabled: PropTypes.bool,
};
