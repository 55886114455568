/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { FunctionField, useLocale } from 'react-admin';
import WealthListGuesser from '../../base/components/guesser/wealth-list.guesser';
import { useReloadContext } from '../../base/context/reload/reload.context';
import { useSchema } from '../../base/hooks';
import ActionLink from './components/action.link';
import DefaultChip from '../../base/components/DefaultChip';
import GameIcon from './components/GameIcon';

const useStyles = makeStyles({
  listWrapper: {
    '& table tr td:last-child': {
      width: 180,
      maxWidth: 180,
    },
  },
});

function GameList(props) {
  const styles = useStyles();
  const locale = useLocale();
  const { setValuesContext } = useReloadContext();
  const [requestFields, setRequestFields] = useState([]);

  useEffect(
    () => () => {
      setValuesContext({});
    },
    [],
  );

  const { api, ref } = useSchema();

  useEffect(() => {
    if (api) {
      const { paths } = api;

      const basePath = '/game';
      const target = `${basePath}/{id}`;
      let requestRef = paths?.[target]?.patch?.requestBody?.content?.['application/json']?.schema;

      requestRef = requestRef.$ref || requestRef.allOf?.filter((i) => i?.$ref)?.[0]?.$ref;

      const requestComponent = ref.get(requestRef);

      const { properties } = requestComponent;

      const writableFields = Object.keys(properties);

      setRequestFields([...writableFields, 'gameIcon']);
    }
  }, [api]);

  return (
    <>
      <WealthListGuesser
        {...props}
        excludeFilterInputs={['isAvailable']}
        extraActions={<ActionLink label="ra.field.action" requestFields={requestFields} {...props} />}
        excludeFields={[
          'enabled',
          'gameLogicUrl',
          'playUrl',
          'name',
          'gameIcon',
          'isAvailable',
          'baseBet',
          'preview',
          'desc',
        ]}
        fieldOrder={['id', 'name', 'created', 'updated', 'gameId', 'gameType.id', 'baseBet']}
        className={styles.listWrapper}
      >
        <FunctionField
          source="name"
          label="resources.game.fields.name"
          render={(record) => (
            <Box
              display="flex"
              alignItems="center"
              px={3}
              sx={{
                gap: 12,
              }}
            >
              <GameIcon src={record.gameIcon?.fileKey} />
              <Box textAlign="left">{record.name?.[locale]}</Box>
            </Box>
          )}
        />
        <FunctionField
          source="baseBet"
          render={(record) => {
            if (Array.isArray(record.multiBaseBets)) {
              return (
                <Box display="flex" alignItems="center">
                  <DefaultChip />
                  <Box component="span">{record.baseBet}</Box>
                </Box>
              );
            }
            return record.baseBet;
          }}
        />
      </WealthListGuesser>
    </>
  );
}

export default GameList;
