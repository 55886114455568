import React, { memo } from 'react';
import { FunctionField, RecordContext, TextField, SelectField, useTranslate } from 'react-admin';
import { Box, makeStyles, Typography } from '@material-ui/core';
import MuiDrawer from '../../../base/components/mui/MuiDrawer';
import DrawerSimpleShowLayout from '../../../base/components/ra/drawers/DrawerSimpleShowLayout';
import { DateField } from '../../../base/components/ra/fields';
import PlayerRewardStatus from '../../check-in/components/CheckInDetails/CheckInPlayerRewards/PlayerRewardStatus';
import { REWARD_TYPES } from '../../../constant/reward';
import { formatMoney, formatNumber } from '../../../services/util/formatNumber';
import CustomLinkField from '../../../base/components/ra/fields/customLink.field';
import { useCampaignDetailsUrl } from '../../../base/hooks/useCampaignDetailsUrl';
import resourceSlug from '../../../constant/resource-slug';
import Labeled from '../../../base/components/ra/labeled';

const UserRewardDetailsDrawer = ({ data, onClose, open }) => {
  const t = useTranslate();
  const classes = useStyles();
  const { getCampaignDetailsUrl } = useCampaignDetailsUrl();

  return (
    <MuiDrawer
      open={open}
      anchor="right"
      onClose={onClose}
      title={t('resources.report-user-rewards.player-reward-details')}
      classes={{
        paper: classes.paper,
      }}
    >
      <RecordContext.Provider value={data}>
        <Box display="flex" flexDirection="column" height="100%">
          <Box
            component={DrawerSimpleShowLayout}
            sx={{
              flexGrow: 1,
              overflow: 'scroll',
            }}
          >
            <Box component={Typography} variant="h4" my="12px !important" mb="10px !important">
              {t('resources.report-user-rewards.player-info')}
            </Box>
            <Labeled label={t('common.fields.nativeId')}>
              <CustomLinkField
                source="nativeId"
                href={(record) => `/#/${resourceSlug.PLAYER}/${record.playerId}/show`}
                title={(record) => record.nativeId}
              />
            </Labeled>

            <TextField source="group" label="common.fields.group" />
            <TextField source="brand" label="common.fields.brand" />

            <Box component={Typography} variant="h4" mt="12px !important" mb="10px !important">
              {t('resources.report-user-rewards.player-reward-info')}
            </Box>
            <FunctionField
              label="common.fields.sourceType"
              source="campaignType"
              render={(record) => t(`common.sourceType.${record.sourceType}`)}
            />
            <Labeled label={t('common.fields.campaignCode')}>
              <CustomLinkField
                source="campaignCode"
                href={(record) => getCampaignDetailsUrl(record.sourceType, record.campaignId)}
                title={(record) => record.campaignCode}
              />
            </Labeled>
            <FunctionField
              label="common.fields.status"
              render={(record) => <PlayerRewardStatus status={record.status} />}
            />
            <Box component={Typography} variant="h4" my="12px !important" mb="10px !important">
              {t('resources.check-in.reward-info')}
            </Box>
            <TextField label="resources.reward.fields.reward-name" source="rewardName" emptyText="-" />
            <SelectField
              label="resources.reward.fields.reward-type"
              source="rewardType"
              choices={Object.values(REWARD_TYPES).map((type) => ({
                id: type,
                name: t(`resources.reward.reward-type.${type}`),
              }))}
            />
            <TextField label="resources.currency.name" source="currencyName" />
            <TextField label="resources.report.fields.currencyType" source="currencyType" />
            <FunctionField
              label="resources.check-in.fields.issued-date"
              render={(record) => <DateField record={record} source="issuedDate" emptyText="-" />}
            />
            <FunctionField
              label="resources.check-in.fields.used-date"
              render={(record) => (record?.usedDate ? <DateField record={record} source="usedDate" /> : '-')}
            />
            {[REWARD_TYPES.FREE_SPIN, REWARD_TYPES.GOLD_CARD, REWARD_TYPES.RUBY_CARD].includes(data?.rewardType) && (
              <FunctionField
                label="resources.reward.fields.total-spins"
                source="issuedQuantity"
                addLabel
                render={(record) => formatNumber('en', record.issuedQuantity) ?? '-'}
              />
            )}
            {[REWARD_TYPES.FREE_SPIN, REWARD_TYPES.GOLD_CARD, REWARD_TYPES.RUBY_CARD].includes(data?.rewardType) && (
              <FunctionField
                label="resources.reward.fields.used-spins"
                source="usedQuantity"
                addLabel
                render={(record) => formatNumber('en', record.usedQuantity) ?? '-'}
              />
            )}
            {[REWARD_TYPES.FREE_SPIN, REWARD_TYPES.GOLD_CARD, REWARD_TYPES.RUBY_CARD].includes(data?.rewardType) && (
              <FunctionField
                label="common.fields.wonAmount"
                source="wonAmount"
                addLabel
                render={(record) => formatMoney(record.wonAmount)}
              />
            )}
            {data?.rewardType === REWARD_TYPES.CASH_VOUCHER && (
              <FunctionField
                label={t('resources.reward.fields.cash-amount')}
                source="amount"
                render={(record) => formatMoney(record.amount)}
              />
            )}
          </Box>
        </Box>
      </RecordContext.Provider>
    </MuiDrawer>
  );
};

export default memo(UserRewardDetailsDrawer);

const useStyles = makeStyles({
  paper: {
    width: '29rem',
  },
});
