import React, { useMemo, memo } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { useParams } from 'react-router-dom';
import CustomizedFilterWrapper from '../../../../../base/components/customized/CustomizedFilterWrapper';
import { SelectInput, TextInput } from '../../../../../base/components/ra/inputs';
import DateRangePickerInput from '../../../../../base/components/guesser/date-range-input';
import resourceSlug from '../../../../../constant/resource-slug';
import { CAMPAIGN_TYPES } from '../../../../../constant/campaign';
import { WALLET_TRANSACTION_STATUS, WALLET_TRANSFER_TYPE } from '../../../../../constant/transaction';

const ConversionHistoryFilters = () => {
  const t = useTranslate();
  const { id } = useParams();
  const record = useRecordContext();

  const currencyOptions = useMemo(
    () =>
      record?.wallets
        ?.filter((wallet) => !!wallet?.bonus && !!wallet.currency)
        ?.map((wallet) => ({
          id: wallet.currency?.id,
          name: wallet.currency?.name,
        })),
    [record],
  );

  return (
    <CustomizedFilterWrapper>
      <DateRangeInput
        source="created"
        label={t('common.fields.created')}
        resource={`${resourceSlug.PLAYER}/${id}/wallet-conversion-history`}
      />
      <TextInput source="transactionId||$eq" label="common.fields.transactionId" resettable />
      <SelectInput source="currencyId" label="common.fields.currency" choices={currencyOptions} resettable />
      <SelectInput
        label="common.fields.sourceType"
        source="sourceType"
        choices={Object.values(CAMPAIGN_TYPES).map((type) => ({
          id: type,
          name: t(`common.sourceType.${type}`),
        }))}
      />
      <SelectInput
        source="transferType"
        label="resources.player.fields.transfer-type"
        choices={Object.values(WALLET_TRANSFER_TYPE).map((type) => ({
          id: type,
          name: t(`resources.player.transfer-type.${type}`),
        }))}
        resettable
      />
      <SelectInput
        source="status"
        label="common.fields.status"
        choices={Object.values(WALLET_TRANSACTION_STATUS).map((status) => ({
          id: status,
          name: t(`resources.bonus-wallet-transaction.status.${status}`),
        }))}
        resettable
      />
    </CustomizedFilterWrapper>
  );
};

const DateRangeInput = (props) => <DateRangePickerInput clearable={false} prefix {...props} />;

export default memo(ConversionHistoryFilters);
