/* eslint-disable no-param-reassign */
import Type from 'prop-types';
import React from 'react';
import { SimpleForm, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { Create } from '../ra/views';

const WealthCreate = props => {
  const classes = useStyles();
  const translate = useTranslate();
  const {
    children, resource, redirect, writableFields, confirmDialogInfo, translatedResourceName, ...rest
  } = props;

  const realResource = resource.includes('/')
    ? `resources.${resource.split('/')[1]}.name`
    : `resources.${resource}.name`;

  return (
    <Create
      {...rest}
      resource={resource}
      title={translate('ra.page.create', {
        name: `${translate(realResource)}`,
      })}
      writableFields={writableFields}
      confirmDialogInfo={confirmDialogInfo}
      translatedResourceName={translatedResourceName}
    >
      <SimpleForm
        redirect={redirect}
        className={classes.form}
      >
        {children}
      </SimpleForm>
    </Create>
  );
};

WealthCreate.propTypes = {
  required: Type.arrayOf(Type.string),
  children: Type.oneOfType([Type.element, Type.arrayOf(Type.element)]),
  resource: Type.string,
  redirect: Type.string, // "edit"|"show"|"list"|<custom route>
  writableFields: Type.arrayOf(Type.object),
  confirmDialogInfo: Type.shape({
    title: Type.string,
    content: Type.string,
  }),
  translatedResourceName: Type.string,
};

WealthCreate.defaultProps = {
  required: [],
  children: null,
  resource: {},
  redirect: 'show',
  writableFields: [],
  confirmDialogInfo: null,
  translatedResourceName: '',
};

export default WealthCreate;

const useStyles = makeStyles({
  form: {
    '&>[class*="RaCardContentInner"]:first-child': {
      padding: '28px 24px 0px',
    },
  },
});
