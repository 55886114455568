/* eslint-disable no-param-reassign */
import { Box, Button, Divider, FormHelperText, makeStyles, Tab, Tabs, Typography, useTheme } from '@material-ui/core';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useField } from 'react-final-form';
import chunk from 'lodash/chunk';
import { useInput, useTranslate } from 'react-admin';
import clsx from 'clsx';
import { CardGiftcard, Delete } from '@material-ui/icons';
import { CHECK_IN_TYPES } from '../../../../../../../constant/campaign';
import { useCheckInCampaignDuration, useCheckInForm, useCheckInValidate } from '../../../../utils';
import AddRewardModal from './AddRewardModal';

const RewardConfiguration = () => {
  const classes = useStyles();
  const campaignDuration = useCheckInCampaignDuration();
  const {
    input: { value: type },
  } = useField('type');
  const {
    input: { value: requiredCheckInDays },
  } = useField('requiredCheckInDays');
  const [currentDay, setCurrentDay] = useState(1);
  const { rewardConfigValidate } = useCheckInValidate();
  const { uneditableFields, data } = useCheckInForm();
  const { currencyId } = data.current;
  const previousCurrencyId = useRef(currencyId);

  const {
    meta: { error, submitError, touched },
    input: { onChange: onRewardConfigChange, value: rewardConfig },
  } = useInput({
    source: 'rewardConfig',
    validate: rewardConfigValidate,
  });

  const showError = (submitError || error) && touched;

  const rewardDates = useMemo(
    () =>
      Array.from(
        {
          length: type === CHECK_IN_TYPES.CONSECUTIVE ? requiredCheckInDays : campaignDuration || 31,
        },
        (_, index) => index + 1,
      ),
    [campaignDuration, type, requiredCheckInDays],
  );

  const currentRewards = useMemo(() => rewardConfig?.[currentDay]?.rewards || [], [rewardConfig, currentDay]);

  const onAddRewardConfig = useCallback(
    (rewards) => {
      const newRewardConfig = {
        ...rewardConfig,
      };

      newRewardConfig[currentDay] = {
        ...newRewardConfig[currentDay],
        rewards: (newRewardConfig[currentDay]?.rewards || []).concat(rewards),
      };

      onRewardConfigChange(newRewardConfig);
    },
    [rewardConfig, onRewardConfigChange, currentDay],
  );

  const onDeleteRewardConfig = useCallback(
    (rewardId) => {
      const newRewardConfig = {
        ...rewardConfig,
      };

      newRewardConfig[currentDay] = {
        ...rewardConfig[currentDay],
        rewards: rewardConfig[currentDay].rewards.filter((reward) => reward.rewardId !== rewardId),
      };

      onRewardConfigChange(newRewardConfig);
    },
    [rewardConfig, onRewardConfigChange, currentDay],
  );

  // Reset all rewards configured after currencyId is changed
  useEffect(() => {
    if (
      !Object.keys(rewardConfig || {}).length ||
      !currencyId ||
      !previousCurrencyId.current ||
      previousCurrencyId.current === currencyId
    ) {
      previousCurrencyId.current = currencyId;
      return;
    }

    const newRewardConfig = Object.keys(rewardConfig).reduce((list, date) => {
      list[date] = {
        ...rewardConfig[date],
        rewards: [],
      };

      return list;
    }, {});

    onRewardConfigChange(newRewardConfig);
  }, [currencyId]);

  if (!rewardDates.length) return null;

  return (
    <Box mt={2} mb={6}>
      <Box mt={2} mb={4}>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={Math.floor(currentDay / 30)}
          classes={{
            indicator: classes.indicator,
          }}
        >
          {chunk(rewardDates, 30).map((group) => (
            <Box
              key={`group-${group}`}
              display="grid"
              gridTemplateColumns="repeat(6,1fr)"
              minWidth="100%"
              height="fit-content"
              sx={{
                gap: 4,
              }}
            >
              {group.map((day) => (
                <Tab
                  key={`tab-${day}`}
                  data-selected={day === currentDay}
                  classes={{
                    root: clsx(classes.tabRoot, showError && classes.tabError),
                    wrapper: classes.tabWrapper,
                  }}
                  label={
                    <RewardConfigLabel
                      day={day}
                      campaignType={type}
                      configured={!!rewardConfig?.[day]?.rewards?.length}
                    />
                  }
                  onClick={() => setCurrentDay(day)}
                />
              ))}
            </Box>
          ))}
        </Tabs>
        <Box
          component={FormHelperText}
          display={showError ? 'flex' : 'none'}
          marginTop="3px !important"
          error={!!showError}
          key={`helperText-${+showError}`}
        >
          {error || submitError || ' '}
        </Box>
      </Box>
      <Box
        display="flex"
        flexWrap="wrap"
        sx={{
          gap: 12,
        }}
      >
        {currentRewards?.map((item) => (
          <RewardBox
            key={item.rewardId}
            rewardName={item.rewardName}
            onDelete={() => onDeleteRewardConfig(item.rewardId)}
            disabled={!!uneditableFields?.includes?.('rewardConfigs')}
          />
        ))}
        {!uneditableFields?.includes?.('rewardConfigs') && (
          <AddRewardModal
            key={`addRewardModal-${currentDay}`}
            onChange={onAddRewardConfig}
            defaultRewards={currentRewards}
            currencyId={currencyId}
          />
        )}
      </Box>
    </Box>
  );
};

const RewardConfigLabel = ({ configured, campaignType, day }) => {
  const t = useTranslate();

  return (
    <Box
      display="flex"
      sx={{
        gap: 8,
      }}
    >
      {!!configured && <Box component={CardGiftcard} width={18} />}
      {campaignType === CHECK_IN_TYPES.CONSECUTIVE
        ? t('resources.check-in.consecutive-day', {
            smart_count: day,
          })
        : t('resources.check-in.cumulative-day', {
            smart_count: day,
          })}
    </Box>
  );
};

export const RewardBox = ({ rewardName, onDelete, disabled }) => {
  const classes = useStyles();
  const theme = useTheme();
  const t = useTranslate();
  return (
    <Box className={classes.rewardBox}>
      <Box
        display="flex"
        p={2.5}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        flexGrow={1}
        borderRadius={!disabled ? '8px 8px 0 0' : 8}
        sx={{
          gap: 2,
        }}
        bgcolor={theme.palette.grey[100]}
      >
        <Box component={CardGiftcard} width={20} />
        <Box
          component={Typography}
          overflow="hidden"
          variant="body2"
          width="100%"
          display="-webkit-box"
          textAlign="center"
          sx={{
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
          }}
        >
          {rewardName}
        </Box>
      </Box>
      {!disabled && (
        <>
          <Divider />
          <Button startIcon={<Delete />} className={classes.deleteButton} onClick={() => onDelete()}>
            {t('ra.action.delete')}
          </Button>
        </>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  rewardBox: {
    width: 120,
    aspectRatio: 1,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 8,
    flexDirection: 'column',
    display: 'flex',
  },
  tabRoot: {
    '&[data-selected="true"]': {
      '& $tabWrapper': {
        color: 'white',
      },
      backgroundColor: theme.palette.primary.dark,
      border: `1px solid ${theme.palette.primary.dark}`,
    },
    padding: 0,
    minWidth: 80,
    minHeight: 'fit-content',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 4,
  },
  tabWrapper: {
    textTransform: 'capitalize',
    color: 'black',
    fontWeight: 600,
    padding: 6,
    height: 'fit-content',
  },
  indicator: {
    height: 0,
  },
  tabError: {
    '& $tabWrapper': {
      color: theme.palette.error.main,
    },
    '&[data-selected="true"]': {
      '& $tabWrapper': {
        color: 'white',
      },
      backgroundColor: theme.palette.error.main,
      border: `1px solid ${theme.palette.error.main}`,
    },
    border: `1px solid ${theme.palette.error.main}`,
  },
  deleteButton: {
    color: theme.palette.error.main,
    width: '100%',
    margin: 0,
  },
}));

export default memo(RewardConfiguration);
