/* eslint-disable no-undef */

import { getBackofficeEnv } from '.';

const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const getImageUrl = (imagePathOrUrl) => {
  const { IMAGE_HOST_URL } = getBackofficeEnv();

  if (!IMAGE_HOST_URL) {
    return imagePathOrUrl;
  }
  if (!isValidHttpUrl(imagePathOrUrl)) {
    return `${IMAGE_HOST_URL}/${imagePathOrUrl}`;
  }
  return imagePathOrUrl;
};

export const gcd = (a, b) => {
  if (b === 0) return a;
  return gcd(b, a % b);
};

export const getRatioString = (width, height) => {
  const lcd = gcd(width, height);
  return `${width / lcd}:${height / lcd}`;
};

export const getImageDimensions = (file) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
      });
    };
    img.onerror = reject;
    img.src = URL.createObjectURL(file);
  });
