import React from 'react';
import { Box } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import { Empty as AntdEmpty } from 'antd';

export default function Empty({ text }) {
  const translate = useTranslate();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& .ant-empty .ant-empty-description': {
          color: '#9e9e9e',
        },
      }}
    >
      <AntdEmpty description={text || translate('ra.text.noRecords')} />
    </Box>
  );
}

Empty.propTypes = {
  text: PropTypes.string,
};

Empty.defaultProps = {
  text: '',
};
