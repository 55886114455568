import React from 'react';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

const DetailsSkeleton = ({
  length = 5, sx, ...props
}) => (
  <Box
    display="flex"
    flexDirection="column"
    sx={{
      gap: 8,
      ...sx,
    }}
    {...props}
  >
    {Array.from(
      {
        length,
      },
      (_, idx) => idx,
    ).map(idx => (
      <Skeleton
        key={idx}
        variant="rect"
        height={20}
      />
    ))}
  </Box>
);

export default DetailsSkeleton;

DetailsSkeleton.propTypes = {
  length: PropTypes.number,
};
