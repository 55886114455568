import React, { useCallback } from 'react';
import { useInput, useTranslate } from 'react-admin';
import { NumericFormat } from 'react-number-format';
import ResettableTextField from '../../../../base/components/ra/inputs/ResettableTextField';

const TurnoverInput = (props) => {
  const t = useTranslate();

  const turnoverValidate = useCallback(
    (value) => {
      if (value == null) {
        return t('ra.validation.required');
      }

      if (value === 0) {
        return t('ra.validation.greater', {
          min: 0,
        });
      }

      return undefined;
    },
    [t],
  );

  const {
    meta: { error, submitError, touched },
    input: { onChange, value },
  } = useInput({
    source: 'turnoverPercent',
    validate: turnoverValidate,
  });

  return (
    <NumericFormat
      customInput={ResettableTextField}
      source="turnoverPercent"
      variant="outlined"
      label={`${t('resources.reward.fields.turnover-requirement')} *`}
      prefix="x"
      resettable
      value={typeof value === 'number' ? value / 100 : value}
      decimalScale={2}
      onValueChange={(v) => {
        // value without prefix
        onChange(v?.value ? parseFloat(v?.value) * 100 : v?.value);
      }}
      onReset={() => {
        onChange();
      }}
      error={!!(touched && (error || submitError))}
      helperText={touched && (error || submitError) ? error || submitError : ' '}
      {...props}
    />
  );
};

export default TurnoverInput;
