import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment-timezone';
import { ErrorBoundary } from 'react-error-boundary';
import App from './App';
import './styles/index.scss';
import * as serviceWorker from './serviceWorker';
import './services/axios/interceptors';
import ErrorFallback from './base/components/ErrorFallback';

moment.tz.setDefault('Africa/Abidjan'); // To set timezone to UTC+0 throughout in the project

ReactDOM.render(
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <App />
  </ErrorBoundary>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
