import React from 'react';
import { CreateButton, useTranslate } from 'react-admin';
import CustomTooltip from '../../../base/components/custom-tooltip';
import { hasPermission } from '../../../services/util/auth';
import resourceSlug from '../../../constant/resource-slug';

const CampaignListAction = () => {
  const t = useTranslate();
  return (
    <>
      {hasPermission(resourceSlug.MKT_FREE_SPINS, 'create') && (
        <CustomTooltip title={t('ra.action.create')} placement="top">
          <CreateButton label="" />
        </CustomTooltip>
      )}
    </>
  );
};

export default CampaignListAction;
