import React, { memo } from 'react';
import {
  Create,
  Edit,
  Resource,
  SimpleForm,
  Toolbar,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { useParams, useLocation } from 'react-router-dom';
import { useField } from 'react-final-form';
import { Box, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import resourceSlug from '../../../../constant/resource-slug';
import MuiDrawer from '../../../../base/components/mui/MuiDrawer';
import { parseRewardData } from '../utils';
import { SaveButton } from '../../../../base/components/ra/buttons';
import RewardNameInput from './RewardNameInput';
import RewardTypeSelect from './RewardTypeSelect';
import ExpiryInput from './ExpiryInput';
import { REWARD_TYPES } from '../../../../constant/reward';
import AmountInput from '../../../../base/components/ra/inputs/AmountInput';
import CurrencyInput from './CurrencyInput';
import TurnoverInput from './TurnoverInput';
import { showConfirmDialog } from '../../../../services/redux/app/app.actions';
import GameListInput from './GameListInput';
import FreeSpinQuantityInput from './FreeSpinQuantityInput';
import Switcher from '../../../../base/components/common/Switcher';

const RewardCreateUpdateDrawer = ({ open }) => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const { id } = useParams();
  const classes = useStyles();
  const notify = useNotify();

  const onClose = () => {
    if (id) {
      dispatch(
        showConfirmDialog({
          isOpen: true,
          title: 'resources.reward.cancel-title',
          content: 'resources.reward.cancel-desc',
          onConfirm: () => redirect(`/${resourceSlug.REWARD}/${id}/details${search}`),
        }),
      );
      return;
    }
    redirect(`/${resourceSlug.REWARD}${search}`);
  };

  return (
    <>
      <Resource intent="registration" show name={resourceSlug.REWARD} />
      <MuiDrawer
        open={open}
        anchor="right"
        onClose={onClose}
        title={id ? t('resources.reward.reward-update') : t('resources.reward.reward-create')}
        classes={{
          paper: classes.drawer,
        }}
      >
        {open ? (
          id ? (
            <Edit
              resource={resourceSlug.REWARD}
              basePath={resourceSlug.REWARD}
              id={id}
              classes={{
                root: classes.root,
                card: classes.card,
              }}
              undoable={false}
              transform={(data) => ({
                id,
                ...parseRewardData(data),
              })}
              onSuccess={() => {
                refresh();
                notify(t('resources.reward.updated-successfully'), 'success');
                redirect(`/${resourceSlug.REWARD}/${id}/details${search}`);
              }}
            >
              <SimpleForm toolbar={<RewardToolbar />}>
                <RewardForm />
              </SimpleForm>
            </Edit>
          ) : (
            <Create
              resource={resourceSlug.REWARD}
              basePath={resourceSlug.REWARD}
              classes={{
                root: classes.root,
                card: classes.card,
              }}
              transform={parseRewardData}
              onSuccess={() => {
                refresh();
                notify(t('resources.reward.created-successfully'), 'success');
                redirect(`/${resourceSlug.REWARD}${search}`);
              }}
            >
              <SimpleForm toolbar={<RewardToolbar />}>
                <RewardForm />
              </SimpleForm>
            </Create>
          )
        ) : null}
      </MuiDrawer>
    </>
  );
};

const RewardForm = memo(() => {
  const t = useTranslate();
  const {
    input: { value: rewardType },
  } = useField('rewardType');

  return (
    <>
      <Switcher source="isEnabled" checkedLabel={t('common.text.active')} uncheckedLabel={t('common.text.inactive')} />
      <RewardNameInput fullWidth />
      <RewardTypeSelect fullWidth />
      {!!rewardType && (
        <>
          <CurrencyInput />
          {[REWARD_TYPES.FREE_SPIN, REWARD_TYPES.GOLD_CARD, REWARD_TYPES.RUBY_CARD].includes(rewardType) && (
            <>
              <GameListInput
                multiSelect={rewardType === REWARD_TYPES.GOLD_CARD || rewardType === REWARD_TYPES.RUBY_CARD}
              />
              <FreeSpinQuantityInput />
              <AmountInput source="betAmount" label={t('resources.reward.fields.bet-amount')} isRequired fullWidth />
              <AmountInput source="maxWin" label={t('resources.reward.fields.max-win')} fullWidth />
            </>
          )}
          {rewardType === REWARD_TYPES.CASH_VOUCHER && (
            <AmountInput source="amount" label={t('resources.reward.fields.cash-amount')} isRequired fullWidth />
          )}
          <ExpiryInput />
          <TurnoverInput fullWidth />
        </>
      )}
    </>
  );
});

const RewardToolbar = (props) => (
  <Box
    component={Toolbar}
    sx={{
      bgcolor: 'transparent !important',
      padding: '0 !important',
      justifyContent: 'flex-end',
      minHeight: 'fit-content !important',
    }}
    {...props}
  >
    <SaveButton icon={<></>} disabled={props.pristine} />
  </Box>
);

const useStyles = makeStyles({
  card: {
    boxShadow: 'none',
    '&>*:first-child': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      '&>*:first-child': {
        flexGrow: 1,
        padding: 0,
        overflow: 'scroll',
      },
    },
  },
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    '&>*:first-child': {
      height: 'calc(100% - 1em)',
    },
  },
  drawer: {
    width: 450,
  },
});

export default RewardCreateUpdateDrawer;
